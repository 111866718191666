import React from "react";
import {
  CommentOutlined,
  DownloadOutlined,
  HeartFilled,
  HeartOutlined,
  ShareOutlined,
} from "../../OldDesign/Icons";
import { useStore } from "../../../store/useStore";

const ActionBar = ({ handleScrollToCommentSection, user, setMetaCv }) => {
  const handleFavMetaCv = useStore((state) => state.handleFavMetaCv);
  const removeFavMetaCv = useStore((state) => state.removeFavMetaCv);

  const downloadMetaCv = useStore((state) => state.downloadMetaCv);

  const handleFavorite = () => {
    handleFavMetaCv(user.id);
    setMetaCv((prev) => {
      return { ...prev, all_sources: [...prev.all_sources, "favourites"] };
    });
  };
  const removeFavorite = () => {
    removeFavMetaCv(user.id);
    setMetaCv((prev) => {
      return {
        ...prev,
        all_sources: [...prev.all_sources].filter(
          (item) => item !== "favourites",
        ),
      };
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-3 bg-neutral-100 p-3 max-lg:flex-row max-lg:px-0 lg:rounded-r-lg">
      <button
        className="rounded-lg bg-primary-200 p-2.5 max-lg:flex max-lg:flex-1 max-lg:items-center max-lg:justify-center max-lg:border max-lg:border-primary-600 max-lg:bg-neutral-100"
        onClick={() => {
          handleScrollToCommentSection();
        }}
      >
        <span className="text-[14px] font-medium leading-tight text-primary-600 lg:hidden">
          Write a review
        </span>

        <CommentOutlined className="h-5 w-5 text-primary-900 max-lg:hidden" />
      </button>

      {user.all_sources.includes("favourites") ? (
        <button
          onClick={removeFavorite}
          className="rounded-lg border-secondary_recruiter-200 bg-secondary_recruiter-200 p-2.5 text-secondary_recruiter-600"
        >
          <HeartFilled className="h-5 w-5" />
        </button>
      ) : (
        <button
          onClick={handleFavorite}
          className="rounded-lg border-secondary_recruiter-200 bg-secondary_recruiter-200 p-2.5 text-secondary_recruiter-600"
        >
          <HeartOutlined className="h-5 w-5" />
        </button>
      )}
      {/* <button
        className="rounded-lg bg-card_candidate-100 p-2.5"
        // onClick={handleCvShare}
      >
        <ShareOutlined className="h-5 w-5 text-card_candidate-400" />
      </button> */}
      <button
        className="rounded-lg bg-primary-75 p-2.5"
        onClick={() => downloadMetaCv(user.id, user.name)}
      >
        <DownloadOutlined className="h-5 w-5 text-primary-500" />
      </button>
    </div>
  );
};

export default ActionBar;
