import React from "react";
import { LazyGuru } from "../OldDesign/Icons";
import { Plane } from "./Illustrations";
import LevelBar from "./StatusBar/LevelBar";

const PlayLazyGuruPopup = ({ loginAction }) => {
  const planePosition = Math.round(
    (loginAction.params.parameters.scenario_progress.metacv_level * 100) /
      loginAction.params.parameters.scenario_progress.scenario_states.length,
  );

  return (
    <div className="flex max-w-[450px] flex-col items-center justify-center gap-2 p-8">
      <div className="relative flex w-full flex-col gap-4 rounded-lg bg-primary-75 p-3">
        <LazyGuru className="mx-auto w-[146px] text-primary-600" />
        <div
          className={`absolute bottom-8 w-20`}
          style={{ left: `${planePosition}%` }}
        >
          <Plane />
        </div>
        <LevelBar
          levels={
            loginAction.params.parameters.scenario_progress.scenario_states
          }
        />
      </div>

      <p className="text-center text-[16px] font-medium leading-tight text-primary-900">
        Merhaba {loginAction.params.parameters.authname}
        <br /> Hay aksi! Bir şeyler eksik gibi görünüyor
      </p>
      <p className="text-center text-[14px] leading-tight text-primary-900">
        Seni daha yakından tanımak istiyoruz. İstanbul senaryosunu tamamladıktan
        sonra hemen aramıza katıl!
      </p>
    </div>
  );
};

export default PlayLazyGuruPopup;
