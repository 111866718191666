import SectionTitle from "../../../components/NewDesign/SectionTitle";
import HowItWorks from "./HowItWorks";
import QuickTips from "./QuickTips";
import WhoAreWe from "./WhoAreWe";

const WhatIsCommunity = () => {
  return (
    <section className="container2 flex flex-col gap-9 max-lg:gap-6">
      {/* <SectionTitle
        className="items-start pb-4 max-lg:pb-2"
        title="What's Humanas Community?"
      /> */}
      <WhoAreWe />
      <HowItWorks />
      <QuickTips />
    </section>
  );
};

export default WhatIsCommunity;
