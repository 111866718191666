import React, { useEffect } from "react";
import HeroCandidate from "../../components/NewDesign/Hero/Overview/HeroCandidate";
import PersonalityType from "../../components/NewDesign/PersonalityType";
import ProfessionalOrientation from "../../components/NewDesign/ProfessionalOrientation";
import StartDiscussion from "../../components/NewDesign/StartDiscussion";
import { useStore } from "../../store/useStore";
import Loading from "../../components/OldDesign/Loading";
import PrintableMetaCv from "../../components/PrintableMetaCv/PrintableMetaCv";
import CoreCvSection from "./CoreCvSection";

const Overview = () => {
  const getOverviewContent = useStore((state) => state.getOverview);
  const appLanguage = useStore((state) => state.appLanguage);
  const overview = useStore((state) => state.overview);
  useEffect(() => {
    getOverviewContent();
  }, [getOverviewContent, appLanguage]);
  // console.log(overview);
  if (overview.isLoading) {
    return <Loading />;
  }
  if (!overview.isLoaded) {
    return null;
  }
  return (
    <>
      <HeroCandidate />
      {overview.data.coreCvProgress.value === 100 && (
        <CoreCvSection user={overview.data} />
      )}
      <PersonalityType />
      <ProfessionalOrientation />
      <StartDiscussion />
    </>
  );
};

export default Overview;
