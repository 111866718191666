import React from "react";
import SectionTitle from "./SectionTitle";
import StatCard from "./StatCard";
import { useStore } from "../../store/useStore";

const Statistics = () => {
  const statistics = useStore((state) => state.dashboard.data.statistics);
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations[
        "Recruiter Puan Kartlari"
      ],
  );
  return (
    <section className="container2 py-6">
      <SectionTitle title={content["istatistikler baslik"]} />
      <div className="grid grid-cols-5 gap-4 pt-6 max-lg:grid-cols-1 max-lg:pt-6">
        {/* {statistics.map((stat, index) => {
          return <StatCard key={index} stat={stat} />;
        })} */}
        <StatCard stat={statistics.unlocks} />
        <StatCard stat={statistics.favourites} />
        <StatCard stat={statistics.total_employee} />
        <StatCard stat={statistics.views} />
        <StatCard stat={statistics.applications} />
      </div>
    </section>
  );
};

export default Statistics;
