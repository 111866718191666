import React, { useEffect, useRef, useState } from "react";

const Tooltip = (props) => {
  const placeholder = useRef(null);
  const [active, setActive] = useState(false);
  const [position, setPosition] = useState(null);
  const [media, setMedia] = useState(
    window.matchMedia("(min-width: 1080px)").matches,
  );

  const openTip = () => {
    setActive(true);
    calculateDirection();
  };
  const closeTip = () => {
    setActive(false);
  };
  const toggleTip = () => {
    setActive((prev) => !prev);
  };

  const calculateDirection = () => {
    let pholderPos = placeholder.current.getBoundingClientRect();
    let windowWidth = window.innerWidth;
    let topEdge = pholderPos.height + 8;
    let ttipWidth = media ? 450 : 310;
    // console.log("pholderPos", pholderPos, "wwidth", windowWidth);
    if (pholderPos.x > ttipWidth && windowWidth - pholderPos.x < ttipWidth) {
      setPosition({ right: -8, top: topEdge });
    } else if (
      pholderPos.x < ttipWidth &&
      windowWidth - pholderPos.x > ttipWidth
    ) {
      setPosition({ left: -8, top: topEdge });
    } else if (
      pholderPos.x < ttipWidth &&
      windowWidth - pholderPos.x < ttipWidth
    ) {
      setPosition({ left: -pholderPos.x + 24, top: topEdge });
    } else {
      setPosition({ left: "50%", transform: `translateX(-50%)`, top: topEdge });
    }
  };

  useEffect(() => {
    window
      .matchMedia("(min-width: 1080px)")
      .addEventListener("change", (e) => setMedia(e.matches));
  }, [media]);

  return (
    <div
      onMouseEnter={openTip}
      onMouseLeave={closeTip}
      className="relative inline-block"
      ref={placeholder}
    >
      {props.children}
      {active && (
        <>
          <div
            style={{ top: position.top - 16, zIndex: 999 }}
            className="absolute left-1/2 h-0 w-0 -translate-x-1/2 border-8 border-primary-800 border-l-[#FFFFFF00] border-r-[#FFFFFF00] border-t-[#FFFFFF00]"
          ></div>
          <div
            className={`${
              active ? "block" : "hidden"
            } absolute z-[999] w-[360px] rounded-xl bg-primary-800 p-6 text-[15px] leading-tight text-neutral-100 transition-all max-lg:max-w-[310px] max-lg:p-3 max-lg:text-xs`}
            style={position}
          >
            {props.content}
          </div>
        </>
      )}
    </div>
  );
};

export default Tooltip;
