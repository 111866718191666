import React from "react";

import { useStore } from "../store/useStore";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  allowedUserTypes = ["recruiter", "candidate"],
  ...props
}) => {
  const user = useStore((state) => state.user);

  if (!allowedUserTypes.includes(user.data.userType)) {
    if (user.data.userType === "recruiter") {
      return <Navigate to="/dashboard" />;
    } else if (user.data.userType === "candidate") {
      return <Navigate to="/overview" />;
    }
  }

  return <>{props.children}</>;
};
export default ProtectedRoute;
