import React, { useEffect, useState } from "react";
import { Form } from "../../../components/NewDesign/Forms/Form";
import { useStore } from "../../../store/useStore";
import Button from "../../../components/NewDesign/Button/Button";
import { Field } from "../../../components/NewDesign/Forms/Field";
import {
  CodeBox,
  Input,
  Switch,
  TextArea,
} from "../../../components/NewDesign/Forms/Input";
import { PhoneInput } from "react-international-phone";
import { Controller, useForm } from "react-hook-form";
import Loading from "../../../components/OldDesign/Loading";
import {
  CheckOutlined,
  ChevronRightOutlined,
  EditFilled,
  TickOutlined,
  TimeOutlined,
} from "../../../components/OldDesign/Icons";
import Modal from "../../../components/OldDesign/Modal";
import { sendSupportTicket, setTwoFA } from "../../../api/endpoints";
import CountdownTimer from "../../../components/OldDesign/CountdownTimer";
import { toast } from "react-toastify";
import Tooltip from "../../../components/NewDesign/Tooltips/Tooltip";

const RecruiterProfileSettings = () => {
  const settings = useStore((state) => state.settings);
  const setSettings = useStore((state) => state.setSettings);
  const appLanguage = useStore((state) => state.appLanguage);

  // const content = useStore(
  //   (state) =>
  //     state.content.data["Candidate Community"].content.translations["Core CV"],
  // );

  const [twoFaState, setTwoFaState] = useState(null);
  const [twoFaToggle, setTwoFaToggle] = useState(null);
  const [delAccModalOpen, setDelAccModalOpen] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: {
      // profile_picture: settings.data.user_profile?.profile_picture.value,
      name: settings.data.user_profile?.name.value,
      phone: settings.data.user_profile?.phone.value,
      email: settings.data.user_profile?.email.value,
      bio: settings.data.user_profile?.bio.value,
      linkedin: settings.data.user_profile?.socials.linkedin.value,
    },
    mode: "onSubmit",
  });

  const handleSubmitSettings = async (formData) => {
    const payload = Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, formData[key]]),
    );

    // console.log(payload);
    await setSettings(payload);
  };

  const handleTwoFaActivation = async (e) => {
    setTwoFaToggle(e.target.checked);
    await setTwoFA({ toggle: e.target.checked }, (res) => {
      setTwoFaState(res.data["2fa_state"]);
    });
  };

  const handleLanguageSettingsChange = async (e) => {
    const payload = { language: e.target.value };
    await setSettings(payload);
  };

  const handleProfilePicChange = async (e) => {
    const payload = { profile_picture: e.target.files[0] };
    // console.log(e.target.files[0]);
    await setSettings(payload);
  };

  useEffect(() => {
    reset({
      // profile_picture: settings.data.user_profile?.profile_picture.value,
      name: settings.data.user_profile?.name.value,
      phone: settings.data.user_profile?.phone.value,
      email: settings.data.user_profile?.email.value,
      bio: settings.data.user_profile?.bio.value,
      linkedin: settings.data.user_profile?.socials.linkedin.value,
    });
  }, [settings, reset]);

  if (settings.isLoading) {
    return <Loading />;
  }
  if (!settings.isLoaded) {
    return null;
  }
  if (twoFaState === "email") {
    return (
      <TwoFaConfirmationBox
        twoFaToggle={twoFaToggle}
        setTwoFaState={setTwoFaState}
      />
    );
  } else if (twoFaState === "sms") {
    return (
      <TwoFaConfirmationBox
        twoFaToggle={twoFaToggle}
        isPhone={true}
        setTwoFaState={setTwoFaState}
      />
    );
  }
  return (
    <div className="flex flex-col gap-6">
      <Form onSubmit={handleSubmit(handleSubmitSettings)}>
        <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3 lg:mx-auto lg:w-[700px]">
          <div className="mb-6 flex basis-full items-center justify-between border-b pb-2">
            <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
              User Profile
            </h5>
            <Button
              type="submit"
              disabled={Object.keys(dirtyFields).length === 0}
              loading={isSubmitting}
              className="bg-neutral-100 px-6 py-3 text-primary-600"
            >
              Kaydet
            </Button>
          </div>

          <div className="relative mx-auto flex w-fit">
            <img
              src={settings.data.user_profile.profile_picture.value}
              alt={settings.data.user_profile.name.value}
              className="h-[100px] w-[100px] rounded-full border"
            />
            <div>
              {settings.data.user_profile.profile_picture.needs_approval &&
                (settings.data.user_profile.profile_picture.is_approved ? (
                  <Tooltip
                    className={"z-10"}
                    content={
                      <span className="whitespace-nowrap">Onaylandı</span>
                    }
                  >
                    <CheckOutlined
                      className={"mb-0.5 h-4 w-4 text-success-400"}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    className={"z-10"}
                    content={
                      <span className="whitespace-nowrap">Onay Bekliyor</span>
                    }
                  >
                    <TimeOutlined
                      className={"mb-0.5 h-4 w-4 text-warning-400"}
                    />
                  </Tooltip>
                ))}
            </div>
            <input
              // {...register("profile_picture")}
              onChange={(e) => handleProfilePicChange(e)}
              id="profile_picture"
              type="file"
              className="hidden"
              accept=".jpg"
            />
            <label
              htmlFor="profile_picture"
              type="button"
              className="absolute -right-3 bottom-0 cursor-pointer rounded-full border-2 border-neutral-100 bg-primary-600 p-2"
            >
              <EditFilled className={"h-4 w-4 text-neutral-100"} />
            </label>
          </div>
          <fieldset className="flex flex-col gap-6 lg:mx-auto lg:w-[460px]">
            <Field
              label={settings.data.user_profile.name.title}
              needs_approval={settings.data.user_profile.name.needs_approval}
              is_approved={settings.data.user_profile.name.is_approved}
              error={errors?.name}
            >
              <Input
                {...register("name", { required: "Required" })}
                id="name"
                placeholder=""
              />
            </Field>

            <Field
              label={settings.data.user_profile.email.title}
              needs_approval={settings.data.user_profile.email.needs_approval}
              is_approved={settings.data.user_profile.email.is_approved}
              error={errors?.email}
            >
              <Input
                // disabled
                {...register("email", {
                  required: "Required",
                })}
                id="email"
                placeholder="Yazınız"
              />
            </Field>

            <Field
              label={settings.data.user_profile.phone.title}
              needs_approval={settings.data.user_profile.phone.needs_approval}
              is_approved={settings.data.user_profile.phone.is_approved}
              error={errors?.phone}
            >
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    defaultCountry="tr"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disableFormatting
                    forceDialCode
                    // disabled
                    inputClassName="border"
                    className="rounded-lg outline outline-1 outline-neutral-300 transition-all hover:outline-primary-600 focus:outline-primary-600"
                    inputProps={{
                      className:
                        "pl-3 h-12 bg-neutral-100 text-[13px] lg:h-[52px] lg:text-[16px] outline-none",
                    }}
                    dialCodePreviewStyleProps={{
                      className:
                        "bg-neutral-200 border-none pl-1 pr-3 text-[16px]",
                    }}
                    countrySelectorStyleProps={{
                      className: "bg-neutral-200 rounded-l-lg",
                      buttonClassName:
                        "border-none h-full rounded-l-lg hover:rounded-l-lg pl-3",
                    }}
                  />
                )}
              />
            </Field>

            <Field
              label={settings.data.user_profile.bio.title}
              needs_approval={settings.data.user_profile.bio.needs_approval}
              is_approved={settings.data.user_profile.bio.is_approved}
              error={errors?.bio}
            >
              <TextArea
                {...register("bio")}
                id="bio"
                maxLength={250}
                placeholder="Kısaca kendinizi tanıtabilirsiniz."
                className="rounded-lg p-4 outline outline-1 outline-neutral-300"
              />
            </Field>

            <Field
              label={settings.data.user_profile.socials.linkedin.title}
              needs_approval={
                settings.data.user_profile.socials.linkedin.needs_approval
              }
              is_approved={
                settings.data.user_profile.socials.linkedin.is_approved
              }
              error={errors?.linkedin}
            >
              <Input {...register("linkedin")} id="linkedin" placeholder="" />
            </Field>
          </fieldset>
        </div>
      </Form>
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3 lg:mx-auto lg:w-[700px]">
        <div className="mb-6 flex basis-full items-center justify-between border-b pb-2">
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            User Account
          </h5>
        </div>
        <div className="flex flex-col">
          {/* LANG */}
          <div className="flex items-center justify-between border-b py-3 lg:h-[84px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              {settings.data.user_account.language_name.title}
            </p>
            <select
              value={appLanguage}
              onChange={handleLanguageSettingsChange}
              className="rounded-lg border-r-[40px] border-transparent bg-white bg-opacity-0 py-4 pl-10 text-[13px] font-medium leading-none text-primary-900 outline outline-neutral-300"
            >
              <option value="tr">Türkçe</option>
              <option value="en">English</option>
            </select>
          </div>
          {/* PASS */}
          <div className="flex items-center justify-between border-b py-3 lg:h-[84px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              Password
            </p>
            <button
              disabled
              className="font-semibold leading-tight text-primary-600 opacity-70"
            >
              Change Password
              <span className="rounded-md bg-primary-100 px-1 text-[9px] text-primary-700">
                Coming Soon
              </span>
            </button>
          </div>
          {/* 2FA */}
          <div className="flex items-center justify-between py-3 lg:h-[84px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              {settings.data.user_account.twofa_enabled.title}
            </p>
            <Switch
              checked={settings.data.user_account.twofa_enabled.value}
              onChange={handleTwoFaActivation}
            />
          </div>
          {/* Phone */}
          <div className="flex items-center justify-between py-3 lg:h-[72px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              {settings.data.user_account.twofa_fields.phone.title}
            </p>
            <p className="flex items-center gap-1 text-[15px] leading-tight text-neutral-700">
              {settings.data.user_account.twofa_fields.phone.value}
              {settings.data.user_account.twofa_fields.phone.is_approved && (
                <span className="rounded-full bg-success-400 p-1">
                  <TickOutlined className={" h-3 w-3 text-neutral-100"} />
                </span>
              )}
            </p>
          </div>
          {/* Email */}
          <div className="flex items-center justify-between border-b py-3 lg:h-[72px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              {settings.data.user_account.twofa_fields.email.title}
            </p>
            <p className="flex items-center gap-1 text-[15px] leading-tight text-neutral-700">
              {settings.data.user_account.twofa_fields.email.value}
              {settings.data.user_account.twofa_fields.email.is_approved && (
                <span className="rounded-full bg-success-400 p-1">
                  <TickOutlined className={" h-3 w-3 text-neutral-100"} />
                </span>
              )}
            </p>
          </div>
          {/* DEL ACC */}
          <div className="flex items-center justify-between py-3 lg:h-[84px]">
            <p className="text-[17px] font-semibold leading-tight text-primary-900">
              Account
            </p>
            <button
              onClick={() => setDelAccModalOpen(true)}
              className="font-semibold leading-tight text-error-400"
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
      <DeleteAccountBox
        delAccModalOpen={delAccModalOpen}
        setDelAccModalOpen={setDelAccModalOpen}
      />
    </div>
  );
};

const TwoFaConfirmationBox = ({ isPhone, setTwoFaState, twoFaToggle }) => {
  const getSettings = useStore((state) => state.getSettings);
  const settings = useStore((state) => state.settings);

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [timer, setTimer] = useState(180);

  const submitCode = () => {
    setTwoFA(
      { toggle: !settings.data.user_account.twofa_enabled.value, code: code },
      (res) => {
        setTwoFaState(res.data["2fa_state"]);
        setTimer(res.data.expires_in);
        setCode("");
        if (res.data["2fa_state"] === "completed") {
          toast.success(res.message);
          getSettings();
        }
      },
      (err) => {
        setError(true);
      },
    );
  };

  const resendCode = () => {
    setTwoFA({ toggle: twoFaToggle, code: -1 }, (res) => {
      setTimer((prev) => prev - 1);
    });
  };
  return (
    <Form onSubmit={() => submitCode()}>
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3 lg:mx-auto lg:w-[700px]">
        <div className="relative mb-6 flex basis-full items-center justify-center border-b pb-2">
          <button
            type="button"
            onClick={() => setTwoFaState(null)}
            className="absolute left-2 rounded-full p-1 hover:bg-primary-75"
          >
            <ChevronRightOutlined
              className={" h-5 w-5 rotate-180 text-primary-600 "}
            />
          </button>
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            İki Aşamalı Onay
          </h5>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="text-center leading-tight text-neutral-700">
            {isPhone ? "Telefon numaranıza" : "E-posta adresinize"} bir
            doğrulama kodu ilettik. Lütfen gelen kodu aşağıdaki alana giriniz.
          </p>
          <CodeBox error={error} value={code} onChange={setCode} />
          <CountdownTimer expire_time={timer} />
          <Button type="submit">Onayla</Button>
          <p className="text-[17px] leading-tight text-neutral-700">
            Kod ulaşmadı mı?{" "}
            <button
              type="button"
              onClick={() => resendCode()}
              className="font-medium text-primary-1000"
            >
              Tekrar gönder
            </button>
          </p>
        </div>
      </div>
    </Form>
  );
};

const DeleteAccountBox = ({ delAccModalOpen, setDelAccModalOpen }) => {
  const [deleteReason, setDeleteReason] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const handleDelAccRequest = async (description) => {
    setSubmitting(true);
    const payload = {
      title: "Account Delete Request",
      description: description,
      category_id: 1,
    };
    sendSupportTicket(
      payload,
      (res) => {
        toast.success(res.message);
        setDelAccModalOpen(false);
        setSubmitting(false);
      },
      (err) => {
        toast.error(
          "İşlem Gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz",
        );
      },
    );
  };
  return (
    <Modal showModal={delAccModalOpen} setShowModal={setDelAccModalOpen}>
      <div className="flex flex-col gap-4 bg-neutral-200 p-9 lg:w-[515px]">
        <h6 className="text-[22px] font-semibold leading-tight text-primary-900">
          Hesap Silme Talebiniz Hakkında
        </h6>
        <p className="leading-tight text-neutral-700">
          Hesabınızı neden silmek istediğinizi kısaca açıklayınız,
          temsilcilerimiz en kısa sürede size ulaşacaktır.
        </p>
        <textarea
          onChange={(e) => setDeleteReason(e.target.value)}
          name="delAcc"
          id="delAcc"
          maxLength={250}
          rows={8}
          className="p-4"
        />
        <Button
          loading={submitting}
          onClick={() => handleDelAccRequest(deleteReason)}
        >
          Talebi ilet
        </Button>
      </div>
    </Modal>
  );
};

export default RecruiterProfileSettings;
