import { useStore } from "../../store/useStore";
import {
  AnalysisOutlined,
  ApplicationFilled,
  BagIcon,
  CommunityOutlined,
  CompassFilled,
  DashboardOutlined,
  EmployeeFilled,
  EyeFilled,
  HeartFilled,
  KeyFilled,
  MagicFilled,
  MetaCvOnlyLogo,
  NetworkOutlined,
  PeopleOutlined,
  SendFilled,
  UnlockFilled,
} from "../OldDesign/Icons";

export const CORECV_FIELD_KEYS = {
  job_title: "İş Pozisyonu",
  age_range: "Yaş Aralığı",
  job_title_experience: "Pozisyon Tecrübesi",
  sector_experience: "Sektörel Tecrübe",
  work_state: "Çalışma Durumu",
  work_location: "Çalışma Yeri",
  work_time: "Çalışma Zamanı",
  location: "İkametgah Tercihi",
  skills: "Yetenekler",
  languages: "Dil Bilgisi",
  universities: "Okul Tercihi",
  university_departments: "Bölüm Tercihi",
  about: "Ek Değerlendirme",
};

export const SORT_OPTIONS = [
  {
    sortby: "metacv_creation_date",
    ascending: true,
    name: "Meta Cv Oluşma Tarihi Eskiden Yeniye",
  },
  {
    sortby: "metacv_creation_date",
    ascending: false,
    name: "Meta Cv Oluşma Tarihi Yeniden Eskiye",
  },
  { sortby: "metacv_level", ascending: true, name: "Meta Cv Level Artan" },
  { sortby: "metacv_level", ascending: false, name: "Meta Cv Level Azalan" },
  {
    sortby: "most_interactions",
    ascending: true,
    name: "Etkileşim Sayısı Artan",
  },
  {
    sortby: "most_interactions",
    ascending: false,
    name: "Etkileşim Sayısı Azalan",
  },
];

export const CONNECTIONS_GROUPS = ["favourites", "reviews"];
export const CONNECTIONS_SOURCES = ["candidate", "employee"];
export const CONNECTIONS_WORKINFO = {
  work_time: [
    { id: 0, value: "fulltime" },
    { id: 1, value: "parttime" },
    { id: 2, value: "freelance" },
  ],
  work_state: [
    { id: 0, value: "employed" },
    { id: 1, value: "unemployed" },
  ],
  work_location: [
    { id: 0, value: "office" },
    { id: 1, value: "remote" },
    { id: 2, value: "hybrid" },
  ],
};
export const CONNECTIONS_PERSONALITY = {
  disa_donuk: [
    { id: 13, value: "baskin" },
    { id: 14, value: "sosyal" },
    { id: 15, value: "enerjik" },
  ],
  sorumlu: [
    { id: 4, value: "basari_odakli" },
    { id: 5, value: "oz_disiplinli" },
    { id: 6, value: "temkinli" },
    { id: 7, value: "guvenilir" },
    { id: 8, value: "oz_yeterli" },
    { id: 9, value: "duzenli" },
  ],
  deneyime_acik: [
    { id: 16, value: "yaratici" },
    { id: 17, value: "estetik_egilimi_olan" },
    { id: 18, value: "acik_fikirli" },
    { id: 19, value: "ic_gorulu" },
    { id: 20, value: "entelektuel" },
  ],
  uyumlu: [
    { id: 10, value: "duyarli" },
    { id: 11, value: "uzlasmaci" },
    { id: 12, value: "alcak_gonullu" },
  ],
  duygusal_denge_sahibi: [
    { id: 0, value: "rahat" },
    { id: 1, value: "uysal" },
    { id: 2, value: "iyimser" },
    { id: 3, value: "sogukkanli" },
  ],
};
export const CONNECTIONS_BADGE = {
  humanas_ids: [
    { id: 0, value: "braveheart" },
    { id: 1, value: "the_dealmaker" },
    { id: 2, value: "the_bridge" },
    { id: 3, value: "social_thinker" },
    { id: 4, value: "the_warrior" },
    { id: 5, value: "wanderlust" },
    { id: 6, value: "vanga" },
    { id: 7, value: "natural_ai" },
    { id: 8, value: "phoenix" },
    { id: 9, value: "voyager_1" },
    { id: 10, value: "justitia" },
    { id: 11, value: "connector" },
    { id: 12, value: "harmonious" },
    { id: 13, value: "maverick" },
  ],
};

export const ORIENTATION_LEVEL_DESCRIPTIONS = {
  tam_dengeli:
    "Bu kategori, tanımı verilen özellikte tam dengeli bir yapının olduğunu göstermektedir. Bir özellikte tam dengeli kategorisinde yer alan bireyler iki kutuplu yapıda tanımlanmış özelliğin bir tarafına eğilim göstermezler, özelliğin iki yönünü de dengeli biçimde yansıtırlar.",
  guclu:
    "Bu kategori, bir özelliğe kuvvetli bir eğilimi temsil eder. Özelliklere güçlü düzeyde sahip olan bireyler, iki kutuplu yapıda tanımlanmış özelliklerden bir tarafa kuvvetli bir eğilim göstermektedir.",
  dengeli:
    "Bu kategori tanımı verilen özelliğe dengeli bir eğilimi ifade eder. Bir özellikte dengeli kategorisinde yer alan bireyler iki kutuplu yapıda tanımlanmış özelliğin bir tarafına eğilim gösterse de bu eğilim yüksek bir düzeyde değildir.",
  siradisi:
    "Bu kategori, bireylerin bir özelliğe çok yüksek düzeyde eğilimini ifade etmektedir. Özelliklere sıra dışı düzeyde sahip olan bireyler, iki kutupku yapıda tanımlanmış özelliklerin bir kutbuna çok kuvvetli bir eğilim göstermektedir.",
};

export const AID_PAGES = [
  { path: "summary", name: "AID Özet", ready: true },
  { path: "identities", name: "AID Kimlikleri", ready: true },
  { path: "cna", name: "CNA", ready: false },
  { path: "services", name: "Hizmetler", ready: false },
];

export const AID_SUBPAGES = [
  { path: "overview", name: "Genel Bakış" },
  { path: "sectors", name: "Sektörel Yaklaşım" },
  { path: "interview", name: "Mülakat Soruları" },
  { path: "features", name: "Özellikler" },
];

export const COMPATIBILITY_STAT_COLORS = {
  0: "#14B8A6",
  1: "#A2D7D0",
  2: "#C2C2D6",
  3: "#9A9ABA",
  4: "#7B7B95",
};
export const ORIENTATION_STAT_COLORS = {
  siradisi: "#3469AD",
  guclu: "#7986E8",
  dengeli: "#82B7EA",
  tam_dengeli: "#77DEDB",
  balanced: "#82B7EA",
  fullybalanced: "#77DEDB",
  strong: "#7986E8",
  extraordinary: "#3469AD",
};

export const WORK_PREF_STAT_COLORS = {
  fulltime: "#6359E9",
  parttime: "#9ECBF6",
  freelance: "#E4E1F2",
  office: "#0075FF",
  hybrid: "#D7D7D7",
  remote: "#14B8A6",
};

export const MY_NETWORK_TABS = () => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  return [
    { path: "connections", title: content["connections baslik"] },
    { path: "invitees", title: content["invitees baslik"] },
    { path: "groups", title: "Gruplarım" },
    { path: "reports", title: "Raporlarım" },
    // { path: "pool", title: content["pool baslik"] },
  ];
};

export const GROUP_FILTERS = () => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const GROUP_FILTERS = {
    all: content["connections hizli filtre all"],
    favourites: content["connections hizli filtre favourites"],
    unlocks: content["connections hizli filtre unlocks"],
    archives: content["connections hizli filtre archives"],
    reviews: content["connections hizli filtre reviews"],
    invitees: content["connections hizli filtre invitees"],
    views: content["connections hizli filtre views"],
    invited: "Davet Alındı",
    playing: "Oynuyor",
    fillingCoreCv: "Core CV Dolduruyor",
    completed: "Hazır",
    "invitees-candidate": "Adaylar",
    "invitees-employee": "Çalışanlar",
    work_time: "Çalışma Zamanı",
    work_location: "Çalışma Yeri",
    work_state: "Çalışma Durumu",
    parttime: "Yarı Zamanlı",
    fulltime: "Tam Zamanlı",
    freelance: "Freelance",
    employed: "Çalışıyor",
    unemployed: "Çalışmıyor",
    office: "Ofis",
    hybrid: "Hibrit",
    remote: "Uzaktan",
    employee: "Çalışanlar",
    candidate: "Adaylar",
    clear: "Sıfırla",
    rahat: "Rahat",
    uysal: "Uysal",
    iyimser: "İyimser",
    sogukkanli: "Soğukkanlı",
    basari_odakli: "Başarı Odaklı",
    oz_disiplinli: "Öz Disiplinli",
    temkinli: "Temkinli",
    guvenilir: "Güvenilir",
    oz_yeterli: "Öz Yeterli",
    duzenli: "Düzenli",
    duyarli: "Duyarlı",
    uzlasmaci: "Uzlaşmacı",
    alcak_gonullu: "Alçak Gönüllü",
    baskin: "Baskın",
    sosyal: "Sosyal",
    enerjik: "Enerjik",
    yaratici: "Yaratıcı",
    estetik_egilimi_olan: "Estetik Eğilimi Olan",
    acik_fikirli: "Açık Fikirli",
    ic_gorulu: "İç Görülü",
    entelektuel: "Entelektüel",
    disa_donuk: "Dışa Dönük",
    sorumlu: "Sorumlu",
    deneyime_acik: "Deneyime Açık",
    uyumlu: "Uyumlu",
    duygusal_denge_sahibi: "Duygusal Denge Sahibi",
    braveheart: "Braveheart:Adanmış",
    the_dealmaker: "The Dealmaker: Çevik Müzakereci",
    the_bridge: "The Bridge: Ara Bulucu",
    social_thinker: "Social Thinker: Empati Sahibi",
    the_warrior: "The Warrior: İş Bitiren",
    wanderlust: "Wanderlust: Yerinde Duramayan",
    vanga: "Vanga: Vizyoner",
    natural_ai: "Natural AI: Mekanik Zihin",
    phoenix: "Phoenix: Strese Dayanıklı",
    voyager_1: "Voyager 1: Gelişim Tutkunu",
    justitia: "Justitia: Etik Savunucu",
    connector: "Connector: Sosyal",
    harmonious: "Harmonious: Çok Yönlü",
    maverick: "Maverick: Farklı Düşünen",
    humanas_ids: "Humanas Kimlikleri",
  };
  return GROUP_FILTERS;
};

export const HEADER_MENU_LINKS = (titles) => ({
  candidate: [
    { title: "Ayarlar", path: "/settings" },
    { title: titles["header menu cikis yap"], path: "/logout" },
  ],
  recruiter: [
    { title: "Fiyatlandırma", path: "/pricing" },
    { title: "Ayarlar", path: "/settings" },
    { title: titles["header menu cikis yap"], path: "/logout" },
  ],
});

export const NAV_LINKS = (titles) => ({
  recruiter: [
    { path: "dashboard", title: titles["header title dashboard"], ready: true },
    {
      path: "community",
      title: titles["header title humanas community"],
      ready: true,
    },
    {
      path: "mynetwork",
      title: titles["header title my network"],
      ready: true,
    },
    {
      path: "aid",
      title: titles["header title suggestions"],
      ready: true,
    },
    {
      path: "cna/overview",
      title: "CNA",
      ready: true,
    },
    {
      path: "boost",
      title: titles["header title boost your network"],
      ready: false,
    },
  ],
  candidate: [
    { path: "overview", title: titles["header title overview"], ready: true },
    {
      path: "community",
      title: titles["header title humanas community"],
      ready: true,
    },
    // {
    //   path: "personality",
    //   title: titles["header title personality type"],
    //   ready: false,
    // },
    // {
    //   path: "orientation",
    //   title: titles["header title professional orientation"],
    //   ready: false,
    // },
    {
      path: "aiobservation",
      title: titles["header title ai observation"],
      ready: false,
    },
  ],
});

export const MOBILE_NAV_LINKS = (titles) => ({
  recruiter: [
    {
      path: "dashboard",
      // title: titles["header title dashboard"],
      title: "Home",
      icon: <DashboardOutlined className="h-[16px] w-[16px]" />,
      active: true,
    },
    // {
    //   path: "community",
    //   title: titles["header title humanas community"],
    //   icon: <CommunityOutlined className="h-[21px] w-[21px]" />,
    //   active: true,
    // },
    {
      path: "mynetwork",
      title: "Network",
      icon: <CommunityOutlined className="h-[21px] w-[21px]" />,
      active: true,
    },
    {
      path: "cna",
      title: "CNA",
      icon: <NetworkOutlined className="h-[16px] w-[16px]" />,
      active: true,
    },
    {
      path: "boost",
      title: "Boost",
      icon: <PeopleOutlined className="h-[16px] w-[16px]" />,
      active: false,
    },
  ],
  candidate: [
    {
      path: "overview",
      title: titles["header title overview"],
      icon: <DashboardOutlined className="h-[20px] w-[20px]" />,
      active: true,
    },
    {
      path: "community",
      title: titles["header title humanas community"],
      icon: <CommunityOutlined className="h-[25px] w-[25px]" />,
      active: true,
    },
    {
      path: "learnmore",
      title: "Learn more",
      icon: <AnalysisOutlined className="h-[20px] w-[20px]" />,
      active: false,
    },
    {
      path: "account",
      title: "Account",
      icon: <PeopleOutlined className="h-[20px] w-[20px]" />,
      active: false,
    },
  ],
});

export const WORK_STATE = [
  { value: "employed", label: "Employed" },
  { value: "unemployed", label: "Unemployed" },
];

export const WORK_LOCATION = [
  { value: "office", label: "Office" },
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
];

export const EXPERIENCE = [
  { job_experience_id: 1, job_experience_title: "0-1 year" },
  { job_experience_id: 2, job_experience_title: "1-3 year" },
  { job_experience_id: 3, job_experience_title: "3-5 year" },
  { job_experience_id: 4, job_experience_title: "5+ year" },
];

export const WORK_TIME = [
  { value: "fulltime", label: "Full Time" },
  { value: "parttime", label: "Part Time" },
  { value: "freelance", label: "Freelance" },
];

export const EDUCATION_DEGREE = [
  {
    id: "primaryeducation",
    title: "Primary Education",
  },
  {
    id: "secondaryeducation",
    title: "Secondary education",
  },
  {
    id: "highschool",
    title: "High School",
  },
  {
    id: "associatedegree",
    title: "Associate Degree",
  },
  {
    id: "bachelorsdegree",
    title: "Bachelor's Degree",
  },
  {
    id: "mastersdegree",
    title: "Master's Degree",
  },
  {
    id: "doctoraldegree",
    title: "Doctorate Degree",
  },
];

export const DEPARTMENTS = [
  { value: "Grafik Tasarım" },
  { value: "Kimya Muhendisligi" },
  { value: "Tarih" },
  { value: "Uluslararasi Iliskiler" },
  { value: "Bilgisayar Muhendisligi" },
  { value: "Ekonomi" },
];

export const LANGUAGE_PROFICIENCY = [
  { value: 0, label: "A1" },
  { value: 1, label: "A2" },
  { value: 2, label: "B1" },
  { value: 3, label: "B2" },
  { value: 4, label: "C1" },
  { value: 5, label: "C2" },
];

export const COMPANY_STATS = {
  total_employee: {
    icon: <EmployeeFilled className="h-[26px] w-[26px] text-primary-500 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-primary-75",
    content: "Sorumluluk alanınızda bulunan çalışanlara ait toplam Meta CV sayınız",
  },
  unlocks: {
    icon: <UnlockFilled className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px] " />,
    bgColor: "bg-warning-100",
    content: "Tam görüntülüme hakkına sahip olduğunuz toplam Meta CV sayısı",
  },
  favourites: {
    icon: <HeartFilled className="h-[26px] w-[26px] text-secondary_recruiter-600 max-lg:h-[22px] max-lg:w-[22px] " />,
    bgColor: "bg-secondary_recruiter-100",
    content: "Favori listenize aldığınız ve henüz görüntülenmemiş toplam Meta CV sayısı",
  },
  applications: {
    icon: <ApplicationFilled className="h-[26px] w-[26px] text-primary-600 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-primary-75",
    content: "İlanlarınıza şimdiye kadar gelen başvuru sayısı",
  },
  invited: {
    icon: <SendFilled className="h-[26px] w-[26px] text-success-400 max-lg:h-[22px]  max-lg:w-[22px]" />,
    bgColor: "bg-success-100",
    content: "Network'ünüze davet ettiğiniz toplam kullanıcı sayısı",
  },
  views: {
    icon: <EyeFilled className="h-[26px] w-[26px] text-card_candidate-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-card_candidate-100",
    content: "Görüntülediğiniz toplam Meta CV sayısı",
  },
  headhunter: {
    icon: <BagIcon className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-warning-100",
    content: "Toplam Headhunter sayısı",
  },
  company: {
    icon: <BagIcon className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-warning-100",
    content: "Toplam Company sayısı",
  },
  metacv_count: {
    icon: <MetaCvOnlyLogo className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Meta Cv Sayisi",
  },
  need_analysis: {
    icon: <MagicFilled className="h-[26px] w-[26px] text-chart-purple2 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Ihtiyac Analizi",
  },
  find_the_one: {
    icon: <CompassFilled className="h-[26px] w-[26px] text-chart-green max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Find the One",
  },
  authorization: {
    icon: <KeyFilled className="h-[26px] w-[26px] text-chart-yellow max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Yetkilendirme",
  },
};
