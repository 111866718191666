import React from "react";
import { twMerge } from "tailwind-merge";

export const Button = ({
  children,
  onClick,
  bgColor,
  txtColor,
  txtSize,
  type,
  className,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className} rounded-[10px] bg-primary-600 text-neutral-100 py-3 lg:py-4 font-medium text-[14px] lg:text-[16px] leading-5`}
      style={{
        backgroundColor: `${bgColor}`,
        color: `${txtColor}`,
      }}
    >
      {children}
    </button>
  );
};

export const ButtonWithIcon = (props) => {
  const className =
    "inline-flex gap-[10px] items-center justify-center py-4 px-6 bg-primary-600 rounded-lg text-[16px] font-semibold leading-tight";
  const mergedClasses = twMerge(className, props.className);
  return (
    <button className={mergedClasses}>
      {props.icon}
      {props.children}
      {props.icon2}
    </button>
  );
};
