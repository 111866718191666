import React from "react";
import OrientationBar from "./StatusBar/OrientationBar";
import SectionTitle from "./SectionTitle";
import Tooltip from "../../components/NewDesign/Tooltips/Tooltip";
import { InfoOutlined } from "../OldDesign/Icons";

const OrientationCard = ({ orientation, editMode, onChange }) => {
  return (
    <div
      id={`orientationCard_${orientation.dimension_title}`}
      className="rounded-xl border border-neutral-300 bg-neutral-100 p-4"
    >
      <h3 className="pb-6 text-center text-[18px] font-semibold leading-tight text-primary-900">{orientation.title}</h3>
      <div className="relative flex flex-col gap-4 ">
        <div className="grid grid-cols-2 gap-2">
          <div
            className={`${
              !orientation.direction || orientation.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientation.title_left}</span>
              <Tooltip content={orientation.description_left}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
          <div
            className={`${
              orientation.direction || orientation.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientation.title_right}</span>
              <Tooltip content={orientation.description_right}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
        </div>
        {/* BAR */}
        <OrientationBar
          editMode={editMode}
          onChange={onChange}
          direction={orientation.direction}
          percent={orientation.value}
        />
        <div className="w-full max-lg:pt-4">
          {/* DESCRIPTION */}
          <div
            className={`rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
          >
            <h5 className="pb-3 font-bold text-neutral-800">
              <span className="text-primary-800">{orientation.description_main_level}</span>{" "}
              {orientation.description_main_level === "Tam dengeli düzeyde"
                ? orientation.title + " Yönelimi"
                : orientation.description_main_title}
            </h5>
            <p className="text-[14px] font-medium leading-tight text-neutral-800">{orientation.description_main}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrientationCard;
