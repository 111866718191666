import { useStore } from "../../store/useStore";
import {
  AppleStoreButton,
  GoogleStoreButton,
  HumanasLogo,
  LocationOutlined,
  MailIcon,
  PhoneIcon,
  SendOutlined,
} from "../OldDesign/Icons";

const Footer = ({ hide }) => {
  const userType = useStore((state) => state.user.data.userType);
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations["Default Footer"],
  );
  return (
    <footer
      className={`${
        hide && "hidden"
      } bg-primary-600 leading-tight text-neutral-100 max-lg:pb-[75px]`}
    >
      <div className="container2 py-10 max-lg:py-6">
        <div className="grid grid-cols-4 border-b border-[#FFFFFF50] pb-9  max-lg:grid-cols-1 max-lg:gap-6 max-lg:pb-6">
          <div className="mr-auto flex flex-col justify-start gap-2">
            <HumanasLogo className="text-neutral-100" />
            <p className="text-[20px] font-medium text-primary-300">
              {userType === "recruiter"
                ? content["recruiter slogan"]
                : content["candidate slogan"]}
            </p>
          </div>
          <ul className="flex flex-col gap-2.5 text-[16px] font-light ">
            <h4 className="text-[20px] font-semibold max-lg:text-[18px]">
              {content["content baslik"]}
            </h4>
            <li className="text-[16px] max-lg:text-[14px]">
              <a href="/dashboard" rel="noreferrer">
                {content["content dashboard"]}
              </a>
            </li>
            <li className="text-[16px] max-lg:text-[14px]">
              <a href="/community" rel="noreferrer">
                {content["content humanas community"]}
              </a>
            </li>
            {userType === "recruiter" && (
              <li className="text-[16px] max-lg:text-[14px]">
                <a href="/mynetwork" rel="noreferrer">
                  {content["content my network"]}
                </a>
              </li>
            )}
            {/* <li className="text-[16px] max-lg:text-[14px]">
              <a
                className="pointer-events-none"
                href="#"
                target="_blank"
                rel="noreferrer"
              >
                {content["content boost your network"]}
              </a>
            </li>
            <li className="text-[16px] max-lg:text-[14px]">
              <a
                className="pointer-events-none"
                href="#"
                target="_blank"
                rel="noreferrer"
              >
                {content["content suggestions"]}
              </a>
            </li> */}
          </ul>
          <div className="flex flex-col gap-2.5 text-[16px] font-light ">
            <h4 className="text-[20px] font-semibold max-lg:text-[18px]">
              {content["contact baslik"]}
            </h4>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <LocationOutlined className="h-[18px] w-[14px]" />
              <address className="not-italic max-lg:text-[14px]">
                {content["contact adres"]}
              </address>
            </div>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <PhoneIcon className="h-[15px] w-[16px]" />
              <a
                className="max-lg:text-[14px]"
                href={`tel:${content["contact telefon"]}`}
              >
                {content["contact telefon"]}
              </a>
            </div>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <MailIcon className="h-[12px] w-[15px]" />
              <a
                className="max-lg:text-[14px]"
                href={`mailto:${content["contact posta"]}`}
              >
                {content["contact posta"]}
              </a>
            </div>
          </div>
          {/* <div className="flex flex-col gap-2.5 text-[16px] font-light ">
            <h4 className="text-[20px] font-semibold max-lg:text-[18px]">
              {content["subscribe baslik"]}
            </h4>
            <p className="max-lg:text-[14px]">
              {content["subscribe aciklama"]}
            </p>
            <div className="relative flex w-[325px] flex-row pt-2.5 max-lg:w-full">
              <input
                type="email"
                placeholder={content["subscribe buton"]}
                className="w-full rounded-xl bg-neutral-100 p-3 text-black opacity-50 outline-none placeholder:text-[15px] placeholder:text-black"
              />
              <button className="absolute right-0 rounded-xl bg-white p-3">
                <SendOutlined className="h-5 w-5 text-primary-900" />
              </button>
            </div>
          </div> */}
        </div>
        <div className="flex flex-row items-center justify-between pt-9 max-lg:flex-col max-lg:gap-6 max-lg:pt-6">
          <div className="flex flex-row gap-2">
            <a
              href="https://play.google.com/store/apps/details?id=com.Humanas.LazyGuru&pcampaignid=web_share"
              target="_blank"
              rel="noreferrer"
            >
              <GoogleStoreButton className="max-lg:w-[150px]" />
            </a>
            <a
              href="https://apps.apple.com/tr/app/lazy-guru/id6451207671"
              target="_blank"
              rel="noreferrer"
            >
              <AppleStoreButton className="max-lg:w-[150px]" />
            </a>
          </div>
          <ul className="flex flex-row  gap-5 text-[15px] opacity-70 max-lg:gap-3 max-lg:text-[13px]">
            <li>
              <a
                href="https://humanas.io/protection-of-personal-data"
                target="_blank"
                rel="noreferrer"
                className="max-lg:font-light sm:whitespace-nowrap"
              >
                {content["protection of personal data"]}
              </a>
            </li>
            <li>
              <a
                href="https://humanas.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className=" max-lg:font-light sm:whitespace-nowrap"
              >
                {content["privacy policy"]}
              </a>
            </li>
            <li>
              <a
                href="https://humanas.io/cookie-policy"
                target="_blank"
                rel="noreferrer"
                className=" max-lg:font-light sm:whitespace-nowrap"
              >
                {content["cookie policy"]}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
