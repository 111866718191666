import React, { forwardRef, useState } from "react";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { CloseOutlined, LeftCircularOutlined } from "../../../OldDesign/Icons";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import { LANGUAGE_PROFICIENCY } from "../../constants";
import { REACT_BASE_URL } from "../../../../api/constants";

const LanguageLevelCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore(
    (state) => state.updateApplicationForm,
  );
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  const [showLanguageLevel, setShowLanguageLevel] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(
    coreCV.languages_array,
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { language: "", language_proficiency: "" },
    mode: "onSubmit",
  });
  const loadLanguageOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/language?rows=50&page=1&keyword=${value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const checkAndInsertData = (value) => {
    const selected_language = getValues("language");
    const selected_language_proficiency = getValues("language_proficiency");
    if (selected_language && selected_language_proficiency) {
      setSelectedLanguages((prev) => [
        ...prev,
        {
          language_name: selected_language.language_name,
          language_id: selected_language.language_id,
          language_proficiency: selected_language_proficiency.value,
        },
      ]);
      reset();
      setShowLanguageLevel(false);
    }
  };

  const sendFormData = () => {
    const languages_array = selectedLanguages.map((lang) => ({
      language_id: lang.language_id,
      language_proficiency: lang.language_proficiency,
    }));

    // console.log({ languages_array });
    if (languages_array.length > 0) {
      updateApplicationForm({ languages_array }, props.handleForwardForm);
    } else {
      setError("language", { message: "Required" });
    }
  };
  return (
    <>
      <Form
        className="flex h-full flex-col justify-between"
        onSubmit={handleSubmit(sendFormData)}
      >
        <fieldset className="flex  flex-col justify-start gap-4">
          <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
            {content["dil seviyesi baslik"]}
          </h2>
          <Field label={content["dil bilgisi baslik"]} error={errors?.language}>
            <Controller
              control={control}
              name="language"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  onChange={(e) => {
                    if (e !== null) {
                      onChange(e);
                      setShowLanguageLevel(true);
                    } else {
                      onChange("");
                      setShowLanguageLevel(false);
                    }
                  }}
                  value={value}
                  // selected={value}
                  loadOptions={loadLanguageOptions}
                  placeholder="Seçiniz"
                  isClearable
                  defaultOptions
                  getOptionLabel={(opt) => opt.language_name}
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      borderRadius: "8px",
                      borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                      borderWidth: "1px",
                    }),
                    valueContainer: (styles, state) => ({
                      ...styles,
                      height: "50px",
                      paddingLeft: "16px",
                    }),
                  }}
                />
              )}
            />
          </Field>
          {showLanguageLevel && (
            <Field
              label={content["dil seviyesi baslik"]}
              error={errors?.language_proficiency}
            >
              <Controller
                control={control}
                name="language_proficiency"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={(e) => {
                      onChange(e);
                      checkAndInsertData(e);
                    }}
                    onBlur={onBlur}
                    selected={value}
                    options={LANGUAGE_PROFICIENCY}
                    placeholder="Seçiniz"
                    isSearchable
                    getOptionLabel={(opt) => opt.label}
                    getOptionValue={(opt) => opt.value}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          )}
          <div className="flex flex-wrap justify-start gap-1 pb-6">
            {selectedLanguages.map((selected, index) => (
              <div
                className="flex items-center gap-1 rounded-full bg-primary-500 px-4 py-2 text-[14px] leading-tight text-primary-50"
                key={index}
              >
                {selected.language_name}{" "}
                {
                  LANGUAGE_PROFICIENCY.find(
                    (prof) => prof.value === selected.language_proficiency,
                  ).label
                }
                <button
                  type="button"
                  onClick={() =>
                    setSelectedLanguages((prev) =>
                      prev.filter((a, i) => index !== i),
                    )
                  }
                >
                  <CloseOutlined className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
          {/* <div className="relative mt-[100px] flex items-center">
            <input
              name="kvkk"
              id="kvkk"
              {...register("kvkk", { required: "Gereklidir" })}
              type="checkbox"
              className={`input-checkbox peer z-[1] h-5 w-7 cursor-pointer opacity-0`}
            />
            <span
              className={`input-checkmark absolute left-0 top-0 h-5 w-5 rounded-[4px] border border-[#D2D2D7] bg-white peer-checked:border-[#106BAC] peer-checked:bg-[#106BAC]`}
            ></span>
            <label
              htmlFor="kvkk"
              className="pt-0.5 text-[15px] leading-tight text-neutral-900"
            >
              <a href="#" className="font-bold">
                KVKK metni
              </a>
              'ni okudum,onayliyorum
            </label>
            {errors.kvkk && (
              <small className="pt-1 text-error-400">
                <ExclamationIcon className="mb-1 mr-1 inline-block h-4 w-4" />
                {errors.kvkk.message}
              </small>
            )}
          </div> */}
        </fieldset>
        <div className="flex justify-center gap-20">
          <button
            disabled={false}
            type="button"
            id="backwardArrow"
            onClick={() => props.handleBackwardForm()}
          >
            <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
          </button>
          <button disabled={false} type="submit" id="forwardArrow">
            <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
          </button>
        </div>
      </Form>
    </>
  );
};

export default LanguageLevelCard;
