import React, { useEffect, useRef, useState } from "react";
import { ClockIcon } from "./Icons";

const CountdownTimer = ({ expire_time }) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + expire_time || 180);
    return deadline;
  };

  useEffect(() => {
    const startTimer = (e) => {
      let { total, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
          (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds),
        );
      }
    };
    const clearTimer = (e) => {
      setTimer("03:00");

      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1000);
      Ref.current = id;
    };
    const onClickReset = () => {
      clearTimer(getDeadTime());
    };
    onClickReset();
  }, [expire_time]);

  return (
    <div className="flex flex-row items-center justify-center gap-1">
      <ClockIcon color="#0D568A" className="h-5 w-5" />
      <p className="text-[16px] font-medium leading-5 text-primary-700">
        {timer}
      </p>
    </div>
  );
};

export default CountdownTimer;
