import React, { useEffect, useState } from "react";
import GradientBorder from "../../../components/NewDesign/GradientBorder";
import ExpBar from "../../../components/NewDesign/StatusBar/ExpBar";
import { GoAnalysis, GoIdentities, GoPositions } from "../../../components/NewDesign/Illustrations";
import {
  LeftCircularOutlined,
  PeopleFilled,
  PlusOutlined,
  RightArrowOutlined,
} from "../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/OldDesign/Loading";
import { useStore } from "../../../store/useStore";
import cnaRoadMap from "../../../assets/cna-roadmap.png";
const PositionsList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openAnalysisModal = (id) => {
    navigate(`/jobposition/${id}/home`, {
      state: { previousLocation: location },
    });
  };

  const getJobPositions = useStore((state) => state.getJobPositions);
  const jobPositions = useStore((state) => state.jobPositions);

  useEffect(() => {
    getJobPositions();
  }, [getJobPositions]);

  return (
    <>
      <div className="flex items-center justify-between pb-4">
        <h2 className="text-[20px] font-bold leading-tight text-primary-900">İş Pozisyonu Uygunluk Analizi</h2>
        <span className="rounded-full bg-primary-200 px-2 py-1 text-sm font-semibold leading-tight text-primary-600">
          {jobPositions.data.rows?.filter((job) => job.state === "completed").length}/{jobPositions.data.rows?.length}
        </span>
      </div>
      <p className="pb-4 text-sm leading-tight text-primary-900">
        İş pozisyonlarınızdan birini seçerek analiz yapmaya devam edin.
      </p>
      <div className="-mx-3 -my-1 flex h-0 min-h-[calc(100%-71px)] flex-col gap-2 overflow-y-auto px-3 py-1 max-lg:min-h-[280px]">
        {jobPositions.isLoaded ? (
          jobPositions.data.rows.map((position, index) => {
            return (
              <div
                key={index}
                className="flex justify-between rounded-lg bg-secondary_recruiter-100  px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] transition-all hover:bg-secondary_recruiter-200"
              >
                <div className="flex flex-col gap-1">
                  <p className="font-medium leading-tight text-primary-800">{position.name}</p>
                  <span className="flex items-center gap-0.5 text-[13px] font-medium leading-tight text-primary-800">
                    <PeopleFilled className="h-3 w-3" />
                    {position.seat_count}
                  </span>
                </div>
                {position.state === "completed" ? (
                  <button
                    onClick={() => navigate(`/cna/my-positions/${position.id}`)}
                    className="flex items-center gap-1 text-[14px] font-bold leading-tight text-success-500"
                  >
                    Görüntüle <RightArrowOutlined className="h-4 w-4" />
                  </button>
                ) : (
                  <button
                    onClick={() => openAnalysisModal(position.id)}
                    className="flex items-center gap-1 text-[14px] font-bold leading-tight text-primary-600"
                  >
                    Başla <RightArrowOutlined className="h-4 w-4" />
                  </button>
                )}
              </div>
            );
          })
        ) : jobPositions.isLoading ? (
          <Loading />
        ) : null}
      </div>
    </>
  );
};

const CNAMainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <section className="bg-cna-gradientblur bg-cover pt-[64px] max-lg:mb-[55px] max-lg:pt-[54px] lg:h-screen lg:w-screen lg:overflow-hidden">
      <div className="container2 grid grid-cols-4 gap-6 py-4 max-lg:grid-cols-1">
        {/* ROAD MAP */}
        {/* <div className="rounded-xl bg-primary-700 p-6 max-lg:hidden lg:col-span-3">
          <div className="pb-3">
            <h2 className="text-[20px] font-bold leading-tight text-neutral-100">İhtiyaç Yol Haritası</h2>
          </div>
          <div className="relative flex h-[123px] w-full items-center justify-center">
            <SmallMapPath className="absolute max-xl:w-[600px]" />

            <div className="absolute left-[5%] top-2 flex items-center justify-center">
              <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-100">1</span>
              <SmallCheckpointFilled />
              <p className="absolute top-14 text-center font-semibold leading-tight text-neutral-100">
                İş Pozisyonu Uygunluk Analizi
              </p>
            </div>

            <div className="absolute bottom-3 left-[24%] flex items-center justify-center">
              <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700">2</span>
              <SmallCheckpointNotFilled />
              <p className="absolute top-14 text-center font-medium leading-tight text-neutral-400">Lorem Ipsum</p>
            </div>

            <div className="absolute left-[44%] top-2 flex items-center justify-center">
              <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700">3</span>
              <SmallCheckpointNotFilled />
              <p className="absolute top-14 text-center font-medium leading-tight text-neutral-400">Lorem Ipsum</p>
            </div>

            <div className="absolute bottom-3 left-[64%] flex items-center justify-center">
              <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700">4</span>
              <SmallCheckpointNotFilled />
              <p className="absolute top-14 text-center font-medium leading-tight text-neutral-400">Lorem Ipsum</p>
            </div>

            <div className="absolute left-[84%] top-2 flex items-center justify-center">
              <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700">5</span>
              <SmallCheckpointNotFilled />
              <p className="absolute top-14 text-center font-medium leading-tight text-neutral-400">Lorem Ipsum</p>
            </div>
          </div>
        </div> */}
        <GradientBorder
          gradientStart={"#536cf0"}
          gradientEnd={"#ffffff"}
          className="cnaRoadmapBg relative rounded-xl bg-secondary_recruiter-200 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] max-lg:hidden lg:col-span-3 lg:backdrop-blur-md"
        >
          <div className="p-6">
            <h2 className="text-[20px] font-bold leading-tight text-neutral-100">Yol Haritanız</h2>
          </div>
          <img src={cnaRoadMap} alt="roadmap" className="absolute bottom-0 left-1/2 -translate-x-1/2" />
        </GradientBorder>
        {/* PROFILE */}
        <GradientBorder className="bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
          <div className="flex items-center gap-3 pb-4">
            <img src="" alt="" className="h-[77px] w-[77px] rounded-full bg-neutral-100" />
            <div className="flex flex-col gap-1">
              <h3 className="text-[18px] font-semibold leading-tight text-primary-800">Humanas</h3>
              <p className="text-sm font-medium leading-tight text-neutral-700">Izmir/Turkiye</p>
            </div>
          </div>
          <div className="pb-4">
            <div className="flex justify-between pb-1.5">
              <p className="text-sm font-medium leading-tight text-primary-700">Krediler</p>
              <span className="text-sm font-medium leading-tight text-primary-700">26/30</span>
            </div>
            <ExpBar percent={87} />
          </div>
          <div>
            <div className="flex justify-between pb-1.5">
              <p className="text-sm font-medium leading-tight text-primary-700">Tecrube Puani</p>
              <span className="text-sm font-medium leading-tight text-primary-700">170/200</span>
            </div>
            <ExpBar percent={92} />
          </div>
        </GradientBorder>
        <div className="grid grid-cols-2 gap-4 max-lg:grid-cols-1 lg:col-span-3">
          {/* COMPANY QUESTIONS */}
          <GradientBorder className="bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
            <div className="flex items-center justify-between pb-4">
              <h2 className="text-[20px] font-bold leading-tight text-primary-900">Şirket Profili</h2>
              <span className="rounded-full bg-primary-200 px-2 py-1 text-sm font-semibold leading-tight text-primary-600">
                3/4
              </span>
            </div>
            <p className="pb-4 text-sm leading-tight text-primary-900">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pretium turpis luctus faucibus?
            </p>
            <div className="grid grid-cols-2 grid-rows-2 gap-4 pb-4">
              <div className="flex min-h-[95px] items-center rounded-xl border border-neutral-100 bg-secondary_recruiter-100 p-4 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25),0px_0px_6px_0px_rgba(255,255,255,0.40)]">
                <p className="font-medium leading-tight text-primary-800">Cevap 1</p>
              </div>
              <div className="flex min-h-[95px] items-center rounded-xl border border-neutral-100 bg-secondary_recruiter-100 p-4 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25),0px_0px_6px_0px_rgba(255,255,255,0.40)]">
                <p className="font-medium leading-tight text-primary-800">Cevap 2</p>
              </div>
              <div className="flex min-h-[95px] items-center rounded-xl border border-neutral-100 bg-secondary_recruiter-500 p-4 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25),0px_0px_6px_0px_rgba(255,255,255,0.40)]">
                <p className="font-medium leading-tight text-neutral-100">Cevap 3</p>
              </div>
              <div className="flex min-h-[95px] items-center rounded-xl border border-neutral-100 bg-secondary_recruiter-100 p-4 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25),0px_0px_6px_0px_rgba(255,255,255,0.40)]">
                <p className="font-medium leading-tight text-primary-800">Cevap 4</p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button>
                <LeftCircularOutlined className="h-8 w-8 text-primary-700" />
              </button>
              <button>
                <LeftCircularOutlined className="h-8 w-8 rotate-180 text-primary-700" />
              </button>
            </div>
          </GradientBorder>
          {/* POSITION ANALYSES */}
          <GradientBorder className="bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
            <PositionsList />
          </GradientBorder>
        </div>
        {/* NAVIGATION MENU */}
        <div className="flex flex-col gap-3">
          {/* go analysis */}
          <GradientBorder className="flex-1 bg-[rgba(142,143,250,0.15)] px-6 py-4 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
            <div className="flex items-center justify-between pb-3">
              <span className="flex items-center gap-3">
                <GoAnalysis />
                <h4 className="text-[18px] font-bold leading-tight text-secondary_recruiter-700">Analizlerime git</h4>
              </span>
              <button onClick={() => navigate("/cna/my-analyses")}>
                <RightArrowOutlined className="h-6 w-6 text-secondary_recruiter-700" />
              </button>
            </div>
            <div className="flex flex-row-reverse">
              <button className="flex items-center gap-1 text-sm font-semibold leading-tight text-secondary_recruiter-600">
                <PlusOutlined className="h-4 w-4" />
                Analiz Ekle
              </button>
            </div>
          </GradientBorder>
          {/* go identities */}
          <GradientBorder className="flex-1 bg-[rgba(142,143,250,0.15)] px-6 py-4 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
            <div className="flex items-center justify-between pb-3">
              <span className="flex items-center gap-3">
                <GoIdentities />
                <h4 className="text-[18px] font-bold leading-tight text-secondary_recruiter-700">Kimliklerime git</h4>
              </span>
              <button>
                <RightArrowOutlined className="h-6 w-6 text-secondary_recruiter-700" />
              </button>
            </div>
            <div className="flex flex-row-reverse">
              <button className="flex items-center gap-1 text-sm font-semibold leading-tight text-secondary_recruiter-600">
                <PlusOutlined className="h-4 w-4" />
                Kimlik Ekle
              </button>
            </div>
          </GradientBorder>
          {/* go positions */}
          <GradientBorder className="flex-1 bg-[rgba(142,143,250,0.15)] px-6 py-4 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] lg:backdrop-blur-md">
            <div className="flex items-center justify-between pb-3">
              <span className="flex items-center gap-3">
                <GoPositions />
                <h4 className="text-[18px] font-bold leading-tight text-secondary_recruiter-700">
                  İş Pozisyonlarıma Git
                </h4>
              </span>
              <button onClick={() => navigate("/cna/my-positions")}>
                <RightArrowOutlined className="h-6 w-6 text-secondary_recruiter-700" />
              </button>
            </div>
            <div className="flex flex-row-reverse">
              <button
                onClick={() => {
                  navigate(`/add-position`, {
                    state: { previousLocation: location },
                  });
                }}
                className="flex items-center gap-1 text-sm font-semibold leading-tight text-secondary_recruiter-600"
              >
                <PlusOutlined className="h-4 w-4" />
                Pozisyon Ekle
              </button>
            </div>
          </GradientBorder>
        </div>
      </div>
    </section>
  );
};

export default CNAMainPage;
