import { useEffect } from "react";
import HeroRecruiter from "../../components/NewDesign/Hero/Dashboard/HeroRecruiter";
import HumanasSystem from "../../components/NewDesign/HumanasSystem";
import OurScience from "../../components/NewDesign/OurScience";
import Statistics from "../../components/NewDesign/Statistics";
import { useStore } from "../../store/useStore";
import Loading from "../../components/OldDesign/Loading";

const Dashboard = () => {
  const getDashboard = useStore((state) => state.getDashboard);
  const dashboard = useStore((state) => state.dashboard);
  const appLanguage = useStore((state) => state.appLanguage);

  useEffect(() => {
    getDashboard();
  }, [getDashboard, appLanguage]);

  if (dashboard.isLoading) {
    return <Loading />;
  }
  if (!dashboard.isLoaded) {
    return null;
  }
  return (
    <>
      <HeroRecruiter />
      <Statistics />
      <HumanasSystem />
      <OurScience />
    </>
  );
};

export default Dashboard;
