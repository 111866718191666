import React, { useEffect } from "react";
import {
  MagicFilled,
  PlusOutlined,
  SendFilled,
  WorldFilled,
} from "../../../components/OldDesign/Icons";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../store/useStore";
import { MY_NETWORK_TABS } from "../../../components/NewDesign/constants";
import { Link } from "react-router-dom";

const MyNetwork = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const content = useStore(
    (state) =>
      state.content.data["My Network"].content.translations[
        "Recruiter My Network Tables"
      ],
  );
  const content2 = useStore(
    (state) =>
      state.content.data["My Network"].content.translations[
        "Recruiter Info Boxes"
      ],
  );
  const myNetworkTabs = MY_NETWORK_TABS();
  const statistics = useStore((state) => state.dashboard.data.statistics);
  const isModalOpenFromState = location.state?.isModalOpen || false;
  const inviteTypeFromState = location.state?.inviteType || "Candidate";

  useEffect(() => {
    if (isModalOpenFromState) {
      navigate("/invite", {
        state: { previousLocation: location },
      });
    }
  }, [isModalOpenFromState, navigate]);
  return (
    <main className="bg-neutral-200 max-lg:bg-neutral-100 max-lg:pt-[53px]">
      {/* Lists */}
      <div className="container2 py-6 max-lg:py-3" id="mynetwork">
        <div className="rounded-xl bg-neutral-100 p-9 max-lg:p-0">
          {/* ACTIONS */}
          <div className="lg:flex lg:flex-row-reverse lg:justify-between lg:pb-1">
            <div className="grid grid-cols-3 gap-1 max-lg:pb-2">
              <Link
                className=""
                to="/invite"
                state={{ previousLocation: location }}
              >
                <button className="flex w-full items-center justify-center gap-1 rounded-lg bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#88A9FD_0%,#1F0877_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <SendFilled className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Davet Et</span>
                </button>
              </Link>
              <Link
                className=""
                to="/create-group"
                state={{ previousLocation: location }}
              >
                <button className="flex w-full items-center justify-center gap-1 rounded-lg bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#51CBB6_0%,#223C3D_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <PlusOutlined className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Grupla</span>
                </button>
              </Link>
              <Link
                className=""
                to="/match"
                state={{ previousLocation: location }}
              >
                <button className="flex w-full items-center justify-center gap-1 rounded-lg bg-[radial-gradient(143.14%_158.2%_at_104.39%_0%,#1C8BD8_0%,#132A38_100%)] p-3 text-neutral-100 max-lg:px-1.5 max-lg:text-[13px]">
                  <MagicFilled className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
                  <span className="font-medium leading-tight">Match</span>
                </button>
              </Link>
            </div>
            <div className="flex items-baseline gap-3 max-lg:gap-1">
              <WorldFilled className="h-7 w-7 max-lg:h-4 max-lg:w-4" />
              <h3 className="text-[32px] font-bold leading-tight text-primary-900 max-lg:text-[18px]">
                {content["my network baslik"]}
              </h3>
            </div>
          </div>
          <p className="pb-6 text-[16px] font-medium leading-tight text-neutral-700 max-lg:pb-2 max-lg:text-[13px]">
            {content["my network aciklama"]}
          </p>

          <ul className="scrollbar-hide mb-2 flex items-center justify-start gap-6 overflow-x-scroll border-b-2 ">
            {myNetworkTabs.map((tab, idx) => (
              <MyNetworkTab key={idx} tab={tab} />
            ))}
          </ul>

          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};
const MyNetworkTab = ({ tab }) => {
  return (
    <NavLink
      to={tab.path}
      style={({ isActive, isPending }) => {
        return {
          color: isActive ? "#4D5AA6" : "#C2C2D6",
          borderColor: isActive ? "#4D5AA6" : "",
          borderBottom: isActive ? "2px solid #4D5AA6" : "",
          borderRadius: 2,
        };
      }}
      className="whitespace-nowrap pb-3 text-[18px] font-bold leading-tight max-lg:pb-1 max-lg:text-[15px]"
    >
      {tab.title}
    </NavLink>
  );
};

export default MyNetwork;
