import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./index.css";
import { history } from "./utils/history";
import CustomBrowserRouter from "./utils/CustomBrowserRouter";
import { TopScroller } from "./utils/TopScroller";

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-5HJJHJM3",
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CustomBrowserRouter history={history}>
    {/* <React.StrictMode> */}
    <App />
    <TopScroller />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    {/* </React.StrictMode> */}
  </CustomBrowserRouter>,
);
