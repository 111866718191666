import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

export const Form = forwardRef(
  ({ className, children, onSubmit, nextStep, ...props }, ref) => {
    const navigate = useNavigate();

    const onSubmitCustom = (e) => {
      e.stopPropagation();
      e.preventDefault();
      onSubmit();
      if (nextStep) navigate(nextStep);
    };

    return (
      <form
        className={className}
        ref={ref}
        onSubmit={onSubmitCustom}
        {...props}
        noValidate
      >
        {children}
      </form>
    );
  },
);
