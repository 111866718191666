import React from "react";
import SectionTitle from "./SectionTitle";
import ScienceCard from "./ScienceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useStore } from "../../store/useStore";

const SCIENCES = [
  {
    title: "İnsan Analitiği İş Dünyasını Şekillendiriyor!",
    content:
      "Günümüz dünyasında pek çok sektörün ilerlemesi ya da iş süreçlerinin geliştirilmesi ve/ veya iyileştirilmesi için farklı araştırmalardan ve araçlardan faydalanılıyor. Bu araçlardan biri de, insan analitiği. İnsan analitiği sayesinde işletmeler, müşteri davranışını anlamlandırıyor, bu davranışları pazarlama stratejilerini optimize ediyor ve mağaza-çalışan performanslarını artırıyorlar. Tüm bu süreçlerin nasıl olduğunu ya da nasıl işlediğini merak ediyorsanız vakit kaybetmeden detaylara geçelim.",
  },
];

const OurScience = () => {
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations[
        "Bilimsel Makaleler"
      ],
  );
  return (
    <section className="container2 py-2">
      <SectionTitle title={content["bilimsel makaleler baslik"]} />

      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: false,
        }}
        centeredSlides
        // centeredSlidesBounds
        // centerInsufficientSlides
        // loop
        // spaceBetween={24}
        className="sciences"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
      >
        {SCIENCES.map((science, index) => {
          return (
            <SwiperSlide key={index} className="">
              <ScienceCard key={index} science={science} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default OurScience;
