import React from "react";
import HeroCommunityRecruiter from "../../../components/NewDesign/Hero/Community/HeroCommunityRecruiter";
import CorpNeedAnalysis from "../../../components/NewDesign/CorpNeedAnalysis";
import HumanasStatistics from "../../../components/NewDesign/HumanasStatistics";
import Events from "../../../components/NewDesign/Events";
import SupportCenterCard from "../../../components/NewDesign/SupportCenterCard";
import Button from "../../../components/NewDesign/Button/Button";
import WhatIsCommunity from "../../candidate/Community/WhatIsCommunity";
import CompanyWeWork from "../../candidate/Community/CompanyWeWork";
import { useStore } from "../../../store/useStore";
import { WorldIllustration } from "../../../components/NewDesign/Illustrations";

const CommunityRecruiter = () => {
  const user = useStore((state) => state.user.data);
  return (
    <main className="bg-neutral-200 pt-4 max-lg:pt-[53px]">
      <div className="container2 relative my-3 flex items-center gap-12 rounded-xl bg-[linear-gradient(261deg,#316BCD_24.64%,#0D568A_99.34%)] px-16 py-9 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:px-4 max-lg:py-6 lg:h-[650px]">
        <div>
          <p className="pb-6 text-[22px] font-semibold leading-tight text-primary-50 max-lg:pb-3 max-lg:text-[14px]">
            Hoşgeldin {user.userName}
          </p>
          <p className="pb-8 text-[52px] font-light leading-tight max-lg:pb-3 max-lg:text-[22px]">
            Humanas Topluluğu
            <br />
            <span className="font-extrabold text-primary-100">
              Ayrıcalıklarından
            </span>
            <br />
            Yararlan
          </p>
          <p className="text-[13px]">
            Yenilikçi sistemimiz, nitelikli adayları ve potansiyelli yetenekleri
            keşfetmenizi sağlar. İşletmelerin ihtiyaçlarına özel olarak
            tasarlanmış bu çözüm, karmaşık veri analizi ve yapay zeka
            algoritmalarını kullanarak en uygun adayları belirlemenize yardımcı
            olur.
          </p>
        </div>
        <div className="right-2 top-2 max-lg:absolute">
          <WorldIllustration className="max-lg:w-[120px] lg:w-[500px]" />
        </div>
      </div>
      <WhatIsCommunity />
      <CompanyWeWork />
      {/* <HeroCommunityRecruiter />
      <CorpNeedAnalysis />
      <HumanasStatistics />
      <div className="container2 grid grid-cols-4 max-lg:grid-cols-1">
        <div className="col-span-3">
          <Events />
        </div>
        <div className="col-span-1">
          <SupportCenterCard />
        </div>
      </div>
      <div className="container2 flex justify-between rounded-xl bg-neutral-100 px-4 py-6 max-lg:flex-col lg:items-center">
        <p className="text-center text-[14px] leading-tight text-primary-800 max-lg:pb-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce posuere
          aliquam nunc eget ullamcorper.
        </p>
        <Button>Access My Network</Button>
      </div> */}
    </main>
  );
};

export default CommunityRecruiter;
