import React from "react";
import { CheckOutlined } from "../../OldDesign/Icons";
import { useStore } from "../../../store/useStore";

const LevelBar = ({ levels }) => {
  const levelNumber = levels.length;
  const levelWidth = 100 / levelNumber;
  return (
    <>
      <div className="flex w-full shrink-0 divide-x-2 divide-success-100 overflow-hidden  rounded-full border border-primary-100 bg-primary-100 bg-opacity-50 text-neutral-800 max-lg:bg-primary-400 max-lg:bg-opacity-50 max-lg:text-primary-100">
        {levels.map((level) => {
          return (
            <div
              style={{
                width: `${levelWidth}%`,
                background: `${level.completed ? "#0AA973" : ""}`,
                color: `${level.completed ? "#E7F0F7" : ""}`,
              }}
              key={level.name}
              className="flex items-center justify-center gap-1 py-1.5 text-center text-[13px] font-semibold leading-tight"
            >
              {level.name}
              {level.completed && (
                <CheckOutlined className="w-[14px h-[14px]" />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LevelBar;
