import React from "react";
import { CheckOutlined, RightArrowOutlined } from "../OldDesign/Icons";
import { ButtonWithIcon } from "../OldDesign/Button";
import { Link } from "react-router-dom";
import { useStore } from "../../store/useStore";

const ScienceCard = ({ science }) => {
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations[
        "Bilimsel Makaleler"
      ],
  );
  return (
    <div className="flex flex-col items-center justify-between rounded-xl border border-neutral-300 p-6 max-lg:p-3">
      <CheckOutlined className="h-[58px] w-[58px] shrink-0 max-lg:h-[42px] max-lg:w-[42px]" />
      <div className=" flex flex-col items-center gap-1">
        <h3 className="pt-1 text-center text-[20px] font-semibold leading-tight text-primary-900 max-lg:text-[14px]">
          {content["bilimsel makaleler kart 1 baslik"]}
        </h3>
        <p className="line-clamp-4 pb-1 text-center leading-tight text-neutral-700 max-lg:text-[13px]">
          {content["bilimsel makaleler kart 1 aciklama"]}
        </p>
      </div>
      <Link
        to="https://humanas.io/case-study/insan-analitigi-is-dunyasini-sekillendiriyor/8"
        target="_blank"
      >
        <ButtonWithIcon
          className="bg-neutral-100 p-0"
          icon2={<RightArrowOutlined className="h-5 w-5" />}
        >
          {content["bilimsel makaleler kart 1 buton"]}
        </ButtonWithIcon>
      </Link>
    </div>
  );
};

export default ScienceCard;
