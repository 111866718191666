import React, { useEffect } from "react";
import {
  ChevronRightOutlined,
  CloseCircleIcon,
  MatchFilled,
  TickOutlined,
} from "../OldDesign/Icons";
import { useStore } from "../../store/useStore";
import GroupCard from "./GroupCard";
import Button from "./Button/Button";
import { useState } from "react";
import { groupActions } from "../../api/endpoints";
import MatchReport from "./MatchReport";
import Loading from "../OldDesign/Loading";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar/SearchBar";

const MatchGroups = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const groupList = useStore((state) => state.groupList);
  const getGroupList = useStore((state) => state.getGroupList);
  const [refGroup, setRefGroup] = useState(null);
  const [secondGroup, setSecondGroup] = useState(null);
  const [compareResult, setCompareResult] = useState(null);
  const [compareLoading, setCompareLoading] = useState(false);
  const steps = ["Referans Grup", "Hedef Grup", "Match Raporu"];
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredGroups, setFilteredGroups] = useState(groupList.data);

  const handleGroupSelect = (e, group) => {
    e.stopPropagation();
    if (currentStep === 1) {
      setRefGroup(group);
    } else if (currentStep === 2) {
      setSecondGroup(group);
    }
  };

  const handleMatchReport = () => {
    const payload = {
      action: {
        type: "compare",
        params: {
          group_reference: refGroup.id,
          group_target: secondGroup.id,
        },
      },
    };
    groupActions(
      payload,
      (res) => {
        setCompareResult(res.data);
        // console.log(res.data);
      },
      (err) => console.log(err),
      (bool) => setCompareLoading(bool),
    );
  };
  const handleNextStep = () => {
    if (currentStep === 1) {
      refGroup && setCurrentStep((prev) => prev + 1);
    } else if (currentStep === 2) {
      secondGroup && setCurrentStep((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (!groupList.isLoaded) {
      getGroupList();
    }
  }, []);
  useEffect(() => {
    setFilteredGroups(groupList.data);
  }, [groupList]);
  useEffect(() => {
    setFilteredGroups((prev) => {
      let newFilteredGroups = [...prev].filter(
        (group) =>
          group.name
            .toLocaleLowerCase()
            .includes(searchKeyword.toLocaleLowerCase()) && group,
      );
      if (searchKeyword === "") {
        newFilteredGroups = groupList.data;
      }
      return newFilteredGroups;
    });
  }, [searchKeyword]);
  return (
    <div className="max-lg:min-w-[355px] lg:min-w-[95vw] lg:max-w-[1366px]">
      {compareLoading ? (
        <Loading />
      ) : compareResult ? (
        <MatchReport report={compareResult} />
      ) : (
        <div className="p-6 max-lg:p-1">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="absolute right-6 top-2 h-4 w-4"
          >
            <CloseCircleIcon className="" />
          </button>
          <h5 className="pb-4 text-center text-[32px] font-bold leading-tight max-lg:px-6 max-lg:pb-1 max-lg:pt-4 max-lg:text-[20px]">
            Mükemmel Eşleşme için karşılaştırmaya başla!
          </h5>
          {/* STEPPER */}
          <div className="relative mx-auto mb-4 flex max-w-[966px]  justify-between py-3 before:absolute before:left-1/2 before:top-1/2 before:h-[3px] before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:bg-neutral-500 before:content-[''] max-lg:mb-2 max-lg:before:top-1/3 max-lg:before:w-[75%]">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`relative px-6 max-lg:px-1 lg:bg-neutral-100`}
              >
                <div className="flex items-center gap-3 max-lg:flex-col">
                  <p
                    className={`${
                      currentStep < index + 1
                        ? "border-secondary_recruiter-300 bg-secondary_recruiter-300 text-neutral-100"
                        : "border-secondary_recruiter-600 bg-secondary_recruiter-600 text-neutral-100"
                    } ${currentStep > index + 1 && "bg-primary-600"} ${
                      currentStep === index + 1 ? "scale-125" : "scale-100"
                    } flex h-10 w-10 items-center justify-center rounded-full border text-[22px] font-medium leading-tight transition-all max-lg:h-8 max-lg:w-8 max-lg:text-[13px]`}
                  >
                    {currentStep > index + 1 ? (
                      <TickOutlined className="h-6 w-6 text-neutral-100" />
                    ) : (
                      index + 1
                    )}
                  </p>
                  <p
                    className={`${
                      currentStep < index + 1
                        ? "text-neutral-500"
                        : "text-primary-600"
                    } text-[20px] font-semibold leading-tight max-lg:text-[13px]`}
                  >
                    {step}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* MATCH PART */}
          <div className="flex items-center justify-around gap-4 rounded-xl border border-neutral-300 bg-neutral-200 p-3 max-lg:flex-col max-lg:gap-1 max-lg:p-1">
            <button
              disabled={currentStep === 1}
              onClick={() => setCurrentStep(currentStep - 1)}
              className="disabled:opacity-20 max-lg:hidden"
            >
              <ChevronRightOutlined className="h-20 w-20 rotate-180 text-secondary_recruiter-600" />
            </button>
            <div className="flex h-[210px] w-[338px] items-center justify-center rounded-2xl border border-dashed border-secondary_recruiter-700 bg-neutral-100 max-lg:h-[180px] max-lg:w-[335px]">
              {refGroup ? (
                <GroupCard group={refGroup} noButtons small />
              ) : (
                <p className="text-center text-[20px] font-semibold leading-tight text-secondary_recruiter-700 max-lg:text-[14px]">
                  Referans grubunu seç
                </p>
              )}
            </div>
            <button
              disabled={currentStep !== 3}
              onClick={() => handleMatchReport()}
              className="relative h-[150px] w-[150px] rounded-full disabled:opacity-40 max-lg:h-[50px]  max-lg:w-[50px]"
            >
              {currentStep === 3 && (
                <>
                  <p className="absolute translate-y-1/2 whitespace-nowrap text-[16px] font-medium text-secondary_recruiter-600 max-lg:left-12 max-lg:top-0 lg:-bottom-1 lg:left-1/2 lg:-translate-x-1/2">
                    Match!
                  </p>
                  <MatchFilled className="absolute bottom-1/2 left-1/2 w-[72px] -translate-x-1/2 translate-y-1/2 scale-125 animate-pulse text-secondary_recruiter-700 blur-sm max-lg:w-[28px]" />
                </>
              )}
              <MatchFilled className="absolute bottom-1/2 left-1/2 w-[72px] -translate-x-1/2 translate-y-1/2 text-secondary_recruiter-600 max-lg:w-[28px]" />
            </button>
            <div
              className={`${
                currentStep === 1 && "opacity-50 grayscale"
              } flex h-[210px] w-[338px] items-center justify-center rounded-2xl border border-dashed border-secondary_recruiter-700 bg-neutral-100 max-lg:h-[180px] max-lg:w-[335px]`}
            >
              {secondGroup ? (
                <GroupCard group={secondGroup} noButtons small />
              ) : (
                <p className="text-center text-[20px] font-semibold leading-tight text-secondary_recruiter-700 max-lg:text-[14px]">
                  Karşılaştırmak istediğin grubu seç
                </p>
              )}
            </div>
            <button
              className="disabled:opacity-20 max-lg:hidden"
              disabled={
                currentStep === 3 ||
                (currentStep === 1 && !refGroup) ||
                (currentStep === 2 && !secondGroup)
              }
              onClick={() => handleNextStep()}
            >
              <ChevronRightOutlined
                className={`${
                  currentStep === 3
                } h-20 w-20 text-secondary_recruiter-600`}
              />
            </button>
          </div>
          {/* GROUPS */}
          <div
            className={`${
              currentStep === 3
                ? "invisible max-h-0 p-0 opacity-0"
                : "visible max-h-[350px] pt-4 opacity-100 max-lg:pt-2"
            } transition-all duration-[400ms] ease-linear`}
          >
            <div className="flex justify-between">
              <h6 className="text-[26px] font-semibold leading-tight text-secondary_recruiter-700">
                Gruplar
              </h6>
              <div>
                <SearchBar
                  placeholder={"Grup ismi ara"}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
            </div>
            <div className="flex items-center justify-start gap-3 overflow-auto px-2 py-4">
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group) => (
                  <button
                    key={group.id}
                    type="button"
                    onClick={(e) => handleGroupSelect(e, group)}
                    className={`${
                      refGroup === group &&
                      "shadow-[0_0_8px_1px] shadow-primary-500"
                    } ${
                      secondGroup === group &&
                      "shadow-[0_0_8px_1px] shadow-secondary_recruiter-500"
                    } cursor-pointer rounded-2xl`}
                  >
                    <GroupCard group={group} noButtons small />
                  </button>
                ))
              ) : (
                <div className="h-[210px]">Grup bulunamadı</div>
              )}
            </div>
          </div>
          {/* ACTION BUTTONS */}
          <div className="flex justify-between pt-3 lg:hidden">
            <Button
              className="bg-transparent text-primary-600"
              disabled={currentStep === 1}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Geri
            </Button>
            <Button
              loading={compareLoading}
              disabled={
                (currentStep === 1 && !refGroup) ||
                (currentStep === 2 && !secondGroup)
              }
              onClick={() => {
                currentStep === 3
                  ? handleMatchReport()
                  : setCurrentStep(currentStep + 1);
              }}
            >
              {currentStep === 3 ? "Eşleşme Raporunu Hazırla!" : "Devam Et"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchGroups;
