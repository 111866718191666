import React from "react";
import { useStore } from "../../../../store/useStore";

import { PurpleSmoke } from "../../Illustrations";
import { BagIconFilled, EmployeeFilled } from "../../../OldDesign/Icons";

import aidVideo2 from "../../../../assets/aidHeroMov.mp4";

const HeroAID = () => {
  const userCounts = useStore((state) => state.aid.data.user_counts);
  return (
    <div className="relative flex h-[350px] overflow-hidden rounded-xl bg-[#02001D] max-lg:h-[300px] max-lg:flex-col max-lg:items-center">
      <PurpleSmoke className={"absolute bottom-0 left-0 z-10 rotate-180"} />
      <div className="relative z-10 flex w-2/3 flex-col items-start justify-center gap-4 py-9 pl-9 max-lg:w-full max-lg:items-center max-lg:gap-3 max-lg:px-4 max-lg:pb-0 max-lg:pt-4">
        <h1 className="gradient-text inline-block animate-gradient text-4xl font-bold leading-tight text-transparent max-lg:text-center max-lg:text-[24px] max-lg:font-semibold">
          Humanas AID'e Hoşgeldiniz
        </h1>
        <p className="text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-center max-lg:text-[13px]">
          Humanas AID, iş süreçlerinde en çok ihtiyaç duyulan çalışan
          özelliklerinin derlenerek bir araya geldiği kimlikleri ifade
          etmektedir. Çalışan ve çalışan adaylarınızın mesleki yönelim /iş
          kimlikleri/iş karakterleri dağılımına göz atın ve hangi Humanas
          AID’lere sahip olduklarını görün.
        </p>
        <div className="flex gap-3">
          <div className="flex items-center gap-3 rounded-xl border border-secondary_recruiter-700 bg-secondary_recruiter-1000 px-4 py-2 max-lg:px-2 max-lg:py-1">
            <div
              className={`rounded-full bg-secondary_recruiter-100 p-2 max-lg:p-1`}
            >
              <BagIconFilled className="h-[26px] w-[26px] text-secondary_recruiter-700 max-lg:h-[12px] max-lg:w-[12px]" />
            </div>
            <p className="w-[172px] overflow-clip whitespace-nowrap text-[15px] font-semibold text-neutral-100 max-lg:w-[82px] max-lg:text-[9px]">
              Toplam Çalışan
            </p>
            <p className="text-[22px] font-semibold text-neutral-100 max-lg:text-[13px]">
              {userCounts.employee}
            </p>
          </div>
          <div className="flex items-center gap-3 rounded-xl border border-secondary_recruiter-700 bg-secondary_recruiter-1000 px-4 py-2 max-lg:px-2 max-lg:py-1">
            <div
              className={`rounded-full bg-secondary_recruiter-100 p-2 max-lg:p-1`}
            >
              <EmployeeFilled className="h-[26px] w-[26px] text-secondary_recruiter-700 max-lg:h-[12px] max-lg:w-[12px]" />
            </div>
            <p className="w-[172px] overflow-clip whitespace-nowrap text-[15px] font-semibold text-neutral-100 max-lg:w-[82px] max-lg:text-[9px]">
              Toplam Aday
            </p>
            <p className="text-[22px] font-semibold text-neutral-100 max-lg:text-[13px]">
              {userCounts.candidate}
            </p>
          </div>
        </div>
      </div>
      {/* <img src={AID_GIF} alt="aid" /> */}
      <div className="z-0 overflow-clip rounded-xl max-lg:absolute max-lg:-bottom-10">
        <video
          src={aidVideo2}
          type="video/mp4"
          autoPlay
          loop
          muted
          playsInline
          className="h-full w-full"
        ></video>
      </div>
      {/* <Lottie animationData={aidAnimationLottie} style={{ width: 400 }} /> */}
      <PurpleSmoke className={"absolute right-0 top-0 z-0"} />
    </div>
  );
};

export default HeroAID;
