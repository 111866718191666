import { useNavigate } from "react-router-dom";
import { RightArrowOutlined } from "../../../OldDesign/Icons";
import Button from "../../Button/Button";
import { CommunityWorld } from "../../Illustrations";
import { useStore } from "../../../../store/useStore";
import CommunityPrefences from "../../CommunityPrefences";
import { WorldIllustration } from "../../Illustrations";

const HeroCommunityCandidate = ({ joined }) => {
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  const userName = useStore((state) => state.user.data.userName);
  const current_step = useStore(
    (state) => state.coreCV.data.identity_progress.current_step,
  );
  const navigate = useNavigate();
  const continueStep =
    current_step < 2
      ? "step1"
      : current_step < 7
      ? "step2"
      : current_step < 8
      ? "step3"
      : current_step < 9
      ? "step4"
      : "confirmation";
  return (
    <div className="max-lg:pb-2 max-lg:pt-[53px]">
      {joined ? (
        <>
          <div className="container2 relative my-3 flex items-center gap-12 rounded-xl bg-[linear-gradient(261deg,#316BCD_24.64%,#0D568A_99.34%)] px-16 py-9 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:px-4 max-lg:py-6 lg:h-[650px]">
            <div>
              <p className="pb-6 text-[22px] font-semibold leading-tight text-primary-50 max-lg:pb-3 max-lg:text-[14px]">
                Welcome {userName}
              </p>
              <p className="pb-8 text-[52px] font-light leading-tight max-lg:pb-3 max-lg:text-[22px]">
                Humanas Community
                <br />
                <span className="font-extrabold text-primary-100">
                  Ayrıcalıklarından
                </span>
                <br />
                Yararlan
              </p>
              <p className="text-[13px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                posuere cursus sem, id mollis velit tristique ac. Nunc vel magna
                mi. Quisque feugiat augue justo, at iaculis magna pellentesque
                nec.
              </p>
            </div>
            <div className="right-2 top-2 max-lg:absolute">
              <WorldIllustration className="max-lg:w-[120px] lg:w-[500px]" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container2 flex items-center gap-14 rounded-xl bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-9 py-8 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:p-4">
            <div className="flex basis-2/3 flex-col justify-between">
              <div className="flex flex-col gap-6 pb-6 max-lg:gap-2 max-lg:pb-4">
                <h1 className="text-[32px] font-medium leading-tight max-lg:text-[18px]">
                  {content["topluluga katil baslik"]}
                </h1>
                <p className="text-[22px] font-medium leading-tight max-lg:text-[13px] lg:text-justify">
                  {content["topluluga katil aciklama"]}
                </p>
              </div>
              {/* <div>
                <Button
                  className="border-neutral-100 bg-neutral-100 text-primary-900 max-lg:px-4 max-lg:text-[15px]"
                  icon2={
                    <RightArrowOutlined className="h-5 w-5 max-lg:h-4 max-lg:w-4" />
                  }
                  onClick={() => navigate(`/apply/${continueStep}`)}
                >
                  {content["topluluga katil buton"]}
                </Button>
              </div> */}
            </div>
            <div className="basis-1/3 max-lg:hidden">
              <CommunityWorld />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CommunityHeaderWithPreferenceBoxes = () => (
  <>
    <div className="flex basis-2/3 flex-col items-start justify-between gap-3">
      <h1 className="text-[32px] font-bold leading-tight max-lg:text-[18px]">
        Good Morning Emily
      </h1>
      <p className="text-[22px] leading-tight max-lg:text-[14px] lg:text-justify">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet
        velit quis ex congue commodo.
      </p>
      <div className="max-w-[820px] max-lg:hidden">
        <CommunityPrefences />
      </div>
    </div>
    <div className="basis-1/3 max-lg:hidden">
      <CommunityWorld />
    </div>
  </>
);

export default HeroCommunityCandidate;
