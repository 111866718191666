import React, { useEffect } from "react";
import { SettingsOutlined } from "../components/OldDesign/Icons";
import { NavLink, Outlet } from "react-router-dom";
import { useStore } from "../store/useStore";
import Loading from "../components/OldDesign/Loading";

const settingsMenuItems = [
  { title: "User Settings", path: "user", isActive: true },
  { title: "Company Settings", path: "company", isActive: false },
];

const SettingsMenuListItem = ({ item }) => {
  return (
    <NavLink
      to={item.path}
      className={({ isActive, isPending }) => {
        return `${
          isActive
            ? `pl-3 font-semibold text-primary-600 before:content-["|"]`
            : `pl-1 font-normal text-neutral-700 before:content-[""]`
        } ${
          !item.isActive && "pointer-events-none opacity-70"
        } relative py-2.5 text-[17px] leading-tight transition-all before:absolute before:left-0 `;
      }}
    >
      {item.title}
      {!item.isActive && (
        <span className="ml-2 rounded-md bg-primary-100 px-1 text-[9px] text-primary-700">
          Coming Soon
        </span>
      )}
    </NavLink>
  );
};

const Settings = () => {
  const getSettings = useStore((state) => state.getSettings);
  const settings = useStore((state) => state.settings);
  const user = useStore((state) => state.user);
  // console.log("settings", settings);
  useEffect(() => {
    if (user.data.userType === "recruiter") getSettings();
  }, [getSettings]);
  return (
    <div className="bg-neutral-200">
      <div className="container2 grid grid-cols-4 gap-9 py-9 max-lg:grid-cols-1 max-lg:gap-3 max-lg:pt-[53px]">
        <div className="col-span-1 rounded-lg bg-neutral-100 p-6 max-lg:p-3">
          <div className="flex items-center gap-1 pb-6 max-lg:pb-3">
            <SettingsOutlined className="h-6 w-6" />
            <h5 className="text-[32px] font-bold leading-tight max-lg:text-[20px]">
              Settings
            </h5>
          </div>
          <ul className="flex flex-col divide-y-2">
            {settingsMenuItems.map((item, index) => (
              <SettingsMenuListItem key={index} item={item} />
            ))}
          </ul>
        </div>
        <div className="col-span-3 max-lg:col-span-1">
          {user.data.userType === "recruiter" ? (
            settings.isLoading || !settings.isLoaded ? (
              <Loading />
            ) : (
              <Outlet />
            )
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
