import React, { useState } from "react";
import { Cell, Pie, PieChart } from "recharts";

import { useStore } from "../../../store/useStore";
import {
  ORIENTATION_STAT_COLORS,
  WORK_PREF_STAT_COLORS,
} from "../../../components/NewDesign/constants";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import Button from "../../../components/NewDesign/Button/Button";

import {
  BadgeFilled,
  BagIconFilled,
  ChevronDownOutlined,
  EmployeeFilled,
  EyeOnIcon,
  MetaCvOnlyLogo,
  StarFilled,
} from "../../../components/OldDesign/Icons";
import {
  Top2LevelFlag,
  Top3LevelFlag,
  Top1LevelFlag,
  MetaCvEmpty,
} from "../../../components/NewDesign/Illustrations";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AidSummaryPage = () => {
  const navigate = useNavigate();
  const aid = useStore((state) => state.aid.data);
  const [topThreeState, setTopThreeState] = useState("all");
  const [extraordinaryState, setExtraordinaryState] = useState("all");
  const [fullybalancedState, setFullybalancedState] = useState("all");
  const [orientationStatState, setOrientationStatState] = useState("all");
  const [workPrefStatState, setWorkPrefStatState] = useState("all");
  const userTypeStates = [
    { value: "all", placeholder: "Tümü" },
    { value: "candidate", placeholder: "Aday" },
    { value: "employee", placeholder: "Çalışan" },
  ];
  //   console.log("topThreeState", topThreeState);
  //   console.log("top3", aid.aid_top3[topThreeState]);

  const colors = ORIENTATION_STAT_COLORS;

  return (
    <>
      <div className="row-auto grid max-lg:grid-cols-1 max-lg:gap-4 lg:grid-cols-2 lg:gap-6">
        {/* ID DISTRIBUTION */}
        <div className="col-span-2 h-[432px] rounded-xl bg-neutral-100 max-lg:col-span-1 max-lg:h-[454px]">
          {/* header */}
          <div className="flex items-center justify-between p-6 max-lg:flex-col max-lg:items-start max-lg:gap-2 max-lg:p-4">
            <h5 className="text-[22px] font-semibold leading-tight text-primary-800 max-lg:text-[20px]">
              Kimlik Dağılımı
            </h5>
            <div className="flex gap-2">
              <TagWithIcon
                icon={<EmployeeFilled className={"h-4 w-4"} />}
                text={"Aday Sayısı"}
                className={
                  "bg-secondary_recruiter-100 text-secondary_recruiter-700"
                }
              />
              <TagWithIcon
                icon={<BagIconFilled className={"h-4 w-4"} />}
                text={"Çalışan Sayısı"}
                className={"bg-success-100 text-success-400"}
              />
            </div>
          </div>
          {/* ids */}
          <div className="flex h-[356px] flex-row flex-wrap items-center justify-center gap-4 overflow-y-auto pb-6 pl-6 pr-4 max-lg:gap-3 max-lg:px-4 max-lg:pb-0">
            {aid.aid_distribution.map((stat, index) => (
              <DistributionStatCard stat={stat} key={index} />
            ))}
          </div>
        </div>
        {/* EXTRAORDINARY PROFILES */}
        <div className="h-[394px] rounded-xl bg-neutral-100">
          {/* header */}
          <div className="flex items-center justify-between p-6 max-lg:p-4">
            <h5 className="text-[22px] font-semibold leading-tight text-primary-800 max-lg:text-[20px]">
              Sıradışı Profiller
            </h5>
            <UserTypeSelectButton
              id="extraordinary"
              userTypes={userTypeStates}
              onChange={(e) => setExtraordinaryState(e.target.value)}
            />
          </div>
          {/* profiles */}
          <div className="grid h-[310px] grid-cols-1 items-start gap-2 overflow-y-auto pb-6 pl-6 pr-4 max-lg:h-[325px] max-lg:px-4 max-lg:pb-0">
            {aid.extraordinary_profiles[extraordinaryState].length === 0 ? ( //IF LIST EMPTY
              <div className="flex flex-col items-center gap-1">
                <MetaCvEmpty />
                <p className="text-center text-[18px] leading-tight text-primary-800 max-lg:text-[13px]">
                  Bağlantılarınız arasında sıradışı profil bulunmamaktadır.
                  <br />
                  Daha fazla kişiyi davet ederek ağınızı genişletebilirsiniz.
                </p>
                <Button
                  onClick={() => navigate("/mynetwork")}
                  className="bg-neutral-100 px-4 py-2 text-primary-600"
                >
                  Devam Et
                </Button>
              </div>
            ) : (
              aid.extraordinary_profiles[extraordinaryState].map(
                (profile, index) => (
                  <ProfileRow
                    profile={profile}
                    key={index}
                    countType={{ id: "extraordinary_count", name: "Sıradışı" }}
                  />
                ),
              )
            )}
          </div>
        </div>
        {/* BALANCED PROFILES */}
        <div className="h-[394px] rounded-xl bg-neutral-100">
          {/* header */}
          <div className="flex items-center justify-between p-6 max-lg:p-4">
            <h5 className="text-[22px] font-semibold leading-tight text-primary-800 max-lg:text-[20px]">
              Dengeli Profiller
            </h5>
            <UserTypeSelectButton
              id="fullybalanced"
              userTypes={userTypeStates}
              onChange={(e) => setFullybalancedState(e.target.value)}
            />
          </div>
          {/* profiles */}
          <div className="grid h-[310px] grid-cols-1 items-start gap-2 overflow-y-auto pb-6 pl-6 pr-4 max-lg:h-[325px] max-lg:px-4 max-lg:pb-0">
            {aid.fullybalanced_profiles[fullybalancedState].length === 0 ? ( //IF LIST EMPTY
              <div className="flex flex-col items-center gap-1">
                <MetaCvEmpty />
                <p className="text-center text-[18px] leading-tight text-primary-800 max-lg:text-[13px]">
                  Bağlantılarınız arasında sıradışı profil bulunmamaktadır.
                  <br />
                  Daha fazla kişiyi davet ederek ağınızı genişletebilirsiniz.
                </p>
                <Button
                  onClick={() => navigate("/mynetwork")}
                  className="bg-neutral-100 px-4 py-2 text-primary-600"
                >
                  Devam Et
                </Button>
              </div>
            ) : (
              aid.fullybalanced_profiles[fullybalancedState].map(
                (profile, index) => (
                  <ProfileRow
                    profile={profile}
                    key={index}
                    countType={{ id: "fullybalanced_count", name: "Dengeli" }}
                  />
                ),
              )
            )}
          </div>
        </div>
      </div>
      {/* STATS */}
      <div className="grid grid-cols-12 grid-rows-1 gap-6 py-6 max-lg:grid-cols-1">
        {/* orientation */}
        <div className="h-[402px] rounded-xl bg-neutral-100 p-4 max-lg:h-[360px] lg:col-span-4">
          {/* header */}
          <div className="flex items-center justify-between pb-4">
            <h5 className="whitespace-nowrap text-[18px] font-semibold leading-tight text-primary-800">
              Yönelim Düzeyleri
            </h5>
            <UserTypeSelectButton
              id="orientation-distribution"
              userTypes={userTypeStates}
              onChange={(e) => setOrientationStatState(e.target.value)}
            />
          </div>
          {/* chart */}
          <div className="flex flex-col items-center justify-center gap-3 rounded-xl border p-3">
            <h3 className="text-[16px] font-semibold text-primary-800">
              Düzey Dağılımı
            </h3>
            <OrientationSummaryChart
              stats={aid.orientation_level_distribution[orientationStatState]}
            />
            <div className="flex w-2/3 flex-wrap items-center justify-center gap-3 max-lg:w-full max-lg:gap-1">
              {aid.orientation_level_distribution[orientationStatState].map(
                (stat, index) => (
                  <p
                    key={index}
                    className="flex items-center pr-1 text-[15px] font-semibold leading-tight text-primary-700"
                  >
                    <span
                      className={`pr-2 text-[10px]`}
                      style={{ color: colors[stat.id] }}
                    >
                      {"\u2B24"}
                    </span>
                    {stat.title}
                  </p>
                ),
              )}
            </div>
          </div>
        </div>
        {/* work pref */}
        <div className="h-[402px] rounded-xl bg-neutral-100 p-4 max-lg:h-auto lg:col-span-5">
          {/* header */}
          <div className="flex items-center justify-between pb-4">
            <h5 className="whitespace-nowrap text-[18px] font-semibold leading-tight text-primary-800">
              Çalışma Tercihleri
            </h5>
            <UserTypeSelectButton
              id="orientation-distribution"
              userTypes={userTypeStates}
              onChange={(e) => setWorkPrefStatState(e.target.value)}
            />
          </div>
          {/* charts */}
          <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
            {/* working time pref stat chart */}
            <div className="flex flex-col items-center gap-3 rounded-xl border p-3">
              <h3 className="text-[16px] font-semibold text-primary-800">
                Çalışma Zamanı
              </h3>
              <WorkTimeSummaryChart
                stats={
                  aid.working_preferences_time_distribution[workPrefStatState]
                }
              />
              <div className="flex flex-wrap items-center justify-center gap-3 max-lg:gap-1">
                {aid.working_preferences_time_distribution[
                  workPrefStatState
                ].map((stat, index) => (
                  <p
                    key={index}
                    className="flex items-center pr-1 text-[15px] font-semibold leading-tight text-primary-700"
                  >
                    <span
                      className={`pr-2 text-[10px]`}
                      style={{ color: WORK_PREF_STAT_COLORS[stat.id] }}
                    >
                      {"\u2B24"}
                    </span>
                    {stat.title}
                  </p>
                ))}
              </div>
            </div>
            {/* working location pref stat chart */}
            <div className="flex flex-col items-center gap-3 rounded-xl border p-3">
              <h3 className="text-[16px] font-semibold text-primary-800">
                Çalışma Yeri
              </h3>
              <WorkPlaceSummaryChart
                stats={
                  aid.working_preferences_location_distribution[
                    workPrefStatState
                  ]
                }
              />
              <div className="flex flex-wrap items-center justify-center gap-3 max-lg:gap-1">
                {aid.working_preferences_location_distribution[
                  workPrefStatState
                ].map((stat, index) => (
                  <p
                    key={index}
                    className="flex items-center pr-1 text-[15px] font-semibold leading-tight text-primary-700"
                  >
                    <span
                      className={`pr-2 text-[10px]`}
                      style={{ color: WORK_PREF_STAT_COLORS[stat.id] }}
                    >
                      {"\u2B24"}
                    </span>
                    {stat.title}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* work status */}
        <div className="h-[402px] rounded-xl bg-neutral-100 p-4 max-lg:h-auto lg:col-span-3">
          {/* header */}
          <div className="flex items-center justify-between pb-4">
            <h5 className="text-[18px] font-semibold leading-tight text-primary-800">
              Çalışma Durumu
            </h5>
          </div>
          {/* stat cards */}
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4 rounded-xl border bg-neutral-100 p-4 max-lg:flex-row max-lg:items-center">
              <div className="flex items-center gap-3 ">
                <div className={`rounded-full bg-card_candidate-100 p-2`}>
                  <EmployeeFilled
                    className={"h-7 w-7 text-card_candidate-400"}
                  />
                </div>
                <p
                  title="Çalışan Aday Sayısı"
                  className="overflow-hidden whitespace-nowrap text-[16px] font-semibold text-primary-900 "
                >
                  Çalışan Aday Sayısı
                </p>
              </div>
              <p className="whitespace-nowrap text-[32px] font-semibold text-primary-900 max-lg:ml-auto max-lg:text-[18px]">
                {aid.working_state_distribution.working}
              </p>
            </div>
            <div className="flex flex-col gap-4 rounded-xl border bg-neutral-100 p-4 max-lg:flex-row max-lg:items-center">
              <div className="flex items-center gap-3 ">
                <div className={`rounded-full bg-secondary_applicant-100 p-2`}>
                  <EmployeeFilled className={"h-7 w-7 text-success-400"} />
                </div>
                <p
                  title="Çalışmayan Aday Sayısı"
                  className="overflow-hidden whitespace-nowrap text-[16px] font-semibold text-primary-900 "
                >
                  Çalışmayan Aday Sayısı
                </p>
              </div>
              <p className="whitespace-nowrap text-[32px] font-semibold text-primary-900 max-lg:ml-auto max-lg:text-[18px]">
                {aid.working_state_distribution.notworking}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OrientationSummaryChart = ({ stats }) => {
  // console.log("oristat", stats);
  const RADIAN = Math.PI / 180;
  const colors = ORIENTATION_STAT_COLORS;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={15} fill="white" opacity="0.5" />
            <text
              x={x}
              y={y}
              fill="#0D568A"
              fontWeight={600}
              textAnchor={"middle"}
              dominantBaseline="central"
              fontSize={13}
            >
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          </>
        )}
      </>
    );
  };
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={stats}
        dataKey={"value"}
        nameKey={"title"}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={customLabel}
        outerRadius={90}
        innerRadius={50}
      >
        {stats.map((entry, index) => (
          <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
        ))}
      </Pie>
    </PieChart>
  );
};

const WorkTimeSummaryChart = ({ stats }) => {
  const RADIAN = Math.PI / 180;
  const colors = WORK_PREF_STAT_COLORS;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={15} fill="white" opacity="0.5" />
            <text
              x={x}
              y={y}
              fill="#0D568A"
              fontWeight={600}
              textAnchor={"middle"}
              dominantBaseline="central"
              fontSize={13}
            >
              {`${(percent * 100).toFixed(0)}`}%
            </text>
          </>
        )}
      </>
    );
  };
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={stats}
        dataKey={"value"}
        nameKey={"title"}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={customLabel}
        outerRadius={90}
        innerRadius={50}
      >
        {stats.map((entry, index) => (
          <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
        ))}
      </Pie>
    </PieChart>
  );
};

const WorkPlaceSummaryChart = ({ stats }) => {
  const RADIAN = Math.PI / 180;
  const colors = WORK_PREF_STAT_COLORS;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={15} fill="white" opacity="0.5" />
            <text
              x={x}
              y={y}
              fill="#0D568A"
              fontWeight={600}
              textAnchor={"middle"}
              dominantBaseline="central"
              fontSize={13}
            >
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          </>
        )}
      </>
    );
  };
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={stats}
        dataKey={"value"}
        nameKey={"title"}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={customLabel}
        outerRadius={90}
        innerRadius={50}
      >
        {stats.map((entry, index) => (
          <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
        ))}
      </Pie>
    </PieChart>
  );
};

const ProfileRow = ({ profile, countType }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleModalOpen = () => {
    navigate(`/view/${profile.user_id}`, {
      state: { previousLocation: location },
    });
  };
  return (
    <div className="flex items-center gap-3 rounded-xl border bg-neutral-100 px-4 py-3 max-lg:gap-2 max-lg:p-3">
      <div className="relative flex h-[65px] w-[65px] shrink-0 grow-0 items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[35px] max-lg:w-[35px] ">
        <img
          src={profile.profile_picture_url}
          alt={profile.name}
          className="h-[57px] w-[57px] rounded-full max-lg:h-[32px] max-lg:w-[32px]"
        />
        <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[9px] font-semibold text-neutral-100 max-lg:px-1 max-lg:text-[7px]">
          LVL 2
        </span>
      </div>
      <div className="flex flex-1 flex-col items-start gap-1 overflow-hidden max-lg:w-[209px]">
        <div className="flex gap-2 max-lg:gap-1">
          <p className="whitespace-nowrap font-medium leading-tight text-neutral-1000">
            {profile.name}
          </p>
          {/* <div
            title="Toplam Rozet"
            className="flex items-center gap-1.5 rounded-full bg-card_candidate-light-gray px-2 py-1 max-lg:gap-0.5 max-lg:px-1 max-lg:py-0.5"
          >
            <BadgeFilled className={"h-3 w-3 text-neutral-600"} />
            <span className="text-[13px] font-medium leading-none text-neutral-600 max-lg:text-[11px]">
              {profile.badge_count}
            </span>
          </div> */}
          {/* <div
            title="Toplam Yıldız"
            className="flex items-center gap-1.5 rounded-full bg-card_candidate-light-yellow px-2 py-1 max-lg:gap-0.5 max-lg:px-1 max-lg:py-0.5"
          >
            <StarFilled className={"h-3 w-3 text-chart-yellow"} />
            <span className="text-[13px] font-medium leading-none text-chart-yellow max-lg:text-[11px]">
              {profile.total_badge}
            </span>
          </div> */}
        </div>
        <p className="line-clamp-2 text-[13px] leading-tight text-primary-800">
          {profile.job} &#x2022; {profile.state},{profile.country}
        </p>
        <p className="text-[13px] font-bold leading-tight text-secondary_recruiter-600">
          {profile[countType.id]} {countType.name} Yönelim
        </p>
      </div>
      <Button
        //TODO mobilde modal bozuk
        className={
          "bg-transparent px-4 py-2 text-primary-600 max-lg:hidden max-lg:p-2"
        }
        icon={<EyeOnIcon className={"h-4 w-4 max-lg:h-3 max-lg:w-3"} />}
        onClick={handleModalOpen}
      >
        <span className="max-lg:hidden">İncele</span>
      </Button>
    </div>
  );
};

const TopProfileCards = ({ profile, rank }) => {
  const firstProfile = rank === 1;
  const navigate = useNavigate();
  const location = useLocation();

  const handleModalOpen = () => {
    navigate(`/view/${profile.user_id}`, {
      state: { previousLocation: location },
    });
  };
  return (
    <div
      onClick={handleModalOpen}
      className="flex h-full cursor-pointer flex-col items-center justify-center gap-2"
    >
      <div className=" relative flex h-[90px] w-[90px] shrink-0 grow-0 items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[65px] max-lg:w-[65px] ">
        <img
          src={profile.profile_picture_url}
          alt="profile"
          className="h-[80px] w-[80px]  rounded-full max-lg:h-[59px] max-lg:w-[59px]"
        />
        <span
          className={`${
            firstProfile ? "" : ""
          } absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[12px] font-semibold text-neutral-100 max-lg:text-[8px]`}
        >
          LVL {profile.metacv_level}
        </span>
      </div>
      <div className="flex flex-col items-center gap-1 max-lg:gap-0.5">
        <p
          className={`${
            firstProfile
              ? "w-[177px] text-[20px] max-lg:w-[94px]"
              : "w-[148px] text-[15px] max-lg:w-[84px]"
          } line-clamp-1  overflow-ellipsis whitespace-nowrap text-center font-semibold leading-tight text-primary-900 max-lg:text-[9px]`}
        >
          {profile.name}
        </p>
        <p
          className={`${
            firstProfile
              ? "w-[177px] text-[15px] max-lg:w-[94px]"
              : "w-[148px] text-[13px] max-lg:w-[84px]"
          } line-clamp-1  overflow-ellipsis whitespace-nowrap text-center leading-tight text-primary-900 max-lg:text-[9px]`}
        >
          {profile.job}
        </p>
        <p
          className={`${
            firstProfile
              ? "w-[177px] text-[15px] max-lg:w-[94px]"
              : "w-[148px] text-[13px] max-lg:w-[84px]"
          } line-clamp-1  overflow-ellipsis whitespace-nowrap text-center leading-tight text-primary-900 max-lg:text-[9px]`}
        >
          {profile.state} {profile.country}
        </p>
      </div>
      <div className=" flex gap-1 overflow-x-auto">
        {profile.badges.slice(0, 3).map((badge, index) => (
          <img
            key={index}
            src={process.env.REACT_APP_STRAPI_BASE_URL + badge.media}
            alt={badge.name}
            className="h-9 w-9 max-lg:h-6 max-lg:w-6"
          />
        ))}
      </div>
    </div>
  );
};

const DistributionStatCard = ({ stat }) => {
  return (
    <Link
      className="max-lg:w-[152px] lg:w-[190px]"
      to={`/aid/identities/${stat.id}`}
    >
      <div className="flex flex-col items-center gap-1 rounded-xl border px-6 py-4 max-lg:gap-1 max-lg:p-2">
        <img
          src={
            process.env.REACT_APP_STRAPI_BASE_URL + stat.media.logo.media_url
          }
          alt={stat.title}
          className="h-[110px] w-[110px] max-lg:h-[90px] max-lg:w-[90px]"
        />
        <p className="text-center text-[17px] font-semibold leading-tight text-primary-800 max-lg:text-[15px]">
          {stat.name}
        </p>
        <p className="text-[15px] font-medium leading-tight text-primary-800 max-lg:text-[14px]">
          {stat.title}
        </p>
        <div className="flex gap-2">
          <TagWithIcon
            icon={<EmployeeFilled className={"h-4 w-4"} />}
            text={stat.candidate}
            className={
              "bg-secondary_recruiter-100 text-secondary_recruiter-700"
            }
          />
          <TagWithIcon
            icon={<BagIconFilled className={"h-4 w-4"} />}
            text={stat.employee}
            className={"bg-success-100 text-success-400"}
          />
        </div>
      </div>
    </Link>
  );
};

const UserTypeSelectButton = ({ onChange, userTypes, id }) => {
  return (
    <label htmlFor={id} className="relative flex items-center">
      <select
        id={id}
        name={id}
        className="appearance-none rounded-full border-none bg-secondary_recruiter-200 py-2 pl-4 pr-7 text-[13px] font-medium leading-none text-secondary_recruiter-600 outline-none"
        onChange={(e) => onChange(e)}
      >
        {userTypes.map((state, index) => (
          <option key={index} value={state.value}>
            {state.placeholder}
          </option>
        ))}
      </select>
      <ChevronDownOutlined
        className={
          "absolute right-2 h-[14px] w-[14px] text-secondary_recruiter-600"
        }
      />
    </label>
  );
};

export default AidSummaryPage;
