import React, { useEffect, useState } from "react";
import { ConnectionsWorld } from "../../../components/NewDesign/Illustrations";
import { useStore } from "../../../store/useStore";
import UserCard from "../../../components/NewDesign/UserCard";
import Loading from "../../../components/OldDesign/Loading";
import Pagination from "../../../components/OldDesign/Pagination";

const Pool = () => {
  const content = useStore(
    (state) =>
      state.content.data["My Network"].content.translations[
        "Recruiter My Network Tables"
      ],
  );
  const getNetworkList = useStore((state) => state.getNetworkList);
  const clearNetworkList = useStore((state) => state.clearNetworkList);
  const networkList = useStore((state) => state.networkList);
  const listType = "pool";

  // PAGINATION
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const options = {
      type: listType,
      page: currentPage,
      rows: pageSize,
      keyword: null,
    };
    getNetworkList(options);
    return () => clearNetworkList();
  }, [getNetworkList, clearNetworkList, currentPage, pageSize]);
  return (
    <>
      <p className="pb-2 text-[14px]">{content["pool aciklama"]}</p>
      <div>
        {networkList.isLoading ? (
          <Loading />
        ) : !networkList.memberList.length ? (
          <div className="py-12">
            <ConnectionsWorld className="mx-auto w-1/2 pb-9" />
            <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
              <p className="pb-2 text-[14px]">
                {content["bos tablo aciklama"]}
              </p>
            </p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-1">
              {networkList.memberList.map((item, index) => {
                return <UserCard key={index} user={item} listType={listType} />;
              })}
            </div>
            <div className="py-2">
              <Pagination
                dataLength={networkList.totalRows}
                setPageSize={setPageSize}
                currentPage={currentPage}
                totalCount={networkList.totalRows}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Pool;
