import React, { useState } from "react";
import Button from "../components/NewDesign/Button/Button";
import { MetaCvLogoText } from "../components/OldDesign/Icons";

const Pricing = () => {
  return (
    <div
      className={`relative h-screen w-full overflow-hidden bg-neutral-200 bg-[url("/src/assets/sideDesign.png"),_url("/src/assets/sideDesignreverse.png")] bg-[position:top_right,bottom_left] bg-no-repeat font-inter text-[14px] lg:flex lg:min-h-[680px] lg:items-center lg:text-[17px]`}
    >
      <div className="z-10 lg:mx-auto lg:w-full lg:max-w-[1184px]">
        <div className="flex h-[33vh] bg-[linear-gradient(258deg,#0D568A_0%,#2878BE_36.98%,#0DC9B1_100%)] lg:h-[335px]">
          <Slider />
        </div>
      </div>
    </div>
  );
};

const Slider = () => {
  const metaCvValues = [
    0, 18, 15, 12, 10, 9, 8.666666667, 8.571428571, 8.125, 7.777777778, 7.5,
    7.454545455, 7.333333333, 7.230769231, 7.142857143, 7, 6.875, 6.764705882,
    6.666666667, 6.578947368, 6.5, 6.428571429, 6.363636364, 6.304347826, 6.25,
    6.2, 6.153846154, 6.111111111, 6.071428571, 6.034482759, 6, 5.967741935,
    5.9375, 5.909090909, 5.882352941, 5.857142857, 5.833333333, 5.810810811,
    5.789473684, 5.769230769, 5.75, 5.731707317, 5.714285714, 5.697674419,
    5.681818182, 5.666666667, 5.652173913, 5.638297872, 5.625, 5.612244898, 5.6,
  ];
  const [val, setVal] = useState(1);
  const price = Math.round(val * metaCvValues[val] * 10);
  // console.log(val);
  return (
    <div className="mx-auto flex items-center ">
      <div className="relative mx-auto flex flex-col items-center justify-center gap-12 rounded-xl border bg-neutral-100 p-9">
        <div className="flex items-center gap-1 text-secondary_recruiter-600">
          <p className="whitespace-nowrap text-xl">Kaç</p>
          <MetaCvLogoText className="mb-2 w-[140px]" />
          <p className="whitespace-nowrap text-xl">'ye ihtiyacınız var?</p>
        </div>
        <div className="relative w-[400px]">
          <label
            htmlFor="pricing-range"
            className="relative inline-block w-20 rounded-full bg-secondary_recruiter-600 px-4  py-2 text-center text-2xl font-bold text-neutral-100 before:absolute before:-bottom-4 before:left-8 before:block before:h-0 before:w-0 before:border-8 before:border-neutral-100 before:border-t-secondary_recruiter-600"
            style={{
              position: "absolute",
              left: `${(val / 55) * 400 - 25}px`,
            }}
          >
            {val > 50 ? `510+` : 10 + val * 10}
          </label>
          <input
            type="range"
            value={val}
            onChange={(e) => setVal(e.target.value)}
            min={0}
            max={51}
            step={1}
            style={{
              background: `linear-gradient(to right, #6070CF 0%, #6070CF ${
                ((val - 0) / (51 - 0)) * 100
              }%, #EFEFEF ${((val - 0) / (51 - 0)) * 100}%, #EFEFEF 100%)`,
            }}
            className={`range-input mt-20 [&::-webkit-slider-thumb]:border-secondary_recruiter-600`}
            id="pricing-range"
          />
        </div>
        <div className="flex w-full justify-between">
          <p className="text-xl font-semibold text-primary-600"></p>
          <p className="text-xl font-semibold text-primary-600">
            {val < 1
              ? "Ücretsiz"
              : val > 50
              ? `Enterprise`
              : `${metaCvValues[val].toFixed(2)} $/Meta CV`}
          </p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-xl font-semibold text-primary-600">
            Toplam Fiyat:
          </p>
          <p className="text-xl font-semibold text-primary-600">
            {val < 1 ? "Ücretsiz" : val > 50 ? `Enterprise` : `${price} $/yıl`}
          </p>
        </div>

        <Button className={`w-full`}>
          {val < 1
            ? `Kullanmaya Başla!`
            : val > 50
            ? `İletişime Geç!`
            : `Satın Al!`}
        </Button>
      </div>
    </div>
  );
};

export default Pricing;
