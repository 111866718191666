import React, { useEffect, useState } from "react";
import PrintableMetaCv from "../components/PrintableMetaCv/PrintableMetaCv";
import { useSearchParams } from "react-router-dom";

const DownloadMetaCv = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [metaCv, setMetaCv] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getMetaCv = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/company/view?id=${id}`,
        {
          method: "GET",
        },
      );

      const data = await res.json();
      if (data.status > 0) {
        setMetaCv(data.data);
        setIsLoaded(true);
      }
    };

    getMetaCv();
  }, [id]);

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <PrintableMetaCv user={metaCv} />
    </div>
  );
};

export default DownloadMetaCv;
