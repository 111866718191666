import React from "react";
import SectionTitle from "../../../components/NewDesign/SectionTitle";
import {
  MetaCVEnvelop,
  MetaCVMagnifier,
  PeopleAndGears,
  StarsSparkling,
} from "../../../components/NewDesign/Illustrations";
import starsSvg from "../../../assets/bg/stars.svg";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useStore } from "../../../store/useStore";

const WhoAreWe = () => {
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  const WHO_WE_ARE = [
    {
      title: content["potansiyel adaylar baslik"],
      content: content["potansiyel adaylar aciklama"],
      icon: <MetaCVEnvelop />,
    },
    {
      title: content["uzmanlar baslik"],
      content: content["uzmanlar aciklama"],
      icon: <MetaCVMagnifier />,
    },
    {
      title: content["humanas calisanlari baslik"],
      content: content["humanas calisanlari aciklama"],
      icon: <PeopleAndGears />,
    },
  ];
  return (
    <div className="relative rounded-xl bg-primary-75 p-6 max-lg:p-3">
      <SectionTitle
        title={content["kimlerden olusur baslik"]}
        className="pb-6 max-lg:items-start max-lg:pb-4 max-lg:text-[15px]"
      />
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".swiper-whoweare-pagination",
        }}
        className=""
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          1080: { slidesPerView: 3 },
        }}
      >
        {WHO_WE_ARE.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="relative flex h-[155px] items-center gap-4 rounded-xl bg-[linear-gradient(180deg,#106BAC_0%,#6FB6FF_100%)] px-4 py-6 max-lg:px-3 max-lg:py-4">
              <StarsSparkling className="absolute right-0 top-0 h-full" />
              <div className="">
                <h4 className="pb-3 text-[22px] font-semibold leading-tight text-neutral-100 max-lg:text-[18px]">
                  {item.title}
                </h4>
                <p className="text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-[14px]">
                  {item.content}
                </p>
              </div>
              <div className="relative">{item.icon}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="swiper-whoweare-pagination text-center"
        slot="pagination"
      />
    </div>
  );
};

export default WhoAreWe;
