import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import AnalyseProgress from "../../../../components/NewDesign/StatusBar/AnalyseProgress";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import urukGirl from "../../../../assets/urukGirl.png";
import { LockIcon, RightArrowOutlined, TickOutlined } from "../../../../components/OldDesign/Icons";
import { Trapezoid } from "../../../../components/NewDesign/Illustrations";
import Button from "../../../../components/NewDesign/Button/Button";
import { motion } from "framer-motion";

const AnalysisHomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [id, jobPosition] = useOutletContext();

  const navigateTournament = () => {
    navigate(`/jobposition/${id}/tournament`, {
      state: { previousLocation: location.state.previousLocation },
    });
  };
  const navigateCoreCv = () => {
    navigate(`/jobposition/${id}/corecv`, {
      state: { previousLocation: location.state.previousLocation },
    });
  };
  const navigateToJobPosResult = () => {
    navigate(`/cna/my-positions/${id}`);
  };
  const corecv_state = jobPosition.core_cv.state;
  const tournament_state = jobPosition.identity.state;

  return (
    <>
      <div className="px-20 max-lg:px-6">
        <AnalyseProgress percent={jobPosition.progress_percent_total} />
      </div>
      <div className="flex flex-col items-end gap-6 px-20 py-6 max-lg:gap-3 max-lg:px-6">
        {tournament_state === "completed" && corecv_state === "completed" && (
          <>
            <motion.img
              className="absolute bottom-0 left-4"
              src={urukGirl}
              alt="uruk"
              initial={{ opacity: 0, x: -75 }} // Initial state: hidden and slightly below
              animate={{ opacity: 1, x: 0 }} // Animate to: fully visible and in original position
              transition={{ duration: 1, ease: "easeOut" }} // Animation duration and easing
            />
            <motion.div
              className="absolute -left-4 bottom-4"
              initial={{ opacity: 0, x: -125 }}
              animate={{ opacity: 1, x: 0 }} // Animate to: fully visible and in original position
              transition={{ duration: 1.2, ease: "easeOut" }} // Animation duration and easing
            >
              <GradientBorder
                gradientStart="#106BAC"
                gradientEnd="#6FB6FF"
                className="absolute bottom-[200px] left-[200px] w-[343px] bg-neutral-1000 bg-opacity-60 p-4 backdrop-blur-sm"
              >
                <span className="font-semibold leading-tight text-neutral-100">Tebrikler...</span>
                <p className="pb-2 text-sm leading-tight text-neutral-100">
                  İdeal meta CV’ni görüntülemek için hazırsın!
                </p>
                <Button
                  onClick={() => navigateToJobPosResult()}
                  className="border-none bg-[linear-gradient(180deg,#106BAC_0%,#6FB6FF_100%)] px-5 py-3 text-[14px] font-medium leading-tight text-neutral-100"
                >
                  Görüntüle
                </Button>
              </GradientBorder>
            </motion.div>
          </>
        )}

        <AnalysisNavigateCard
          state={corecv_state}
          title="Core CV"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fringilla lacus velit, quis semper leo t"
          navigateTo={navigateCoreCv}
        />
        <AnalysisNavigateCard
          state={tournament_state}
          title="Kimlik Turnuvası"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fringilla lacus velit, quis semper leo t"
          navigateTo={navigateTournament}
        />
      </div>
    </>
  );
};

const AnalysisNavigateCard = ({ state, title, description, navigateTo }) => {
  return (
    <GradientBorder
      gradientStart={state === "completed" ? "#18A178" : "#106BAC"}
      gradientEnd={state === "completed" ? "#36EBAA" : "#6FB6FF"}
      padding="3px"
      className={`${
        state === "completed"
          ? "bg-[linear-gradient(100deg,rgba(54,234,170,0.50)_1.98%,rgba(125,233,195,0.40)_50.9%,rgba(255,255,255,0.20)_98.4%)]"
          : "bg-[linear-gradient(100deg,rgba(255,255,255,0.30)_1.98%,rgba(255,255,255,0.10)_98.4%)]"
      } h-[150px] w-[380px] shadow-[5px_4px_12px_0px_rgba(0,0,0,0.20)] backdrop-blur max-lg:h-[186px] max-lg:w-[342px]`}
    >
      <h3 className="pt-4 text-center text-[18px] font-bold leading-tight text-neutral-100">{title}</h3>
      <p className="px-4 py-4 text-center text-[14px] leading-tight text-neutral-100">{description}</p>
      {state === "completed" ? (
        <>
          <Button
            // onClick={navigateCoreCv}
            onClick={() => navigateTo()}
            disabled
            className="absolute bottom-0 left-0 z-10 border-transparent bg-transparent py-2.5"
            icon={<TickOutlined className="h-5 w-5" />}
          >
            Tamamlandi
          </Button>
          <Trapezoid className="absolute bottom-0 left-0" />
        </>
      ) : state === "inactive" ? (
        <>
          <Button
            disabled
            className="absolute bottom-0 left-0 z-10 border-transparent bg-transparent py-2.5"
            icon={<LockIcon className="h-5 w-5" />}
          >
            Kilitli
          </Button>
          <Trapezoid className="absolute bottom-0 left-0" gradientStart="#BFC6EC" gradientEnd="#BFC6EC" />
        </>
      ) : (
        <>
          <Button
            onClick={() => navigateTo()}
            className="absolute bottom-0 left-0 z-10 border-transparent bg-transparent py-2.5"
            icon={<RightArrowOutlined className="h-5 w-5" />}
          >
            Başlayalım
          </Button>
          <Trapezoid className="absolute bottom-0 left-0" gradientStart="#106BAC" gradientEnd="#6FB6FF" />
        </>
      )}
    </GradientBorder>
  );
};

export default AnalysisHomePage;
