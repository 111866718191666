import React from "react";
import Tooltip from "./Tooltips/Tooltip";
import { InfoOutlined } from "../OldDesign/Icons";

const MatchedOrientationCard = ({ orientation, orientationContent, onlyRef, refName, targetName }) => {
  // REFERENCE LOCATION
  const referenceIndicatorLocation = orientation.reference_direction
    ? 50 + orientation.reference_value
    : 50 - orientation.reference_value;

  const refDirection = orientation.reference_direction ? "right" : "left";

  // TARGET LOCATION
  const targetIndicatorLocation = orientation.target_direction
    ? 50 + orientation.target_value
    : 50 - orientation.target_value;

  const targetDirection = orientation.target_direction ? "right" : "left";

  return (
    <div className="relative rounded-xl border border-neutral-300 bg-neutral-100 p-4 max-lg:p-2">
      <div className="flex flex-col items-center gap-1 pb-4">
        <h3 className="text-[18px] font-semibold leading-tight text-primary-900">{orientationContent.title}</h3>
        <span className="text-[13px] font-semibold leading-tight text-neutral-800">
          {orientation.compatibility_name}
        </span>
      </div>
      <div className="relative flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-2">
          <div
            className={`${
              !orientation.reference_direction || !orientation.target_direction || orientation.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientationContent.name_left}</span>
              <Tooltip content={orientationContent.ideal_working_environment_left}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
          <div
            className={`${
              orientation.reference_direction || orientation.target_direction || orientation.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientationContent.name_right}</span>
              <Tooltip content={orientationContent.ideal_working_environment_right}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
        </div>
        {/* BAR */}
        <div className="relative w-full lg:mb-6 ">
          <div className="grid h-6 grid-cols-[repeat(20,minmax(0,1fr))] gap-2 max-lg:h-4 max-lg:gap-1">
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="rounded-l-full bg-[#135B91]"></div>
              <div className="bg-[#3469AD]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Sıra Dışı
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#4771BD]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#7986E8]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Güçlü
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#8D94F8]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#82B7EA]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Dengeli
              </span>
            </div>
            <div className={` relative col-span-4 grid grid-cols-4`}>
              <div className="bg-[#7CCBE3]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#7CCBE3]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Tam Dengeli
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#82B7EA]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#8D94F8]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Dengeli
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#7986E8]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#4771BD]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Güçlü
              </span>
            </div>
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="bg-[#3469AD]"></div>
              <div className="rounded-r-full  bg-[#135B91]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                Sıra Dışı
              </span>
            </div>
          </div>

          {/* TARGET INDICATOR */}
          <div
            className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
            style={{ paddingLeft: `${targetIndicatorLocation}%` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              className="max-lg:w-5"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="#9ECBF6"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
          {/* REF INDICATOR */}
          <div
            className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
            style={{ paddingLeft: `${referenceIndicatorLocation}%` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              className="max-lg:w-5"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="#6359E9"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>

          <div className="absolute right-1/2 top-1/2 h-4 w-4 -translate-y-1/2 translate-x-1/2 rotate-45 rounded bg-neutral-100 max-lg:h-3 max-lg:w-3 max-lg:rounded-[2px]"></div>
        </div>
        <div className="grid w-full gap-6 max-lg:grid-cols-1 max-lg:gap-2 lg:grid-cols-2">
          {/* REFERENCE GROUP */}
          <div
            className={`${
              onlyRef && "col-span-2"
            } rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
          >
            <div className="flex flex-col items-start justify-between pb-3">
              <p className="inline-block max-w-[calc(100%)] items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap rounded-full bg-chart-purple2 px-3 py-1 text-[13px] font-medium leading-none  text-neutral-100">
                {refName}
              </p>
              <h5 className="font-bold text-neutral-800">
                <span className="text-primary-800">
                  {orientationContent[orientation.reference_stat_id][refDirection].title}
                </span>{" "}
                {orientation.reference_stat_id === "fully_balanced"
                  ? orientationContent.title + " Yönelimi"
                  : refDirection === "right"
                  ? orientationContent.name_right
                  : orientationContent.name_left}
              </h5>
            </div>
            <p className="text-[14px] font-medium leading-tight text-neutral-800">
              {orientationContent[orientation.reference_stat_id][refDirection].description}
            </p>
          </div>
          {/* TARGET GROUP */}
          {!onlyRef && (
            <div
              className={`rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
            >
              <div className="flex flex-col items-start justify-between pb-3">
                <p className="inline-block max-w-[calc(100%)] items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap rounded-full bg-chart-sky-blue px-3 py-1 text-[13px] font-medium leading-none text-primary-900">
                  {targetName}
                </p>
                <h5 className="font-bold text-neutral-800">
                  <span className="text-primary-800">
                    {orientationContent[orientation.target_stat_id][targetDirection].title}
                  </span>{" "}
                  {orientation.target_stat_id === "fully_balanced"
                    ? orientationContent.title + " Yönelimi"
                    : refDirection === "right"
                    ? orientationContent.name_right
                    : orientationContent.name_left}
                </h5>
              </div>
              <p className="text-[14px] font-medium leading-tight text-neutral-800">
                {orientationContent[orientation.target_stat_id][targetDirection].description}
              </p>
            </div>
          )}
        </div>
      </div>
      {/* {!onlyRef && (
          <div className="flex items-center justify-center gap-3">
            <p className="flex items-center gap-1 text-[15px] font-medium text-[#6359E9] max-lg:text-[12px]">
              <DotOutlined className="h-4 w-4 max-lg:h-2 max-lg:w-2" />
              {refName}: <span>{dimension.reference_value}</span>
            </p>
  
            <p className="flex items-center gap-1 text-[15px] font-medium text-[#9ECBF6] max-lg:text-[12px]">
              <DotOutlined className="h-4 w-4 max-lg:h-2 max-lg:w-2" />
              {targetName}: <span>{dimension.target_value}</span>
            </p>
          </div>
        )} */}
    </div>
  );
};

export default MatchedOrientationCard;
