import React from "react";
import SectionTitle from "../../../components/NewDesign/SectionTitle";
import {
  FilterFilled,
  NetworkOutlined,
  NotificationFilled,
  WorldFilled,
} from "../../../components/OldDesign/Icons";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useStore } from "../../../store/useStore";
import "swiper/css";
import "swiper/css/pagination";

const HowItWorks = () => {
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  const HOW_IT_WORKS = [
    {
      title: content["veri paylasimi baslik"],
      content: content["veri paylasimi aciklama"],
      icon: <WorldFilled className="h-5 w-5" />,
    },
    {
      title: content["akilli filtreler baslik"],
      content: content["akilli filtreler aciklama"],
      icon: <FilterFilled className="h-5 w-5 " />,
    },
    {
      title: content["etkin bildirim sistemi baslik"],
      content: content["etkin bildirim sistemi aciklama"],
      icon: <NotificationFilled className="h-5 w-5 " />,
    },
  ];
  return (
    <div className="rounded-xl bg-secondary_applicant-100 p-6 max-lg:p-3">
      <SectionTitle
        title={content["nasil calisir baslik"]}
        className="pb-6 max-lg:items-start max-lg:pb-4 max-lg:text-[15px]"
      />
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".swiper-howitworks-pagination",
        }}
        className=""
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          1080: { slidesPerView: 3 },
        }}
      >
        {HOW_IT_WORKS.map((item, index) => (
          <SwiperSlide key={index}>
            <div className=" h-[205px] rounded-xl bg-[linear-gradient(113deg,#14557B_0.78%,#00CDAC_101.06%)] px-4 py-6 max-lg:h-[155px] max-lg:px-3 max-lg:py-4">
              <div className="flex items-center gap-4 max-lg:pb-1 lg:pb-3">
                <div className="rounded-full bg-neutral-100 p-1.5 text-secondary_applicant-500">
                  {item.icon}
                </div>
                <h4 className="text-[22px] font-semibold leading-tight text-neutral-100 max-lg:text-[18px]">
                  {item.title}
                </h4>
              </div>
              <p className="text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-[14px]">
                {item.content}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="swiper-howitworks-pagination text-center"
        slot="pagination"
      />
    </div>
  );
};

export default HowItWorks;
