import React, { useRef, useState } from "react";
import AnalyseProgress from "../../../../components/NewDesign/StatusBar/AnalyseProgress";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import { TournamentCard, TournamentCardMini } from "../../../../components/NewDesign/Cards/TournamentCard";
import eugeneIllustration from "../../../../assets/eugene1.png";
import { motion, AnimatePresence } from "framer-motion";
import { Navigate, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Button from "../../../../components/NewDesign/Button/Button";
import { postJobPosition } from "../../../../api/endpoints";
import useIsMobile from "../../../../utils/useIsMobile";

const OrientationTournament = () => {
  const [id, jobPosition, setJobPosition] = useOutletContext();
  const [understood, setUnderstood] = useState(localStorage.getItem("tourUnderstood"));
  const [submitting, setSubmitting] = useState(false);
  const [payload, setPayload] = useState(jobPosition.identity);
  const isMobile = useIsMobile();
  const tournamentScene = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const questionsIndex = payload.current_index - 1;
  const questions = payload.question_array[questionsIndex];
  const progress = jobPosition.progress_percent_identity;

  // console.log("tournament", payload);

  const toggleQuestionInOrder = (questionId) => {
    const newQuestionsArray = [...payload.question_array];
    if (!payload.question_array[questionsIndex].answers.includes(questionId)) {
      newQuestionsArray[questionsIndex].answers.length < newQuestionsArray[questionsIndex].questions.length &&
        newQuestionsArray[questionsIndex].answers.push(questionId);
      if (newQuestionsArray[questionsIndex].answers.length === newQuestionsArray[questionsIndex].questions.length - 1) {
        const lastAnwser = newQuestionsArray[questionsIndex].questions.filter(
          (q) => !newQuestionsArray[questionsIndex].answers.includes(q.id),
        );
        // console.log("last", lastAnwser);
        newQuestionsArray[questionsIndex].answers.push(lastAnwser[0].id);
      }
      // console.log("id icermiyor", newQuestionsArray);
    } else if (payload.question_array[questionsIndex].answers.includes(questionId)) {
      let _index = newQuestionsArray[questionsIndex].answers.indexOf(questionId);
      //can toggle out only last item
      if (_index === newQuestionsArray[questionsIndex].answers.length - 1) {
        if (_index === newQuestionsArray[questionsIndex].questions.length - 1) {
          newQuestionsArray[questionsIndex].answers.splice(_index - 1, 2);
        } else {
          newQuestionsArray[questionsIndex].answers.splice(_index, 1);
        }
      }
      // console.log("iceriyor cikar", newQuestionsArray);
    }
    setPayload({ ...payload, question_array: newQuestionsArray });
  };

  const handleSubmitQuestions = async () => {
    const params = { id: id };
    const payloadToBeSubmitted = { identity: { ...payload } };
    postJobPosition(
      params,
      payloadToBeSubmitted,
      (res) => {
        setPayload({ ...res.data.identity });
        isMobile && tournamentScene.current.scrollTo(0, 0);
        if (res.data.identity.state === "completed") setJobPosition(res.data);
      },
      (err) => console.log,
      (bool) => setSubmitting(bool),
    );
  };

  const handleTakeBack = () => {
    toggleQuestionInOrder(
      payload.question_array[questionsIndex].answers[payload.question_array[questionsIndex].questions.length - 1],
    );
  };

  const handleCloseInfoBox = () => {
    setUnderstood(true);
    localStorage.setItem("tourUnderstood", true);
  };

  if (payload.state === "completed" || payload.question_array.length === 0) {
    return <Navigate to={`/jobposition/${id}/home`} state={{ previousLocation: location.state.previousLocation }} />;
  }

  return (
    <>
      {!understood && (
        <div className="absolute -left-0 -top-0 z-50 h-[650px] w-[1020px] bg-neutral-1000 bg-opacity-80">
          <motion.img
            src={eugeneIllustration}
            alt=""
            className="absolute bottom-0"
            initial={{ opacity: 0, y: 50 }} // Initial state: hidden and slightly below
            animate={{ opacity: 1, y: 0 }} // Animate to: fully visible and in original position
            transition={{ duration: 1, ease: "easeOut" }} // Animation duration and easing
          />
          <motion.div
            className="absolute bottom-0"
            initial={{ opacity: 0, y: 20, scale: 0 }} // Initial state: hidden and slightly below
            animate={{ opacity: 1, y: 0, scale: 1 }} // Animate to: fully visible and in original position
            transition={{ duration: 1.3, ease: "easeOut" }} // Animation duration and easing
          >
            <GradientBorder
              gradientStart="#106BAC"
              gradientEnd="#6FB6FF"
              className="absolute bottom-[200px] left-[200px] w-[343px] bg-neutral-1000 p-4"
            >
              <p className="pb-2 text-[15px] font-bold leading-tight text-primary-600">Turnuva Nedir</p>
              <p className="pb-2 text-sm leading-tight text-neutral-100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate urna vel convallis
              </p>
              <Button
                onClick={handleCloseInfoBox}
                className="border-neutral-100 bg-transparent p-2.5 text-[18px] font-bold leading-tight text-neutral-100"
              >
                Anladım
              </Button>
            </GradientBorder>
          </motion.div>
        </div>
      )}
      <div className="px-20 pb-4 max-lg:px-4 max-lg:pb-2">
        <AnalyseProgress percent={progress} />
      </div>
      <GradientBorder className="mx-auto w-fit bg-neutral-100 bg-opacity-20 px-8 py-6 backdrop-blur max-lg:p-4 lg:max-w-[870px]">
        <p className="pb-4 text-center text-sm font-semibold leading-tight text-primary-200">
          Step {payload.current_index}/{payload.question_array.length}
        </p>
        <p className="pb-6 text-center font-semibold leading-tight text-neutral-100 max-lg:hidden">
          Aradığın özellikleri en önemlisinden başlayarak sırala!
        </p>
        <AnimatePresence>
          <div
            ref={tournamentScene}
            className="flex flex-row gap-2 max-lg:max-h-[484px] max-lg:flex-col max-lg:items-center max-lg:gap-2 max-lg:overflow-y-auto lg:flex-wrap lg:justify-center"
          >
            {questions.questions.map((question, index) => {
              const variants = ["blue", "purple", "green", "red", "orange", "grey", "lime", "pink"];
              return (
                <motion.div
                  key={question.id}
                  initial={{ rotate: 270, opacity: 0, y: 60 }}
                  animate={{ rotate: 0, opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -60 }}
                  transition={{ duration: 0.45, ease: "easeInOut" }}
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1, zIndex: 99 }}
                >
                  {questions.questions.length === 8 ? (
                    <TournamentCardMini
                      key={index}
                      onClick={() => toggleQuestionInOrder(question.id)}
                      variant={variants[index]}
                      selected={payload.question_array[questionsIndex].answers.includes(question.id)}
                      order={payload.question_array[questionsIndex].answers.indexOf(question.id)}
                      question={question.description}
                    />
                  ) : (
                    <TournamentCard
                      key={index}
                      onClick={() => toggleQuestionInOrder(question.id)}
                      variant={variants[index]}
                      selected={payload.question_array[questionsIndex].answers.includes(question.id)}
                      order={payload.question_array[questionsIndex].answers.indexOf(question.id)}
                      question={question.description}
                    />
                  )}
                </motion.div>
              );
            })}
            {isMobile &&
              payload &&
              payload.question_array[questionsIndex]?.answers.length ===
                payload.question_array[questionsIndex]?.questions.length && (
                <div className="absolute flex h-[488px] w-full items-center justify-center gap-2 backdrop-blur-sm">
                  <Button
                    loading={submitting}
                    onClick={handleTakeBack}
                    className={`border-none bg-neutral-100 text-primary-600`}
                  >
                    Geri Al
                  </Button>
                  <Button
                    loading={submitting}
                    onClick={handleSubmitQuestions}
                    className={`border-none bg-success-400 text-neutral-100`}
                  >
                    Onayla
                  </Button>
                </div>
              )}
          </div>
        </AnimatePresence>
        <div className="mt-2 flex items-center justify-center gap-4 max-lg:hidden">
          <Button
            loading={submitting}
            disabled={
              payload.question_array[questionsIndex].answers.length <
              payload.question_array[questionsIndex].questions.length
            }
            onClick={handleSubmitQuestions}
          >
            Ileri
          </Button>
        </div>
      </GradientBorder>
    </>
  );
};

export default OrientationTournament;
