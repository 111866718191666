import { forwardRef, useState } from "react";
import { PhoneInput } from "react-international-phone";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";
import VerificationInput from "react-verification-input";
import { getTrackBackground, Range } from "react-range";
import IsMobile from "../../../utils/IsMobile";
import useIsMobile from "../../../utils/useIsMobile";

export const TextArea = forwardRef((props, ref) => {
  return (
    <textarea
      ref={ref}
      {...props}
      className="border-1 focus:border-1 min-h-[48px] w-full rounded-lg border border-neutral-300 bg-neutral-100 px-4 py-4 text-[14px] hover:border-primary-600 focus:border-primary-600 lg:min-h-[54px] lg:text-[16px]"
    />
  );
});

export const Input = forwardRef((props, ref) => {
  return (
    <input
      autoFocus={false}
      ref={ref}
      className="border-1 focus:border-1 h-[48px] w-full rounded-lg border border-neutral-300 bg-neutral-100 py-4 pl-4 pr-10 text-[14px] hover:border-primary-600 focus:border-primary-600 lg:h-[54px] lg:text-[16px]"
      {...props}
    />
  );
});

export const Checkbox = forwardRef((props, ref) => {
  return (
    <div className="relative h-5 w-5">
      <input
        ref={ref}
        type="checkbox"
        className={`input-checkbox peer absolute h-0 w-0 cursor-pointer opacity-0`}
        {...props}
      />
      <span
        className={`input-checkmark absolute left-0 top-0 h-5 w-5 rounded-[4px] border border-neutral-400 bg-white peer-checked:border-[#106BAC] peer-checked:bg-[#106BAC]`}
      ></span>
    </div>
  );
});
export const Radio = forwardRef((props, ref) => {
  return (
    <div className="relative h-5 w-5">
      <input ref={ref} type="radio" className={`peer absolute h-0 w-0 cursor-pointer opacity-0`} {...props} />
      <span
        className={`absolute left-0 top-0 h-5 w-5 rounded-full border border-neutral-400 bg-white peer-checked:border-[#106BAC] peer-checked:ring-4 peer-checked:ring-inset peer-checked:ring-[#106BAC]`}
      ></span>
    </div>
  );
});
export const Phone = forwardRef((props, ref) => {
  return (
    <PhoneInput
      defaultCountry="tr"
      disableFormatting
      forceDialCode
      inputClassName="border"
      className="border-1 rounded-lg border border-neutral-300  hover:border-primary-600 focus:border-4 focus:border-primary-600"
      inputProps={{
        className: "pl-3 h-[49px] bg-neutral-100 text-[15px] lg:h-[52px] lg:text-[16px] outline-none rounded-lg",
      }}
      countrySelectorStyleProps={{
        dropdownStyleProps: {
          style: { maxHeight: "120px", top: "60px" },
        },
        className: "bg-neutral-200 rounded-l-lg",
        buttonClassName: "border-none h-full rounded-l-lg hover:rounded-l-lg px-3",
      }}
      ref={ref}
      {...props}
    />
  );
});

export const ImportanceRangeInput = forwardRef(({ values, onChange, name, min = 1, max = 5, step = 1 }, ref) => {
  const isMobile = useIsMobile();
  return (
    <Range
      values={[values]}
      ref={ref}
      name={name}
      step={step}
      min={min}
      max={max}
      onChange={(val) => onChange(val[0])}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: isMobile ? "150px" : "200px",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "8px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: [values],
                colors: ["#6359E9", "#FFFFFF20"],
                min: min,
                max: max,
                rtl: false,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "24px",
            width: "24px",
            borderRadius: "100%",
            backgroundColor: "#6359E9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            {values}
          </div>
        </div>
      )}
    />
  );
});

export const MultiRangeInput = forwardRef(({ values, onChange, name, min = 0, max = 100, step = 1 }, ref) => {
  return (
    <div className="relative flex flex-col items-center">
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        name={name}
        onChange={(values) => onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "8px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: ["#FFFFFF20", "#6359E9", "#FFFFFF20"],
                  min: min,
                  max: max,
                  rtl: false,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: "24px",
              width: "24px",
              borderRadius: "100%",
              backgroundColor: "#6359E9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {values[index]}
            </div>
          </div>
        )}
      />
      <button type="button" onClick={() => onChange([])} className="self-end text-sm font-semibold text-neutral-100">
        Sıfırla
      </button>
    </div>
  );
});

export const InputRange = forwardRef((props, ref) => {
  const { values } = props;
  // const [value, setValue] = useState(props.initialValue || 0);
  // const handleClick = (action) => {
  //   let newValue = value;
  //   if (action === "decrement") {
  //     newValue -= 1;
  //   } else if (action === "increment") {
  //     newValue += 1;
  //   }
  //   setValue(newValue);
  //   props.onChange && props.onChange(newValue);
  // };

  // const handleInputChange = (e) => {
  //   let newValue = parseInt(e.target.value, 10);
  //   if (!isNaN(newValue)) {
  //     setValue(newValue);
  //     props.onChange && props.onChange(newValue);
  //   }
  // };
  console.log(props);
  return (
    <Range
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: "215px",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "8px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: values[0],
                colors: ["#6359E9", "#FFFFFF20"],
                min: 1,
                max: 5,
                rtl: false,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props, isDragged }) => (
        <div
          {...props}
          key={props.key}
          style={{
            ...props.style,
            height: "24px",
            width: "24px",
            borderRadius: "100%",
            backgroundColor: "#6359E9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            {values[0]}
          </div>
        </div>
      )}
      ref={ref}
      {...props}
    />
  );
});
export const CrASelect = forwardRef((props, ref) => {
  return (
    <AsyncCreatableSelect
      cacheOptions={true}
      isCreatable
      ref={ref}
      isClearable
      styles={{
        multiValue: (styles, state) => ({
          ...styles,
          backgroundColor: "#CFE1EE",
          borderRadius: "12px",
          maxWidth: "200px",
          color: "#106BAC",
          ":hover": "#FFFFFF",
        }),
        multiValueLabel: (styles, state) => ({
          ...styles,
          color: "#106BAC",
        }),
        placeholder: (styles, state) => ({
          ...styles,
          color: "#9A9ABA",
        }),
        menuList: (styles, state) => ({
          ...styles,
          maxHeight: IsMobile() ? "175px" : "200px",
        }),
        control: (styles, state) => ({
          ...styles,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
          borderWidth: "1px",
          boxShadow: "none",
          backgroundColor: props.transparent ? "#FFFFFF80" : "#FFFFFF",
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          minHeight: "50px",
          maxHeight: "130px",
          paddingLeft: "16px",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          overflowY: "auto",
        }),
      }}
      {...props}
    />
  );
});
export const ASelect = forwardRef((props, ref) => {
  return (
    <AsyncSelect
      cacheOptions={true}
      ref={ref}
      isClearable
      styles={{
        multiValue: (styles, state) => ({
          ...styles,
          backgroundColor: "#CFE1EE",
          borderRadius: "12px",
          maxWidth: "200px",
          color: "#106BAC",
          ":hover": "#FFFFFF",
        }),
        multiValueLabel: (styles, state) => ({
          ...styles,
          color: "#106BAC",
        }),
        placeholder: (styles, state) => ({
          ...styles,
          color: "#9A9ABA",
        }),
        menuList: (styles, state) => ({
          ...styles,
          maxHeight: IsMobile() ? "175px" : "200px",
        }),
        control: (styles, state) => ({
          ...styles,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
          borderWidth: "1px",
          boxShadow: "none",
          backgroundColor: props.transparent ? "#FFFFFF80" : "#FFFFFF",
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          minHeight: "50px",
          maxHeight: "130px",
          paddingLeft: "16px",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          overflowY: "auto",
        }),
      }}
      {...props}
    />
  );
});
export const SSelect = forwardRef((props, ref) => {
  return (
    <Select
      ref={ref}
      styles={{
        multiValue: (styles, state) => ({
          ...styles,
          backgroundColor: "#CFE1EE",
          borderRadius: "12px",
          maxWidth: "200px",
          color: "#106BAC",
          ":hover": "#FFFFFF",
        }),
        multiValueLabel: (styles, state) => ({
          ...styles,
          color: "#106BAC",
        }),
        placeholder: (styles, state) => ({
          ...styles,
          color: "#9A9ABA",
        }),
        menuList: (styles, state) => ({
          ...styles,
          maxHeight: IsMobile() ? "175px" : "250px",
        }),
        control: (styles, state) => ({
          ...styles,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
          borderWidth: "1px",
          boxShadow: "none",
          backgroundColor: props.transparent ? "#FFFFFF80" : "#FFFFFF",
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          minHeight: "50px",
          maxHeight: "130px",
          paddingLeft: "16px",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          overflowY: "auto",
        }),
      }}
      {...props}
    />
  );
});

export const Switch = forwardRef(({ size = "medium", ...props }, ref) => {
  const sizes = {
    xsmall:
      "h-[20px] w-[32px] before:h-[20px] before:w-[32px] after:ml-[2px] after:mt-[2px] after:h-[16px] after:w-[16px] checked:after:ml-[14px] checked:after:h-4 checked:after:w-4 focus:after:h-4 focus:after:w-4",
    small:
      "h-[32px] w-[52px] before:h-[32px] before:w-[52px] after:ml-[2px] after:mt-[2px] after:h-[28px] after:w-[28px] checked:after:ml-[22px] checked:after:h-[28px] checked:after:w-[28px] focus:after:h-[28px]  focus:after:w-[28px] checked:focus:before:ml-[2px]",
    medium:
      "h-[38px] w-[62px] before:h-[38px] before:w-[62px] after:ml-[2px] after:mt-[2px] after:h-[34px] after:w-[34px] checked:after:ml-[26px] checked:after:h-[34px] checked:after:w-[34px] focus:after:h-[34px]  focus:after:w-[34px] checked:focus:before:ml-[2px]",
    large: "",
  };
  return (
    <input
      className={`relative appearance-none rounded-full bg-neutral-300 before:pointer-events-none before:absolute before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:rounded-full after:border-none after:bg-neutral-100 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-600 checked:after:absolute checked:after:z-[2]  checked:after:rounded-full checked:after:border-none checked:after:bg-neutral-100 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:after:absolute focus:after:z-[1] focus:after:block  focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary-600 checked:focus:bg-primary-600  ${sizes[size]} `}
      role="switch"
      type="checkbox"
      ref={ref}
      {...props}
    />
  );
});

export const CodeBox = forwardRef((props, ref) => {
  return (
    <VerificationInput
      ref={ref}
      placeholder=""
      validChars="0-9"
      {...props}
      classNames={{
        container: "w-[310px] h-[46px] lg:w-[400px] lg:h-[60px]",
        character: `${
          props.error ? "text-error-400 border-error-400" : "text-primary-600 border-primary-200"
        } bg-neutral-100 rounded-md outline-none text-[36px] font-bold flex items-center justify-center`,
        characterSelected: `character-selected-verification-input ${
          props.error ? "border-error-400" : "border-primary-600"
        }`,
        characterInactive: `${props.error ? "border-error-400" : "border-primary-200"}`,
      }}
    />
  );
});

export const InputNumber = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.initialValue || 0);
  const { min, max } = props;
  const handleClick = (action) => {
    let newValue = value;
    if (action === "decrement") {
      if (newValue > min) newValue -= 1;
    } else if (action === "increment") {
      if (newValue < max) newValue += 1;
    }
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const handleInputChange = (e) => {
    let newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue)) {
      setValue(newValue);
      props.onChange && props.onChange(newValue);
    }
  };
  // console.log("number", props);
  return (
    <div className="number-input my-2 flex h-[33px] w-fit items-center justify-center overflow-hidden rounded-full bg-white">
      <button
        onClick={() => handleClick("decrement")}
        className="decrement h-full bg-secondary_recruiter-450 px-4 py-2 text-white focus:outline-none max-lg:px-2"
      >
        <svg
          className="h-3 w-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 2"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
        </svg>
      </button>
      <input
        autoFocus={false}
        type="number"
        ref={ref}
        // readOnly
        value={value}
        onChange={handleInputChange}
        className="input-number h-[32px] w-[61px] bg-neutral-100 p-2 text-center text-[14px] outline-none max-lg:w-[30px] lg:h-[31px] lg:text-[16px]"
        // {...props}
      />
      <button
        onClick={() => handleClick("increment")}
        className="increment h-full bg-secondary_recruiter-450 px-4 py-2 text-white focus:outline-none max-lg:px-2"
      >
        <svg
          className="h-3 w-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
        </svg>
      </button>
    </div>
  );
});
