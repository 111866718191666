import React, { useState } from "react";
import Modal from "../../OldDesign/Modal";
import Button from "../Button/Button";

const ConfirmationPopup = ({
  showModal,
  setShowModal,
  confirmModal,
  id,
  message,
  onSuccess,
  confirmationTopic,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <div className="border-b p-4">
        <h5 className="text-xl font-medium">
          {confirmationTopic} Confirmation
        </h5>
      </div>
      <div className="p-4">
        <p className="rounded-md bg-error-200 p-2 text-sm text-error-500">
          {message}
        </p>
      </div>
      <div className="flex justify-end gap-2 border-t p-4">
        <Button
          onClick={() => setShowModal(false)}
          className="border-neutral-300 bg-neutral-300 text-primary-900"
        >
          Geri
        </Button>
        <Button
          onClick={() => {
            setIsDeleting(true);
            confirmModal(id).then(() => {
              setIsDeleting(false);
              setShowModal(false);
              onSuccess && onSuccess();
            });
          }}
          loading={isDeleting}
          className="border-error-400 bg-error-400"
        >
          {confirmationTopic}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
