import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { groupActions, reportActions } from "../../api/endpoints";
import Loading from "../OldDesign/Loading";
import MatchReport from "./MatchReport";

const GroupReport = () => {
  const { id } = useParams();
  const [compareResult, setCompareResult] = useState(null);
  const [compareLoading, setCompareLoading] = useState(false);
  const location = useLocation();
  const isSelfReport = location.state.selfReport;
  const savedReport = location.state.savedReport;
  useEffect(() => {
    if (savedReport) {
      const payload = {
        action: {
          type: "view",
          params: {
            id: id,
          },
        },
      };
      reportActions(
        payload,
        (res) => {
          setCompareResult(res.data);
        },
        (err) => console.log(err),
        (bool) => setCompareLoading(bool),
      );
    } else {
      const payload = {
        action: {
          type: "compare",
          params: {
            group_reference: id,
            group_target: id,
          },
        },
      };
      groupActions(
        payload,
        (res) => {
          setCompareResult(res.data);
        },
        (err) => console.log(err),
        (bool) => setCompareLoading(bool),
      );
    }
  }, []);

  return (
    <div className="max-w-[1356px]">
      {compareLoading ? (
        <Loading />
      ) : compareResult ? (
        <MatchReport
          isSelfReport={isSelfReport}
          report={compareResult}
          savedReport={savedReport}
        />
      ) : null}
    </div>
  );
};

export default GroupReport;
