import React from "react";
import { TickOutlined } from "../../OldDesign/Icons";
import { PaperPlane } from "../Illustrations";

const InviteFormSuccessPopup = ({ children }) => {
  return (
    <div className="relative flex flex-col items-center gap-4 p-6">
      <PaperPlane className="absolute bottom-1 right-3" />
      <div className="rounded-full bg-success-100 p-3">
        <div className="rounded-full bg-success-300 p-2">
          <TickOutlined className="h-12 w-12 text-neutral-100" />
        </div>
      </div>
      <h2 className="text-center text-[18px] font-bold leading-tight text-success-400">
        İşlem Başarılı
      </h2>
      {children}
    </div>
  );
};

export default InviteFormSuccessPopup;
