import React, { useEffect } from "react";
import Breadcrumb from "../../../components/NewDesign/Breadcrumb";
import HeroAID from "../../../components/NewDesign/Hero/AID/HeroAID";
import { AID_PAGES } from "../../../components/NewDesign/constants";
import { NavLink, Outlet } from "react-router-dom";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";

const HumanasAid = () => {
  const getAid = useStore((state) => state.getAid);
  const aid = useStore((state) => state.aid);
  useEffect(() => {
    getAid();
  }, [getAid]);
  if (aid.isLoading) {
    return <Loading />;
  }
  if (!aid.isLoaded) {
    return null;
  }
  return (
    <div className="bg-neutral-200 max-lg:mt-[53px]">
      <div className="container2">
        <Breadcrumb />
        <HeroAID />
        <nav className="scrollbar-hide flex flex-row items-center gap-3 py-6 max-lg:gap-2 max-lg:overflow-x-auto max-lg:py-2 lg:justify-center">
          {AID_PAGES.map((subpage, index) => {
            return (
              <NavLink
                key={index}
                className={({ isActive, isPending }) =>
                  `${
                    isActive
                      ? "bg-secondary_recruiter-600 font-semibold text-neutral-100 "
                      : "bg-neutral-100 font-medium text-secondary_recruiter-600"
                  } ${
                    !subpage.ready && "pointer-events-none"
                  } relative whitespace-nowrap rounded-xl px-4 py-3 transition-colors max-lg:text-[13px] `
                }
                to={subpage.path}
              >
                {subpage.name}
                {!subpage.ready && (
                  <span className="py-.5 absolute -right-2 -top-2 rounded-md bg-primary-100 px-1 text-[9px] text-primary-700 max-lg:-right-1 max-lg:-top-1">
                    Coming Soon
                  </span>
                )}
              </NavLink>
            );
          })}
        </nav>
        <Outlet />
      </div>
    </div>
  );
};

export default HumanasAid;
