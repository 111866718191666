import React from "react";
import Button from "./Button/Button";
import { RightArrowOutlined } from "../OldDesign/Icons";
import { Link, useLocation } from "react-router-dom";
import { useStore } from "../../store/useStore";

const StartDiscussion = () => {
  const content = useStore(
    (state) =>
      state.content.data["Overview"].content.translations["Discussion"],
  );
  const location = useLocation();
  return (
    <section className="container2 flex flex-row items-center justify-between gap-4 pb-6 pt-2 max-lg:items-center">
      <p className="text-[17px] leading-tight max-lg:text-[14px]">
        {content["discussion aciklama"]}
      </p>
      <Link to="/start-a-discussion" state={{ previousLocation: location }}>
        <Button
          className="text-neutral-100"
          icon2={<RightArrowOutlined className="h-5 w-5" />}
        >
          {content["discussion buton"]}
        </Button>
      </Link>
    </section>
  );
};

export default StartDiscussion;
