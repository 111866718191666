import React, { useEffect } from "react";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import ExpBar from "../../../../components/NewDesign/StatusBar/ExpBar";
import { PeopleFilled, PlusOutlined, RightArrowOutlined } from "../../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../components/OldDesign/Loading";
import Button from "../../../../components/NewDesign/Button/Button";
import { useStore } from "../../../../store/useStore";

const JobPositionRow = ({ position }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="flex flex-wrap items-center justify-between rounded-lg border border-neutral-100 bg-secondary_recruiter-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] max-lg:gap-3">
      <p className="basis-[22%] font-medium leading-tight text-primary-800 max-lg:basis-1/3">{position.name}</p>
      <div className="flex basis-[10%] items-center gap-0.5 max-lg:basis-1/3 max-lg:justify-end">
        <PeopleFilled className="h-3 w-3 text-primary-900" />
        <p className="text-[13px] font-medium leading-tight text-primary-900">{position.seat_count}</p>
      </div>
      <div className="flex basis-[15%] flex-col max-lg:basis-full">
        <span className="text-[13px] font-bold leading-tight text-chart-purple2">
          {position.progress_percent_total} %
        </span>
        <ExpBar className="h-2" percent={position.progress_percent_total} />
      </div>
      <div className="flex basis-[15%]">
        {position.state === "in_progress?" ? (
          <Button
            icon2={<RightArrowOutlined className="h-4 w-4" />}
            onClick={() =>
              navigate(`/jobposition/${position.id}/home`, {
                state: { previousLocation: location },
              })
            }
            className="w-full py-3"
          >
            Başla
          </Button>
        ) : position.state === "completed" ? (
          <Button
            onClick={() => navigate(`/cna/my-positions/${position.id}`)}
            className="w-full border-none bg-success-400 py-3"
          >
            Görüntüle
          </Button>
        ) : (
          <Button
            onClick={() =>
              navigate(`/jobposition/${position.id}/home`, {
                state: { previousLocation: location },
              })
            }
            className="w-full border-none bg-warning-400 py-3"
          >
            Devam Et
          </Button>
        )}
      </div>
    </div>
  );
};

const MyJobPositions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getJobPositions = useStore((state) => state.getJobPositions);
  const jobPositions = useStore((state) => state.jobPositions);

  useEffect(() => {
    getJobPositions();
  }, [getJobPositions]);

  return (
    <div className="h-screen w-screen overflow-hidden bg-cna-gradientblur bg-cover bg-center pt-[64px] max-lg:mb-[55px] max-lg:pt-[54px]">
      <div className="container2 h-full py-4">
        <div className="-mx-2 grid h-full grid-cols-5 grid-rows-[230px_1fr] gap-[16px_24px] px-2 max-lg:grid-cols-1">
          <GradientBorder className="col-span-1 bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] backdrop-blur-md max-lg:hidden lg:row-span-1">
            <div className="flex items-center gap-3 pb-4">
              <img src="" alt="" className="h-[77px] w-[77px] rounded-full bg-neutral-100" />
              <div className="flex flex-col gap-1">
                <h3 className="text-[18px] font-semibold leading-tight text-primary-800">Humanas</h3>
                <p className="text-sm font-medium leading-tight text-neutral-700">Izmir/Turkiye</p>
              </div>
            </div>
            <div className="pb-4">
              <div className="flex justify-between pb-1.5">
                <p className="text-sm font-medium leading-tight text-primary-700">Krediler</p>
                <span className="text-sm font-medium leading-tight text-primary-700">26/30</span>
              </div>
              <ExpBar percent={87} />
            </div>
            <div>
              <div className="flex justify-between pb-1.5">
                <p className="text-sm font-medium leading-tight text-primary-700">Tecrube Puani</p>
                <span className="text-sm font-medium leading-tight text-primary-700">170/200</span>
              </div>
              <ExpBar percent={92} />
            </div>
          </GradientBorder>

          <GradientBorder className="h-full bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] max-lg:col-span-1 max-lg:row-span-2 max-lg:p-3 lg:col-span-4 lg:row-span-3">
            <div className="flex items-center justify-between pb-4">
              <h2 className="text-[20px] font-bold leading-tight text-primary-900 max-lg:text-base">
                İş Pozisyonlarım
              </h2>
              <Button
                onClick={() => {
                  navigate(`/add-position`, {
                    state: { previousLocation: location },
                  });
                }}
                className={"max-lg:px-6 max-lg:py-2 lg:hidden"}
                icon2={<PlusOutlined className={"h-4 w-4"} />}
              >
                Yeni Ekle
              </Button>
              {/* <span className="rounded-full bg-primary-200 px-2 py-1 text-sm font-semibold leading-tight text-primary-600">
                  4/6
                </span> */}
            </div>
            <div className="mb-4 flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
              <div className="basis-[22%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Pozisyon İsmi</p>
              </div>
              <div className="basis-[10%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Kişi Sayısı</p>
              </div>
              <div className="basis-[15%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">İlerleme</p>
              </div>
              <div className="basis-[15%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
              </div>
            </div>
            <div className="-mx-2 -my-1 flex flex-col gap-3 overflow-y-auto px-3 py-1 max-lg:max-h-[calc(100%-54px)] lg:max-h-[calc(100%-64px)]">
              {jobPositions.isLoaded ? (
                jobPositions.data.rows.map((position, index) => <JobPositionRow key={index} position={position} />)
              ) : (
                <Loading />
              )}
            </div>
          </GradientBorder>

          <GradientBorder className="col-span-1 bg-primary-800 p-6 max-lg:hidden lg:row-span-2">
            <div className="flex flex-col items-center">
              <h2 className="text-[20px] font-bold leading-tight text-neutral-100">Yeni Pozisyon Ekle</h2>
              <p className="py-6 text-center font-medium leading-tight text-primary-200">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras laoreet dui eu gravida ultricies.
                Phasellus tincidunt urna maximus, rutrum neque id, eleifend ligula.
              </p>
              <Button
                onClick={() => {
                  navigate(`/add-position`, {
                    state: { previousLocation: location },
                  });
                }}
                className="border-neutral-100 bg-neutral-100 p-3 text-base text-primary-900"
                icon={<RightArrowOutlined className={"h-4 w-4"} />}
              >
                Yeni Pozisyon Ekle
              </Button>
            </div>
          </GradientBorder>
        </div>
      </div>
    </div>
  );
};

export default MyJobPositions;
