import React from "react";
import { Link } from "react-router-dom";

import { TagWithIcon } from "../../../components/NewDesign/Tags";

const AidCards = ({ aidCards }) => {
  // console.log(aidCards);
  return (
    <div className="flex flex-row flex-wrap justify-center gap-6 rounded-xl bg-neutral-100 p-9 max-lg:grid-cols-1 max-lg:p-4">
      {aidCards.data.map((aid, index) => (
        <Link key={index} to={`/aid/identities/${aid.attributes.codeName}`}>
          <AidCard aid={aid.attributes} />
        </Link>
      ))}
    </div>
  );
};

const AidCard = ({ aid }) => {
  return (
    <div
      style={{ background: aid.secondaryColor }}
      className={`flex h-[378px] w-[295px] flex-col items-center justify-start rounded-xl p-4 max-lg:h-[330px] max-lg:p-3`}
    >
      <div
        style={{
          background: `linear-gradient(180deg, ${aid.primaryColor} 0%, #FFFFFF00 100%)`,
        }}
        className={`mb-2 h-[210px] w-[210px] rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-[160px] max-lg:w-[160px]`}
      >
        <img
          src={
            process.env.REACT_APP_STRAPI_BASE_URL + aid.logo.data.attributes.url
          }
          alt={aid.name}
        />
      </div>

      <h2
        style={{ color: aid.primaryColor }}
        className={`pb-1 text-[24px] font-semibold leading-tight`}
      >
        {aid.name}
      </h2>
      <h2
        style={{ color: aid.primaryColor }}
        className={`pb-3 text-[18px] font-normal leading-tight`}
      >
        {aid.title}
      </h2>
      <div className="flex flex-wrap items-center justify-center gap-1.5">
        {aid.keyword.map((tag) => (
          <TagWithIcon
            key={tag.keyword}
            className={"bg-neutral-100 text-primary-800"}
            text={tag.keyword}
          />
        ))}
      </div>
      {/* <p className="text-[16px] font-medium leading-tight text-primary-800">
        {aid.description}
      </p> */}
    </div>
  );
};

export default AidCards;
