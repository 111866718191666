import React, { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";
import CommunityRecruiter from "../../recruiter/Community/CommunityRecruiter";
import CommunityCandidate from "./CommunityCandidate";

const Community = () => {
  const user = useStore((state) => state.user.data);
  const coreCV = useStore((state) => state.coreCV);
  const getCoreCV = useStore((state) => state.getCoreCV);
  useEffect(() => {
    getCoreCV();
  }, [getCoreCV]);
  if (!coreCV.isLoaded) {
    return <Loading />;
  }
  return (
    <>
      {user.userType === "candidate" ? (
        <CommunityCandidate />
      ) : (
        <CommunityRecruiter />
      )}
    </>
  );
};

export default Community;
