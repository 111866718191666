import React from "react";
import { twMerge } from "tailwind-merge";

const ProgressBar = ({ percent, ...props }) => {
  const rate = percent < 100 ? percent : percent >= 100 ? 100 : 12;
  const classes =
    "h-[27px] rounded-full border border-primary-100 bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] text-center text-[13px] font-semibold leading-[27px] text-primary-100 transition-all";
  const mergedClasses = twMerge(classes, props.className);
  return (
    <>
      <div className="h-[27px] w-full rounded-full bg-primary-100">
        <div className={mergedClasses} style={{ width: `${rate}%` }}>
          {rate}%
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
