import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { InfoOutlined, RightArrowOutlined } from "../OldDesign/Icons";
import { ORIENTATION_LEVEL_DESCRIPTIONS, ORIENTATION_STAT_COLORS } from "./constants";
import Tooltip from "./Tooltips/Tooltip";

const OrientationSummary = ({ summary }) => {
  const metacvDimStats = summary;
  const colors = ORIENTATION_STAT_COLORS;
  const RADIAN = Math.PI / 180;
  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={13} fill="white" opacity="0.5" />
            <text x={x} y={y} fill="#0D568A" fontWeight={600} textAnchor={"middle"} dominantBaseline="central">
              {`${(percent * 14).toFixed(0)}`}
            </text>
          </>
        )}
      </>
    );
  };

  const handleScrollToOrientation = (id) => {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center">
      <PieChart width={225} height={225}>
        <Pie
          data={metacvDimStats}
          dataKey={"value"}
          nameKey={"title"}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={customLabel}
          outerRadius={100}
          innerRadius={60}
        >
          {metacvDimStats.map((entry, index) => (
            <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
          ))}
        </Pie>
      </PieChart>
      <div className="grid w-full grid-cols-2 grid-rows-2 gap-4">
        {metacvDimStats.map((entry, index) => (
          <div key={index} className="rounded-[4px] border border-neutral-300 p-2">
            <div className="flex items-center justify-between pb-4">
              <p className="flex items-center pr-1 text-[17px] font-semibold leading-tight text-primary-700">
                <span className={`pr-2 text-[10px]`} style={{ color: colors[entry.id] }}>
                  {"\u2B24"}
                </span>
                {entry.title}
              </p>
              <Tooltip content={ORIENTATION_LEVEL_DESCRIPTIONS[entry.id]}>
                <InfoOutlined className={"h-4 w-4 text-neutral-600"} />
              </Tooltip>
            </div>
            <div className="flex items-center justify-between">
              <p className="flex items-center pr-1 text-[22px] font-bold leading-tight text-primary-800">
                {entry.value}
              </p>
              <button
                onClick={() => handleScrollToOrientation("orientationCard_" + entry.id)}
                disabled={entry.value === 0}
              >
                <RightArrowOutlined className={"h-4 w-4 text-primary-800"} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrientationSummary;
