import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import { DrawnHeader1 } from "../../../components/NewDesign/DrawnHeader";
import { useStore } from "../../../store/useStore";

import userPP from "../../../user.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import Loading from "../../../components/OldDesign/Loading";
import { Link } from "react-router-dom";
import {
  ConnectionsWorld,
  WorldIllustration,
} from "../../../components/NewDesign/Illustrations";

const AidOverviewSubPage = () => {
  const [AIDContent] = useOutletContext();
  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6 rounded-xl bg-neutral-100 p-9 max-lg:flex-col max-lg:gap-3 max-lg:p-4">
        <div className="flex flex-col gap-6 max-lg:gap-3">
          <DrawnHeader1
            brushClassName={"-left-4 translate-x-0"}
            className={"max-lg:text-[20px]"}
          >
            {AIDContent.attributes.name}'ı
            <br />
            Yakından Tanıyalım!
          </DrawnHeader1>
          <p className="leading-tight text-primary-800 max-lg:text-[14px]">
            {AIDContent.attributes.description}
          </p>
        </div>
        <div
          style={{
            background: `linear-gradient(180deg, ${AIDContent.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-[210px] w-[210px] shrink-0 grow-0 rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:mx-auto max-lg:h-[160px] max-lg:w-[160px]`}
        >
          <img
            src={
              process.env.REACT_APP_STRAPI_BASE_URL +
              AIDContent.attributes.logo.data.attributes.url
            }
            alt={AIDContent.attributes.name}
          />
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-6 rounded-xl bg-neutral-100 p-9 max-lg:p-4">
        <DrawnHeader1
          className={"text-center max-lg:text-[20px]"}
          brushClassName={""}
        >
          {AIDContent.attributes.name} Bağlantılarım
        </DrawnHeader1>

        <ConnectionsSwiper />
      </div>
    </div>
  );
};

const ConnectionsSwiper = () => {
  const [AIDContent] = useOutletContext();
  const networkList = useStore((state) => state.networkList);
  const getNetworkList = useStore((state) => state.getNetworkList);
  const clearNetworkList = useStore((state) => state.clearNetworkList);

  useEffect(() => {
    const options = {
      filters: {
        badge: [AIDContent.attributes.codeName],
      },
      type: "connections",
      page: 1,
      rows: 200,
    };
    getNetworkList(options);
    return () => {
      clearNetworkList();
    };
  }, [getNetworkList, clearNetworkList, AIDContent.attributes.codeName]);

  // console.log(networkList);

  let size = 6;
  const smallArrays = [];
  for (let i = 0; i < networkList.memberList.length; i += size) {
    smallArrays.push(networkList.memberList.slice(i, i + size));
  }

  const slides =
    networkList.memberList.length > 0 ? (
      smallArrays.map((smallArray, index) => (
        <SwiperSlide key={index}>
          <div className="mx-16 grid grid-cols-3 grid-rows-2 justify-center gap-2 max-lg:mx-0 max-lg:grid-cols-1">
            {smallArray.map((user, index) => {
              return (
                <Link
                  key={index}
                  className="flex"
                  to="/mynetwork/connections"
                  state={{
                    stateSelectedAidFilter: AIDContent.attributes.codeName,
                  }}
                >
                  <div
                    key={index}
                    className="mx-auto flex w-[369px] flex-wrap items-center justify-center gap-4 rounded-xl border border-neutral-300 bg-neutral-100 px-6 py-4 max-lg:p-3"
                  >
                    <div className="relative flex h-[77px] w-[77px] items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[55px] max-lg:w-[55px]">
                      <img
                        src={userPP}
                        alt="profile"
                        className="h-[70px] w-[70px] rounded-full max-lg:h-[48px] max-lg:w-[48px]"
                      />
                      <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100">
                        LVL {user.metaCvLevel}
                      </span>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5">
                      <p className="font-medium leading-tight text-primary-900">
                        {user.name}
                      </p>
                      <p className="text-[13px] leading-tight text-neutral-900">
                        {user.job}
                      </p>
                      <p className="text-[13px] leading-tight text-neutral-900">
                        {user.state}, {user.country}
                      </p>
                      <div className=" flex max-w-[240px] flex-row flex-wrap gap-1">
                        {user.aid.map((badge, index) => (
                          <img
                            key={index}
                            title={badge.level + ". Seviye " + badge.name}
                            src={
                              process.env.REACT_APP_STRAPI_BASE_URL +
                              badge.media
                            }
                            alt={badge.name}
                            className="h-[35px] w-[35px]"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </SwiperSlide>
      ))
    ) : (
      <>
        <ConnectionsWorld className="mx-auto w-[500px] pb-9" />
        <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
          {AIDContent.attributes.name} bağlantınız bulunmamaktadır.
        </p>
      </>
    );

  // console.log(smallArrays);
  if (networkList.isLoading) {
    return <Loading className={"h-[300px]"} />;
  }

  if (!networkList.isLoaded || networkList.memberList.length === 0) {
    return null;
  }
  return (
    <>
      {networkList.isLoaded && (
        <Swiper
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView="auto"
          navigation={true}
        >
          {slides}
        </Swiper>
      )}
    </>
  );
};

export default AidOverviewSubPage;
