import React from "react";
import { RadioCircleIcon, TickOutlined } from "../OldDesign/Icons";

const StepCard = ({ step, currentStep }) => {
  return (
    <div
      className={`${
        currentStep
          ? "bg-primary-600 text-neutral-100"
          : "bg-primary-100 text-primary-600"
      } flex-1 rounded-xl px-9 py-6 max-lg:p-3`}
    >
      <h3 className="pb-6 text-[24px] font-semibold leading-tight max-lg:pb-3 max-lg:text-[15px] ">
        {step.name}
      </h3>
      <div className="flex flex-col gap-4 max-lg:gap-3">
        {step.subSteps.map((subStep, index) => (
          <div key={index} className="flex items-center gap-3">
            {!subStep.status ? (
              <RadioCircleIcon className="h-8 w-8 max-lg:h-4 max-lg:w-4" />
            ) : (
              <TickOutlined className="h-4 w-4 max-lg:h-3 max-lg:w-3" />
            )}

            <p className="text-[18px] font-medium max-lg:text-[14px] ">
              {subStep.stepName}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepCard;
