import React, { useState } from "react";
import { Form } from "./Forms/Form";
import { Field } from "./Forms/Field";
import { Input } from "./Forms/Input";
import { CloseCircleIcon } from "../OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import Button from "./Button/Button";
import { useForm } from "react-hook-form";
import { sendSupportTicket } from "../../api/endpoints";
import { toast } from "react-toastify";
import InviteFormSuccessPopup from "./Popups/InviteFormSuccessPopup";

const SupportForm = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const sendQuestion = async (formData) => {
    // console.log(formData);
    const payload = {
      title: formData.support_title,
      description: formData.support_topic + ":" + formData.support_message,
    };
    await sendSupportTicket(
      payload,
      () => {
        // navigate(-1);
        // toast.success("Ticket is sent");
        setSuccess(true);
      },
      (err) => {
        toast.error("Ticket cannot sent");
      },
    );
  };
  return (
    <>
      {success ? (
        <InviteFormSuccessPopup />
      ) : (
        <div className="max-w-[450px] rounded-xl bg-neutral-200 px-9 py-8">
          <div className="pb-4">
            <div className="flex flex-row justify-between">
              <h2 className="mb-2 border-l-2 border-primary-900 pl-2 text-[22px] font-semibold leading-tight text-primary-900">
                Support Center
              </h2>
              <button type="button" onClick={() => navigate(-1)}>
                <CloseCircleIcon className="h-6 w-6" />
              </button>
            </div>
            <p className="text-[17px] leading-tight text-primary-900">
              Nasıl yardımcı olabiliriz?
            </p>
          </div>
          <Form
            className="flex flex-col gap-3"
            onSubmit={handleSubmit(sendQuestion)}
          >
            <Field label={"Başlık"}>
              <Input {...register("support_title")} id="support_title" />
            </Field>
            <Field label={"Konu"}>
              <Input {...register("support_topic")} id="support_topic" />
            </Field>
            <Field label={"Mesajınız"}>
              <textarea
                {...register("support_message")}
                id="support_message"
                className="p-4"
                cols={50}
                rows={4}
              />
            </Field>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              className="self-end"
            >
              Gönder
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};

export default SupportForm;
