import { useEffect } from "react";
import { useStore } from "../../store/useStore";

const Logout = () => {
  const logout = useStore((state) => state.logout);
  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};

export default Logout;
