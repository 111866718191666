import React from "react";
import LazyGuruAppIcon from "../../assets/LazyGuruAppIcon.png";
import { Baloon, Cloud1, Cloud2 } from "./Illustrations";
import { MetaCvText } from "../OldDesign/Icons";
import Button from "./Button/Button";
import { Link } from "react-router-dom";

const JoinCommunityPopup = () => {
  return (
    <div className="h- flex max-w-[588px] flex-col items-center gap-4 pb-6">
      <div className="relative flex h-[170px] w-full justify-center bg-primary-50 p-6">
        <Baloon className="absolute left-4 z-10 w-[100px]" />
        <Baloon className="absolute right-4 w-[60px] rotate-45" />
        <MetaCvText className="w-[150px] pb-8 text-primary-900" />
        <Cloud1 className="absolute -bottom-2 left-0 w-[150px]" />
        <Cloud1 className="absolute -bottom-4 left-[20%] w-[150px]" />
        <Cloud2 className="absolute -bottom-1 -right-[10%] w-[150px]" />
        <Cloud1 className="absolute -bottom-4 left-[40%] w-[150px]" />
        <Cloud1 className="absolute -bottom-5 left-[70%] w-[150px]" />
        <Cloud2 className="absolute -bottom-1 right-[20%] w-[150px]" />
      </div>
      <p className="px-12 text-center text-[15px] font-medium leading-tight text-primary-900">
        Meta CV'nizi indirmek ve fırsatlarlarımızı keşfetmek için hemen Humanas
        topluluğuna katılın! Seni aramızda görmek için sabırsızlanıyoruz!
      </p>
      <Link to="/community">
        <Button>Topluluğa Katıl</Button>
      </Link>
    </div>
  );
};

export default JoinCommunityPopup;
