import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const GradientBorder = ({ children, borderRadius, padding, gradientStart, gradientEnd, className }) => {
  const classes = twMerge("gradient-border-wrapper", className);
  return (
    <div
      className={classes}
      style={{
        "--border-radius": borderRadius,
        "--padding": padding,
        "--gradient-start": gradientStart,
        "--gradient-end": gradientEnd,
      }}
    >
      <div className="gradient-border-content">{children}</div>
    </div>
  );
};

GradientBorder.propTypes = {
  children: PropTypes.node.isRequired,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  gradientStart: PropTypes.string,
  gradientEnd: PropTypes.string,
};

GradientBorder.defaultProps = {
  borderRadius: "12px",
  padding: "1px",
  gradientStart: "#ffffff",
  gradientEnd: "#536cf0",
};

export default GradientBorder;
