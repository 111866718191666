import React from "react";
import SectionTitle from "../SectionTitle";
import OrientationCard from "../OrientationCard";
import DiamondCard from "../Cards/DiamondCard";

const ProfessionalOrientationSection = ({ user }) => {
  return (
    <div className="mb-2">
      <SectionTitle
        title="Mesleki Yönelim"
        className="items-start gap-1 pb-2 text-[22px]"
      />
      <p className="pb-9 leading-tight text-primary-900">
        Aşağıda mesleki yönelimlerim ile ilgili sonuçları bulabilirsiniz.
      </p>
      {/* DIAMOND CARDS */}
      {/* <div className="lg:my-12 lg:py-20">
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 max-lg:pb-4 lg:flex-wrap">
          <DiamondCard polar={user.metacv.polars[0]} />
          <DiamondCard polar={user.metacv.polars[1]} />
          <DiamondCard polar={user.metacv.polars[2]} />
        </div>
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 lg:flex-wrap">
          <DiamondCard polar={user.metacv.polars[3]} />
          <DiamondCard polar={user.metacv.polars[4]} />
        </div>
      </div> */}
      <div className="flex flex-col gap-6">
        {user.metacv.dimensions.map((orientation) => {
          return (
            <OrientationCard
              key={orientation.title}
              orientation={orientation}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProfessionalOrientationSection;
