import React from "react";
import userPP from "../../../user.png";
import { TagWithIcon } from "../Tags";
import {
  BagIconFilled,
  BirthdayFilled,
  LocationFilled,
  MailFilled,
  PhoneFilled,
  SendFilled,
} from "../../OldDesign/Icons";
import { Link } from "react-router-dom";
const CvHeader = ({ user }) => {
  const work_locations = [];
  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  const work_times = [];
  for (const [key, value] of Object.entries(user.work_time)) {
    if (value) {
      if (key === "fulltime") work_times.push("Full Time");
      if (key === "parttime") work_times.push("Part Time");
      if (key === "freelance") work_times.push("Freelance");
    }
  }
  return (
    <div className="rounded-xl bg-neutral-200 p-4">
      <div className="grid grid-cols-4 gap-4 pb-4">
        {/* PERSONAL INFO */}
        <div className="col-span-1 flex flex-col justify-between rounded-lg bg-neutral-100 p-6">
          <div className="relative mx-auto mb-2 flex h-[110px] w-[110px] items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[80px] max-lg:w-[80px]">
            <img
              src={userPP}
              alt="profile"
              className="h-[100px] w-[100px] rounded-full max-lg:h-[70px] max-lg:w-[70px]"
            />
            <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-2.5 text-[13px]  font-semibold text-neutral-100 max-lg:text-[11px]">
              LVL {user.metacv_level}
            </span>
          </div>
          <ul className="flex flex-col gap-2">
            <h4 className="pb-1 text-[17px] font-semibold leading-tight text-primary-900">
              Kişisel Bilgiler
            </h4>
            <li className="flex items-center gap-2">
              <MailFilled className="h-4 w-4 text-primary-900" />
              <p className="text-[13px] font-medium leading-tight text-primary-900">
                {user.email || "Not found"}
              </p>
            </li>
            <li className="flex items-center gap-2">
              <PhoneFilled className="h-4 w-4" />
              <p className="text-[13px] font-medium leading-tight text-primary-900">
                {user.phone || "Not found"}
              </p>
            </li>
            <li className="flex items-center gap-2">
              <LocationFilled className="h-4 w-4" />
              <p className="text-[13px] font-medium leading-tight text-primary-900">
                {user.location_state.state_name || "Not found"},{" "}
                {user.location_country.country_name || "Not found"}
              </p>
            </li>
            <li className="flex items-center gap-2">
              <BirthdayFilled className="h-4 w-4" />
              <p className="text-[13px] font-medium leading-tight text-primary-900">
                {user.birth_date || "Not found"}
              </p>
            </li>
          </ul>
        </div>
        {/* ABOUT */}
        <div className="col-span-2">
          <p className="pb-4 text-[36px] font-bold leading-tight text-primary-900">
            {user.name}{" "}
            <span className="text-[24px] font-medium italic">
              {user.job.job_name}
            </span>
          </p>
          <div className="flex justify-start gap-2 pb-2">
            <TagWithIcon
              icon={<SendFilled className="h-[14px] w-[14px]" />}
              className="bg-secondary_recruiter-700 px-2 py-1 text-secondary_recruiter-100"
              text={`${user.is_employee ? "Employee" : "Candidate"}`}
            />
            <TagWithIcon
              icon={<BagIconFilled className="h-[14px] w-[14px]" />}
              className={`${
                user.work_state === "unemployed"
                  ? "bg-success-400 text-success-100"
                  : " bg-error-300 text-error-100"
              }  px-2 py-1 `}
              text={`${
                user.work_state === "unemployed" ? "Not working" : "Working"
              }`}
            />
            {/* <TagWithIcon
              className="bg-primary-500 px-2 py-1 text-primary-50"
              text={`Open to work`}
            /> */}
          </div>
          <div className="flex justify-start gap-2 pb-3">
            {work_locations.map((workLocation, index) => (
              <TagWithIcon
                key={index}
                className="bg-secondary_recruiter-200 px-2 py-1 text-secondary_recruiter-600"
                text={workLocation}
              />
            ))}
            {work_times.map((workTime, index) => (
              <TagWithIcon
                key={index}
                className="bg-primary-200 px-2 py-1 text-primary-600"
                text={workTime}
              />
            ))}
          </div>
          <div className="flex flex-col gap-1 pb-3">
            <h6 className="text-[14px] font-medium leading-tight text-neutral-900">
              {user.metacv.aid[0].name} - {user.metacv.aid[0].title}
            </h6>
            <p className="text-[13px] leading-tight text-neutral-800">
              {user.metacv.aid[0].definition}
            </p>
          </div>
          <div>
            <h4 className="pb-1 text-[17px] font-semibold leading-tight text-primary-900">
              Hakkında
            </h4>
            <p
              style={{ wordBreak: "break-word" }}
              className="text-[16px] leading-tight text-primary-900"
            >
              {user.user_bio}
            </p>
          </div>
        </div>
        <div
          style={{ background: user.metacv.aid[0].secondary_color }}
          className={`col-span-1 flex flex-col items-center justify-start rounded-xl p-4 max-lg:p-3`}
        >
          <div
            style={{
              background: `linear-gradient(180deg, ${user.metacv.aid[0].primary_color} 0%, #FFFFFF00 100%)`,
            }}
            className={`mb-2 h-[210px] w-[210px] rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-[160px] max-lg:w-[160px]`}
          >
            <Link to={`/aid/identities/${user.metacv.aid[0].aid}`}>
              <img
                src={
                  process.env.REACT_APP_STRAPI_BASE_URL +
                  user.metacv.aid[0].logo
                }
                alt={user.metacv.aid[0].name}
              />
            </Link>
          </div>

          <h2
            style={{ color: user.metacv.aid[0].primary_color }}
            className={`pb-1 text-[24px] font-semibold leading-tight`}
          >
            {user.metacv.aid[0].name}
          </h2>
          <h2
            style={{ color: user.metacv.aid[0].primary_color }}
            className={`pb-3 text-[18px] font-normal leading-tight`}
          >
            {user.metacv.aid[0].title}
          </h2>
          <div className="flex flex-wrap items-center justify-center gap-1.5">
            {user.metacv.aid[0].keywords.map((tag) => (
              <TagWithIcon
                key={tag.keyword}
                className={"bg-neutral-100 text-primary-800"}
                text={tag.keyword}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {/* SKILLS&LANG */}
        <div className="rounded-lg bg-neutral-100 p-6">
          <h4 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">
            Yetenekler
          </h4>
          <ul className="flex flex-wrap gap-2 pb-4">
            {user.skills_array.map((skill, index) => (
              <li
                key={index}
                className="flex flex-row gap-2 text-[14px] font-medium leading-tight text-primary-900"
              >
                <p className="text-[6px] leading-[18px]">{"\u2B24"}</p>
                {skill.skill_name}
              </li>
            ))}
          </ul>
          <h4 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">
            Dil Bilgisi
          </h4>
          <ul className="flex flex-wrap gap-2">
            {user.languages_array.map((language, index) => (
              <li
                key={index}
                className="flex flex-row gap-2 text-[14px] font-medium leading-tight text-primary-900"
              >
                <p className="text-[6px] leading-[18px]">{"\u2B24"}</p>
                {language.language_name} {language.language_proficiency}
              </li>
            ))}
          </ul>
        </div>
        {/* EDUCATION */}
        <div className="rounded-lg bg-neutral-100 p-6">
          <h4 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">
            Eğitim Durumu
          </h4>
          <ul className="flex flex-col gap-4">
            {user.educations_array.map((school, index) => (
              <li key={index} className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <p className="text-[6px] leading-[18px]">{"\u2B24"}</p>
                  <p className="text-[14px] font-medium leading-tight text-primary-900">
                    {school.institution}
                  </p>
                  <p className="ml-auto whitespace-nowrap text-[14px] font-medium leading-tight text-neutral-700">
                    ({school.start_date.substring(0, 4)}-
                    {school.end_date.substring(0, 4)})
                  </p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {school.department !== "-1" && school.department + ","}{" "}
                  {school.degree}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {/* EXPERIENCE */}
        <div className="rounded-lg bg-neutral-100 p-6">
          <h4 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">
            Deneyim
          </h4>
          <ul className="flex flex-col gap-4">
            {user.experiences_array.map((exp, index) => (
              <li key={index} className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <p className="text-[6px] leading-[18px]">{"\u2B24"}</p>
                  <p className="text-[14px] font-medium leading-tight text-primary-900">
                    {exp.firm_name}
                  </p>
                  <p className="ml-auto whitespace-nowrap text-[14px] font-medium leading-tight text-neutral-700">
                    ({exp.start_date.replace("-", "/")}-
                    {exp.end_date ? exp.end_date.replace("-", "/") : "Devam"})
                  </p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {exp.job.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CvHeader;
