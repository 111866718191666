import React from "react";

import { useStore } from "../store/useStore";
import { Navigate } from "react-router-dom";

const ProtectedAuthRoute = ({ ...props }) => {
  const loginAction = useStore((state) => state.loginAction);

  if (!props.allowedAction.includes(loginAction.type)) {
    return <Navigate to="/login" />;
  }

  return <>{props.children}</>;
};
export default ProtectedAuthRoute;
