import React from "react";
import { twMerge } from "tailwind-merge";

const ExpBar = ({ percent, ...props }) => {
  const rate = percent < 100 ? percent : percent >= 100 ? 100 : 12;
  const classes =
    "h-[12px] rounded-full bg-[linear-gradient(180deg,#106BAC_0%,#6FB6FF_100%)] text-center text-[8px] font-semibold leading-[12px] text-primary-100 transition-all";
  const mergedClasses = twMerge(classes, props.className);
  return (
    <>
      <div className="w-full overflow-clip rounded-full bg-primary-200">
        <div className={mergedClasses} style={{ width: `${rate}%` }}></div>
      </div>
    </>
  );
};

export default ExpBar;
