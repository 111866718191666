import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { ExclamationIcon, EyeOffIcon, EyeOnIcon } from "./Icons";

export const InputText = ({
  register,
  rules,
  errors = false,
  value,
  label,
  name,
  placeholder,
  onChange,
  icon,
  type,
  inputClassName,
  wrapperClassName,
  id,
}) => {
  let isValid = !errors[name];
  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className="text-[16px] font-medium leading-tight text-neutral-900"
        >
          {label}
        </label>
      )}
      <div className={`${wrapperClassName} relative`}>
        <input
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder=""
          className={`${inputClassName} ${
            !isValid
              ? "outline-error-400 hover:outline-error-400 focus:outline-error-400"
              : "outline-neutral-300 hover:outline-primary-600 focus:outline-primary-600"
          } input-text peer h-[52px]  w-full rounded-lg bg-neutral-100 py-4 pl-4 pr-10 text-[13px] outline outline-1 transition-all lg:text-[16px]`}
          {...(register && register(name, rules))}
          id={id}
        />
        <span className="input-text-placeholder pointer-events-none absolute left-4 top-[20px] rounded-[8px] bg-white text-[14px] leading-none text-neutral-700 transition-all peer-focus:-top-[6px] peer-focus:left-6 peer-focus:px-2.5 peer-focus:text-[12px] lg:top-[18px] lg:text-[16px] peer-focus:lg:-top-[7px] peer-focus:lg:text-[13px]">
          {placeholder}
        </span>
        <span className="absolute right-3 top-4 h-5 w-5">{icon}</span>
        {errors[name] && (
          <p
            id="email-validation-error-message"
            className="flex flex-row items-center gap-1 pt-1.5 text-[13px] text-error-400"
          >
            <ExclamationIcon className="h-3.5 w-3.5" />
            {errors[name].message}
          </p>
        )}
      </div>
    </>
  );
};

export const InputPhone = ({
  value,
  errors = false,
  label,
  name,
  placeholder,
  onChange,
  icon,
  inputClassName,
}) => {
  let isValid = !errors[name];
  return (
    <div className="relative my-2">
      {label && <label htmlFor={name}>{label}</label>}
      <PhoneInput
        innerRef
        defaultCountry="tr"
        disableFormatting
        forceDialCode
        value={value}
        name={name}
        onChange={onChange}
        placeholder=""
        className={`${inputClassName} ${
          !isValid
            ? "outline-error-400 hover:outline-error-400 focus:outline-error-400"
            : "outline-neutral-300 hover:outline-primary-600 focus:outline-primary-600"
        } input-phone peer h-12  w-full rounded-lg bg-neutral-100 pr-10 text-[13px] outline outline-1 transition-all lg:h-[52px] lg:text-[16px] `}
        inputProps={{
          className: "phone-input",
          style: {
            padding: "16px 4px",
          },
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            border: "none",
            background: "#FFF",
            padding: "0 6px 0 12px",
            height: "100%",
            width: "100%",
            borderRadius: "8px 0 0 8px",
          },
          dropdownStyleProps: {
            className: "phone-input-dropdown",
          },
        }}
      />
      <span className="input-phone-placeholder pointer-events-none absolute left-4 top-[18px] rounded-md bg-white text-[14px] leading-none text-neutral-700 transition-all peer-focus:-top-[6px] peer-focus:left-6 peer-focus:px-2.5 peer-focus:text-[12px] lg:text-[16px] peer-focus:lg:-top-[7px] peer-focus:lg:text-[13px]">
        {placeholder}
      </span>
      <span className="absolute right-3 top-4 h-5 w-5">{icon}</span>
      {errors[name] && (
        <p className="flex flex-row items-center gap-1 pt-1.5 text-[13px] text-error-400">
          <ExclamationIcon className="h-3.5 w-3.5" />
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export const InputCheckbox = ({
  name,
  errors = false,
  label,
  register,
  rules,
  wrapperClassName,
  inputClassName,
}) => {
  let isValid = !errors[name];
  return (
    <>
      <label
        className={`${wrapperClassName} relative cursor-pointer pl-6 text-[13px] leading-5 text-primary-800 lg:text-[14px]`}
      >
        <input
          name={name}
          type="checkbox"
          className={`${inputClassName} input-checkbox peer absolute h-0 w-0 cursor-pointer opacity-0`}
          {...(register && register(name, rules))}
        />
        <span
          className={`${
            isValid ? "" : "border-error-400"
          } input-checkmark absolute left-0 top-0 h-5 w-5 rounded-[4px] border border-[#D2D2D7] bg-white peer-checked:border-[#106BAC] peer-checked:bg-[#106BAC]`}
        ></span>
        {label}
      </label>
      {errors[name] && (
        <p className="flex flex-row items-center gap-1 text-[13px] text-error-400">
          <ExclamationIcon className="h-3.5 w-3.5" />
          {errors[name].message}
        </p>
      )}
    </>
  );
};

export const InputPassword = ({
  register,
  rules,
  errors = false,
  value,
  label,
  name,
  placeholder,
  onChange,
  inputClassName,
  wrapperClassName,
  copyInput = false,
}) => {
  let isValid = !errors[name];
  const [visible, setVisible] = useState(false);
  return (
    <div className={`${wrapperClassName} relative my-2 font-inter`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={visible ? "text" : "password"}
        value={value}
        onPaste={(e) => {
          if (!copyInput) {
            e.preventDefault();
            return false;
          }
        }}
        onCopy={(e) => {
          if (!copyInput) {
            e.preventDefault();
            return false;
          }
        }}
        name={name}
        onChange={onChange}
        placeholder=""
        className={`${inputClassName} ${
          !isValid
            ? "outline-error-400 hover:outline-error-400 focus:outline-error-400"
            : "outline-neutral-300 hover:outline-primary-600 focus:outline-primary-600"
        } input-text peer h-12  w-full rounded-lg bg-neutral-100 py-4 pl-3 pr-10 text-[13px] outline outline-1 transition-all lg:h-[52px] lg:pl-4 lg:text-[16px]`}
        {...(register && register(name, rules))}
      />
      <span className="input-text-placeholder pointer-events-none absolute left-4 top-[18px] rounded-md bg-white text-[14px] leading-none text-neutral-700 transition-all peer-focus:-top-[6px] peer-focus:left-6 peer-focus:px-2.5 peer-focus:text-[12px] lg:text-[16px] peer-focus:lg:-top-[7px] peer-focus:lg:text-[13px]">
        {placeholder}
      </span>
      <span
        className="absolute right-3 top-4 h-5 w-5 cursor-pointer"
        onClick={() => setVisible(!visible)}
        id="password-hide-button"
      >
        {visible ? (
          <EyeOffIcon className="text-neutral-700" />
        ) : (
          <EyeOnIcon className="text-neutral-700" />
        )}
      </span>
      {errors[name] && (
        <p className="flex flex-row items-center gap-1 pt-1.5 text-[13px] text-error-400">
          <ExclamationIcon className="h-3.5 w-3.5" />
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

// export const FormInput = () => {
//   return (

//   )
// }
