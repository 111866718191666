import React from "react";
import {
  BlueTournamentCardIcon,
  GreenTournamentCardIcon,
  GreyTournamentCardIcon,
  LimeTournamentCardIcon,
  OrangeTournamentCardIcon,
  PinkTournamentCardIcon,
  PurpleTournamentCardIcon,
  RedTournamentCardIcon,
} from "../Illustrations";

export const TournamentCard = ({ variant, question, onClick, selected, order }) => {
  const variantStyles = {
    blue: "bg-[radial-gradient(circle,rgba(207,225,238,1)_0%,rgba(158,203,246,1)_65%)]",
    purple: "bg-[radial-gradient(circle,rgba(223,226,245,1)_0%,rgba(160,169,226,1)_65%)] lg:rotate-2",
    green: "bg-[radial-gradient(circle,rgba(208,235,232,1)_0%,rgba(115,196,185,1)_100%)]",
    red: "bg-[radial-gradient(circle,rgba(255,233,232,1)_0%,rgba(239,152,143,1)_100%)] lg:rotate-2",
  };
  const icons = {
    blue: <BlueTournamentCardIcon />,
    purple: <PurpleTournamentCardIcon />,
    green: <GreenTournamentCardIcon />,
    red: <RedTournamentCardIcon />,
  };
  return (
    <div className="relative w-fit">
      <div
        className={`${
          selected && "brightness-[40%]"
        } h-[225px] w-[195px] rounded-xl shadow-md max-lg:h-[115px] max-lg:w-[310px] ${
          variantStyles[variant]
        } cursor-pointer`}
        onClick={onClick}
      >
        <div className="h-[42px] p-3 max-lg:h-[26px] max-lg:p-2">{icons[variant]}</div>
        <div className="px-5 py-3 max-lg:px-2 max-lg:py-1.5">
          <p className="break-words text-center text-base font-medium leading-tight text-primary-900">{question}</p>
        </div>
      </div>
      {selected && (
        <span className="absolute bottom-0 right-0 p-4 text-[40px] font-bold leading-none text-neutral-100 drop-shadow-textShadow">
          {order + 1}
        </span>
      )}
    </div>
  );
};

export const TournamentCardMini = ({ variant, question, onClick, selected, order }) => {
  const variantStyles = {
    blue: "bg-[radial-gradient(circle,rgba(207,225,238,1)_0%,rgba(158,203,246,1)_65%)]",
    purple: "bg-[radial-gradient(circle,rgba(223,226,245,1)_0%,rgba(160,169,226,1)_65%)] lg:rotate-2",
    green: "bg-[radial-gradient(circle,rgba(208,235,232,1)_0%,rgba(115,196,185,1)_100%)]",
    red: "bg-[radial-gradient(circle,rgba(255,233,232,1)_0%,rgba(239,152,143,1)_100%)] lg:rotate-2",
    orange: "bg-[radial-gradient(circle,#FEF3E6_0%,#FBD8AA_100%)] lg:rotate-2",
    grey: "bg-[radial-gradient(circle,#FFFFFF_0%,#D7D7D7_100%)]",
    lime: "bg-[radial-gradient(circle,#FFFFFF_0%,#E5F9DB_100%)] lg:rotate-2",
    pink: "bg-[radial-gradient(circle,#FFFFFF_0%,#E4E1F2_100%)]",
  };
  const icons = {
    blue: <BlueTournamentCardIcon className="w-[39px]" />,
    purple: <PurpleTournamentCardIcon className="w-[44px]" />,
    green: <GreenTournamentCardIcon className="w-[28px]" />,
    red: <RedTournamentCardIcon className="w-[28px]" />,
    orange: <OrangeTournamentCardIcon className="w-[28px]" />,
    grey: <GreyTournamentCardIcon className="w-[28px]" />,
    lime: <LimeTournamentCardIcon className="w-[28px]" />,
    pink: <PinkTournamentCardIcon className="w-[28px]" />,
  };
  return (
    <div className="relative w-fit">
      <div
        className={`${
          selected && "brightness-[40%]"
        } h-[149px] w-[183px] rounded-xl shadow-md max-lg:h-[115px] max-lg:w-[310px] ${
          variantStyles[variant]
        } cursor-pointer`}
        onClick={onClick}
      >
        <div className="flex h-[24px] items-center px-3 max-lg:h-[24px] max-lg:px-2">{icons[variant]}</div>
        <div className="p-2 max-lg:px-2 max-lg:py-1.5">
          <p className="break-words text-center text-[14px] font-medium leading-tight text-primary-900">{question}</p>
        </div>
      </div>
      {selected && (
        <span className="absolute bottom-0 right-0 p-4 text-[28px] font-bold leading-none text-neutral-100 drop-shadow-textShadow">
          {order + 1}
        </span>
      )}
    </div>
  );
};
