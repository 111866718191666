import React from "react";
import { InfoOutlined, RightArrowOutlined } from "../OldDesign/Icons";
import Tooltip from "./Tooltips/Tooltip";
import { COMPANY_STATS } from "./constants";
import { Link } from "react-router-dom";

const StatCard = ({ stat }) => {
  return (
    <div className="flex flex-col justify-between gap-6 rounded-xl bg-primary-100 px-3 py-2 text-primary-900 max-lg:w-full max-lg:flex-row">
      <div className="flex flex-row items-center gap-2 max-lg:gap-1">
        <div
          className={`${
            COMPANY_STATS[stat.id].bgColor
          } flex items-center justify-center rounded-full p-2 text-primary-100`}
        >
          {COMPANY_STATS[stat.id].icon}
        </div>
        <p className="mr-auto overflow-hidden text-ellipsis whitespace-nowrap text-[16px] font-semibold leading-tight max-lg:text-[13px] max-lg:font-medium">
          {stat.title}
        </p>
        <Tooltip content={`${COMPANY_STATS[stat.id].content}`}>
          <InfoOutlined className="h-5 w-5 text-primary-400 max-lg:h-4 max-lg:w-4" />
        </Tooltip>
      </div>
      <div className="flex flex-row items-center max-lg:gap-2">
        <p className="text-[32px] font-bold leading-tight max-lg:text-[18px] ">
          {stat.value}
        </p>
        <Link
          className={`${
            stat.title === "Applications" && "pointer-events-none"
          } relative ml-auto`}
          to="/mynetwork/connections"
          // state={{ stateSelectedGroupFilter: stat.title.toLowerCase() }}
        >
          {["applications", "unlocks"].includes(stat.id) ? (
            <span className="text-[9px] font-semibold text-primary-900">
              Coming Soon
            </span>
          ) : (
            <RightArrowOutlined className="h-6 w-6 max-lg:h-[13px] max-lg:w-[13px]" />
          )}
        </Link>
      </div>
    </div>
  );
};

export default StatCard;
