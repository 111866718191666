import React from "react";
import { HeadHunterOutlined } from "../OldDesign/Icons";

const ButtonHeadHunter = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="hover:bg-secondary_recruiter-450 max-lg:bg-secondary_recruiter-450 group flex h-[180px] w-[147px] flex-col items-center justify-end gap-6 rounded-xl border border-neutral-300 p-9 font-medium leading-5 hover:text-white max-lg:text-white lg:h-[240px] lg:w-[190px] lg:gap-12"
    >
      <HeadHunterOutlined />
      Headhunter
    </button>
  );
};

export default ButtonHeadHunter;
