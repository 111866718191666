import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HumanasLogo } from "../OldDesign/Icons";
import { AutoLoginNotFound } from "./Illustrations";
import Button from "./Button/Button";
import { useStore } from "../../store/useStore";

const CompanyAutoLogin = () => {
  const navigate = useNavigate();
  const os = useStore((state) => state.os);
  const autoLoginLink = localStorage.getItem("autoLoginLink");
  const [notFound, setNotFound] = useState(null);

  const handleNavigateGame = () => {
    if (os === "Mac OS") {
      window.location.href = "com.HumanasTech.LazyGuru://mylink?";
    } else {
      window.location.href = "lazyguru://mylink?";
    }
  };
  useEffect(() => {
    if (autoLoginLink) {
      navigate(autoLoginLink, { state: { linkFound: true } });
      // window.location.href = autoLoginLink;
    } else {
      setNotFound(true);
    }
  }, [autoLoginLink, navigate]);
  return !notFound ? (
    <>Auto Login</>
  ) : (
    <div className="container2 h-screen py-4">
      <HumanasLogo className="text-primary-600 max-lg:mx-auto" />
      <div className="flex h-full flex-col items-center justify-center gap-9">
        <AutoLoginNotFound />
        <div className="text-center">
          <p>Merhaba</p>
          <p>
            Herhangi bir şirkete ait bir davet linki bulunamadı. Oyuna geri
            dönmek için aşağıdaki butona tıklayınız.
          </p>
        </div>
        <Button onClick={() => handleNavigateGame()} className={"w-full"}>
          Oyuna Geri Dön!
        </Button>
      </div>
    </div>
  );
};

export default CompanyAutoLogin;
