import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  PurpleSmoke,
  QuotationMark,
} from "../../../components/NewDesign/Illustrations";
import Breadcrumb from "../../../components/NewDesign/Breadcrumb";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import { AID_SUBPAGES } from "../../../components/NewDesign/constants";

import { NavLink } from "react-router-dom";
import StartDiscussion from "../../../components/NewDesign/StartDiscussion";
import { useStore } from "../../../store/useStore";

const AidPage = () => {
  const { aid } = useParams();
  const navigate = useNavigate();
  const strapiContent = useStore((state) => state.strapiContent);

  const _aidContent = strapiContent.data.find(
    (item) => `${item.attributes.codeName}` === aid,
  );
  const [AIDContent, setAIDContent] = useState(_aidContent);
  useEffect(() => {
    setAIDContent(_aidContent);
    // window.scrollTo({
    //   top: 63,
    //   behavior: "smooth",
    // });
  }, [navigate, _aidContent]);

  return (
    <div className="bg-neutral-200 max-lg:mt-[53px]">
      <div className="container2">
        <Breadcrumb />
        {/* HERO */}
        <div className="relative flex h-[250px] rounded-xl bg-[#000016] p-9 max-lg:p-4">
          <PurpleSmoke className={"absolute bottom-0 left-0 rotate-180"} />
          <div className="relative z-10 flex w-full flex-col items-center justify-center gap-4 max-lg:gap-3">
            <h1 className="gradient-text inline-block animate-gradient text-4xl font-semibold leading-tight text-transparent max-lg:text-[24px]">
              {AIDContent.attributes.name}: {AIDContent.attributes.title}
            </h1>
            <div className="flex flex-wrap gap-3 max-lg:justify-center max-lg:gap-2">
              {AIDContent.attributes.keyword.map((tag, index) => (
                <TagWithIcon
                  key={index}
                  text={tag.keyword}
                  className={`border border-neutral-100 bg-neutral-100 bg-opacity-20 text-neutral-100`}
                />
              ))}
            </div>
            <div className="relative w-2/3 px-8 py-2 max-lg:w-full max-lg:px-5 max-lg:py-1">
              <QuotationMark className={"absolute left-0 top-0 max-lg:w-5"} />
              <p className="text-center text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-[13px]">
                {AIDContent.attributes.definition}
              </p>
              <QuotationMark
                className={"absolute bottom-0 right-0 rotate-180 max-lg:w-5"}
              />
            </div>
          </div>
          <PurpleSmoke className={"absolute right-0 top-0"} />
        </div>
        {/* NAV */}
        <nav className="scrollbar-hide flex flex-row items-center gap-3 py-6 max-lg:gap-2 max-lg:overflow-y-auto max-lg:py-2 lg:justify-center">
          {AID_SUBPAGES.map((subpage, index) => {
            return (
              <NavLink
                key={index}
                className={({ isActive, isPending }) =>
                  `${
                    isActive
                      ? "bg-secondary_recruiter-600 font-semibold text-neutral-100 "
                      : "bg-neutral-100 font-medium text-secondary_recruiter-600"
                  } whitespace-nowrap rounded-xl px-4 py-3 transition-colors max-lg:text-[13px]`
                }
                to={subpage.path}
              >
                {subpage.name}
              </NavLink>
            );
          })}
        </nav>
        <Outlet context={[AIDContent]} />
        <div className="pt-4">
          <StartDiscussion />
        </div>
      </div>
    </div>
  );
};

export default AidPage;
