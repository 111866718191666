import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Field } from "../../../../components/NewDesign/Forms/Field";
import { Input, InputNumber } from "../../../../components/NewDesign/Forms/Input";
import Button from "../../../../components/NewDesign/Button/Button";
import { PlusCircularOutlined, RubbishOutlined } from "../../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store/useStore";
import { addJobPosition } from "../../../../api/endpoints";
import { toast } from "react-toastify";

const AddJobPosition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getJobPositions = useStore((state) => state.getJobPositions);
  const emptyJobPos = { name: "", seat_count: 0 };
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      job_positions: [emptyJobPos],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "job_positions",
  });

  const handleSubmitJobPositions = async (formData) => {
    await addJobPosition(
      formData,
      (res) => {
        toast.success("Yeni Pozisyon Eklendi");
        getJobPositions();
        navigate(location.state.previousLocation);
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        console.log(bool);
      },
    );
  };

  return (
    <div className="flex flex-col items-center gap-4 bg-secondary_recruiter-200 px-16 py-9">
      <p className="font-semibold leading-tight text-primary-900">
        Pozisyonlarınızın isimlerini ve çalışan sayılarını giriniz.
      </p>
      <ul className="flex flex-col items-center gap-2">
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="relative flex items-center gap-2">
              <Field
                label={index === 0 && "Pozisyon ismi"}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.name || errors.job_positions[1]?.name || errors.job_positions[2]?.name)
                }
                className={"mx-auto max-lg:w-[200px] lg:min-w-[450px]"}
              >
                <Input
                  {...register(`job_positions.${index}.name`, { required: true, maxLength: 30 })}
                  placeholder="Pozisyon ismi"
                />
              </Field>
              <Field
                label={index === 0 && "Kişi Sayısı"}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.seat_count ||
                    errors.job_positions[1]?.seat_count ||
                    errors.job_positions[2]?.seat_count)
                }
                className={"w-full"}
                name={`job_positions.${index}.seat_count`}
              >
                <Controller
                  control={control}
                  name={`job_positions.${index}.seat_count`}
                  rules={{ required: true, min: 1 }}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return <InputNumber min={0} max={9999} onChange={onChange} />;
                  }}
                />
              </Field>
              {index >= 1 && (
                <Button
                  onClick={() => remove(index)}
                  className="absolute -right-12 border-none bg-transparent p-2"
                  icon={<RubbishOutlined className="h-6 w-6 text-secondary_recruiter-600" />}
                />
              )}
            </li>
          );
        })}
        <button onClick={() => append(emptyJobPos)}>
          <PlusCircularOutlined className={"h-6 w-6 text-secondary_recruiter-600"} />
        </button>
      </ul>
      <Button
        loading={isSubmitting}
        className="border-none bg-neutral-100 text-secondary_recruiter-900"
        onClick={handleSubmit((formData, e) => handleSubmitJobPositions(formData))}
      >
        Kaydet
      </Button>
    </div>
  );
};

export default AddJobPosition;
