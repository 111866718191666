import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 1080); // Set mobile breakpoint (e.g., 768px)
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Re-check on window resize

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return isMobile;
};

export default useIsMobile;
