import React from "react";
import Button from "../../../components/NewDesign/Button/Button";
import { RightArrowOutlined } from "../../../components/OldDesign/Icons";
import { useStore } from "../../../store/useStore";

const ApplyNow = () => {
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  return (
    <section className="container2 ">
      <div className="flex items-center justify-between gap-16 rounded-xl bg-primary-500 px-9 py-6 max-lg:flex-col max-lg:gap-9 max-lg:px-6 max-lg:py-4">
        <div className="">
          <h2 className="pb-3 text-[22px] font-semibold leading-tight text-primary-75 max-lg:text-[18px]">
            {content["potansiyelini duyur baslik"]}
          </h2>
          <p className="text-[18px] font-medium leading-tight text-primary-75 max-lg:text-[14px]">
            {content["potansiyelini duyur aciklama"]}
          </p>
        </div>
        <div>
          <Button
            className="border-neutral-100 bg-neutral-100 text-primary-900"
            icon2={<RightArrowOutlined className="h-5 w-5" />}
          >
            {content["potansiyelini duyur buton"]}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ApplyNow;
