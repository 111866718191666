import React, { useEffect, useMemo, useState } from "react";
import eugeneIllustration from "../../../../assets/eugene1.png";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import AnalyseProgress from "../../../../components/NewDesign/StatusBar/AnalyseProgress";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../../../../components/NewDesign/Button/Button";
import { Field } from "../../../../components/NewDesign/Forms/Field";
import {
  ASelect,
  ImportanceRangeInput,
  MultiRangeInput,
  SSelect,
  Switch,
  TextArea,
} from "../../../../components/NewDesign/Forms/Input";
import { CloseOutlined, DoubleTickOutlined, InfoFilled, TickOutlined } from "../../../../components/OldDesign/Icons";
import { TagWithIcon } from "../../../../components/NewDesign/Tags";
import _ from "lodash";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { REACT_BASE_URL } from "../../../../api/constants";
import { postJobPosition } from "../../../../api/endpoints";
import { Form } from "../../../../components/NewDesign/Forms/Form";
import SectionTitle from "../../../../components/NewDesign/SectionTitle";
import useIsMobile from "../../../../utils/useIsMobile";
import { CORECV_FIELD_KEYS } from "../../../../components/NewDesign/constants";
import Tooltip from "../../../../components/NewDesign/Tooltips/Tooltip";

const defaultImportance = 3;

const SelectJobPosition = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;
  const maxJobPosSelection = 3;

  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              isMulti
              defaultValue={value}
              selected={value}
              onBlur={onBlur}
              loadOptions={loadJobTitleOptions}
              isOptionDisabled={(opt) => value.length >= maxJobPosSelection}
              onChange={(opt) => {
                const newOpts = opt.map((op) => (op.importance < 6 ? op : { ...op, importance: defaultImportance }));
                onChange(newOpts);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.job_name}
              getOptionValue={(opt) => opt.job_id}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectSkills = (props) => {
  const { field, formInstance } = props;
  const { control, getValues } = formInstance;

  const maxSkillSelection = 10;
  const loadSkillsOptions = async (value) => {
    const jobIds = getValues("job_title");
    const jobIdsParam = jobIds.reduce((acc, cur, index) => {
      acc = acc + "&job_id[]=" + cur.job_id;
      return acc;
    }, "");
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/skill?rows=100&page=1&keyword=${encodeURIComponent(value)}${jobIdsParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              isMulti
              defaultValue={value}
              selected={value}
              onBlur={onBlur}
              defaultOptions
              loadOptions={loadSkillsOptions}
              isOptionDisabled={(opt) => value.length >= maxSkillSelection}
              onChange={(opt) => {
                const newOpts = opt.map((op) => (op.importance < 6 ? op : { ...op, importance: defaultImportance }));
                onChange(newOpts);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.skill_name}
              getOptionValue={(opt) => opt.skill_name}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectAgeRange = (props) => {
  const { field, formInstance } = props;
  const { control, setValue } = formInstance;

  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <MultiRangeInput
              values={[value[0]?.low || 18, value[0]?.high || 25]}
              min={16}
              max={100}
              onChange={(values) => {
                values.length
                  ? onChange([{ low: values[0], high: values[1], importance: defaultImportance }])
                  : onChange([]);
              }}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectResidentLocations = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  const loadCityOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/state?page=1&rows=60&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  return (
    <>
      <Field name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              isMulti
              onBlur={onBlur}
              value={value}
              loadOptions={loadCityOptions}
              onChange={(opt) => {
                const newOpts = opt.map((op) => (op.importance < 6 ? op : { ...op, importance: defaultImportance }));
                onChange(newOpts);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.id}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectSchools = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  const loadInstitutionOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/institute?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              isMulti
              defaultValue={value}
              selected={value}
              onBlur={onBlur}
              defaultOptions
              isOptionDisabled={(opt) => value.length >= 3}
              loadOptions={loadInstitutionOptions}
              onChange={(opt) => {
                const newOpts = opt.map((op) => (op.importance < 6 ? op : { ...op, importance: defaultImportance }));
                onChange(newOpts);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.institute_name}
              getOptionValue={(opt) => opt.institute_id}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectDepartments = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/departments?page=1&rows=100&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              isMulti
              defaultValue={value}
              selected={value}
              onBlur={onBlur}
              defaultOptions
              isOptionDisabled={(opt) => value.length >= 2}
              loadOptions={loadDepartmentOptions}
              onChange={(opt) => {
                const newOpts = opt.map((op) => (op.importance < 6 ? op : { ...op, importance: defaultImportance }));
                onChange(newOpts);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.id}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectEducationDegree = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  const loadDegreeOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/educationlevel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ASelect
              defaultValue={value}
              selected={value}
              onBlur={onBlur}
              defaultOptions
              isOptionDisabled={(opt) => value.length >= 2}
              loadOptions={loadDegreeOptions}
              onChange={(opt) => {
                onChange([{ ...opt, importance: defaultImportance }]);
              }}
              placeholder="Seçiniz"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.id}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectLanguage = (props) => {
  const { field, formInstance } = props;
  const { control, watch, setValue } = formInstance;
  const [language, setLanguage] = useState(null);
  const languages = watch(field.name);

  const loadLanguageOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/language?rows=50&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const languageLevelOptions = [
    { proficiency_id: 0, proficiency_name: "A1" },
    { proficiency_id: 1, proficiency_name: "A2" },
    { proficiency_id: 2, proficiency_name: "B1" },
    { proficiency_id: 3, proficiency_name: "B2" },
    { proficiency_id: 4, proficiency_name: "C1" },
    { proficiency_id: 5, proficiency_name: "C2" },
  ];

  const handleRemoveLanguage = (language) => {
    const newVal = _.xorBy(languages, [language], (item) => item.language_id);
    setValue(field.name, newVal);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center gap-4 pb-2 max-lg:flex-col">
        <Field name={field.name} className={"lg:w-[260px]"}>
          <Controller
            control={control}
            name={field.name}
            rules={{ required: "Required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ASelect
                value={language}
                onBlur={onBlur}
                defaultOptions
                loadOptions={loadLanguageOptions}
                onChange={setLanguage}
                placeholder="Seçiniz"
                getOptionLabel={(opt) => opt.language_name}
                getOptionValue={(opt) => opt.language_id}
              />
            )}
          />
        </Field>
        <Field name={field.name} className={"lg:w-[260px]"}>
          <Controller
            control={control}
            name={field.name}
            rules={{ required: "Required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <SSelect
                onBlur={onBlur}
                value={null}
                isDisabled={!language}
                options={languageLevelOptions}
                onChange={(val) => {
                  if (val) {
                    const newVal = _.xorBy(
                      languages,
                      [
                        val.importance < 6
                          ? { ...val, ...language }
                          : { ...val, ...language, importance: defaultImportance },
                      ],
                      (item) => item.language_id,
                    );
                    onChange(newVal);
                    setLanguage(null);
                  }
                }}
                placeholder="Seçiniz"
                getOptionLabel={(opt) => opt.proficiency_name}
                getOptionValue={(opt) => opt.proficiency_id}
              />
            )}
          />
        </Field>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-center gap-1">
        {languages.map((language, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-0.5 rounded-full bg-neutral-100 px-2 py-1 text-primary-600 opacity-80"
            >
              <p className="text-[14px] leading-tight text-primary-600">
                {language.language_name + ", " + language.proficiency_name}
              </p>
              <button type="button" onClick={(e) => handleRemoveLanguage(language)}>
                <CloseOutlined className={"h-4 w-4"} />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

const SelectSectorExperienceRange = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;
  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <MultiRangeInput
              values={[value[0]?.low || 0, value[0]?.high || 10]}
              min={0}
              max={30}
              onChange={(values) =>
                values.length
                  ? onChange([
                      { low: Math.max(0, values[0]), high: Math.min(100, values[1]), importance: defaultImportance },
                    ])
                  : onChange([])
              }
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectJobTitleExperienceRange = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required" }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <MultiRangeInput
              values={[value[0]?.low || 0, value[0]?.high || 10]}
              min={0}
              max={30}
              onChange={(values) =>
                values.length
                  ? onChange([{ low: values[0], high: values[1], importance: defaultImportance }])
                  : onChange([])
              }
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectWorkStatus = (props) => {
  const { field, formInstance } = props;
  const { watch, setValue } = formInstance;

  const workStatus = watch(field.name);

  const workStatusOpts = [
    {
      id: "employed",
      name: "Çalışıyor",
      importance: defaultImportance,
    },
    {
      id: "unemployed",
      name: "Çalışmıyor",
      importance: defaultImportance,
    },
  ];

  const toggleWorkStatus = (pref) => {
    const newSelectedWorkStatus = _.xorBy([...workStatus], [pref], (item) => item.id);
    setValue(field.name, newSelectedWorkStatus, { shouldDirty: true });
  };

  return (
    <>
      <ul className="flex items-center justify-center gap-4 max-lg:flex-col">
        {workStatusOpts.map((pref, index) => (
          <li key={index} className="max-lg:w-full">
            <button type="button" className="max-lg:w-full" onClick={() => toggleWorkStatus(pref)}>
              <TagWithIcon
                className={`${
                  workStatus.find((item) => item.id === pref.id)
                    ? "bg-neutral-100 text-primary-600"
                    : "bg-transparent text-neutral-100"
                } border border-neutral-100 `}
                text={pref.name}
                icon2={workStatus.find((item) => item.id === pref.id) && <TickOutlined className="h-3 w-3" />}
              />
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

const SelectWorkingTimes = (props) => {
  const { field, formInstance } = props;
  const { watch, setValue } = formInstance;

  const workTimes = watch(field.name);
  const workTimesOpts = [
    {
      id: "fulltime",
      name: "Tam Zamanlı",
      importance: defaultImportance,
    },
    {
      id: "parttime",
      name: "Yarı zamanlı",
      importance: defaultImportance,
    },
    {
      id: "freelance",
      name: "Freelance",
      importance: defaultImportance,
    },
  ];

  const toggleWorkTimes = (pref) => {
    const newSelectedWorkTimes = _.xorBy([...workTimes], [pref], (item) => item.id);
    // console.log(newSelectedWorkStatus);
    setValue(field.name, newSelectedWorkTimes, { shouldDirty: true });
  };
  return (
    <>
      <ul className="flex items-center justify-center gap-4 max-lg:flex-col">
        {workTimesOpts.map((pref, index) => (
          <li className="max-lg:w-full" key={index}>
            <button type="button" className="max-lg:w-full" onClick={() => toggleWorkTimes(pref)}>
              <TagWithIcon
                className={`${
                  workTimes.find((item) => item.id === pref.id)
                    ? "bg-neutral-100 text-primary-600"
                    : "bg-transparent text-neutral-100"
                } border border-neutral-100`}
                text={pref.name}
                icon2={workTimes.find((item) => item.id === pref.id) && <TickOutlined className="h-3 w-3" />}
              />
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

const SelectWorkLocations = (props) => {
  const { field, formInstance } = props;
  const { watch, setValue } = formInstance;

  const workLocations = watch(field.name);
  const workLocationOpts = [
    {
      id: "office",
      name: "Ofis",
      importance: defaultImportance,
    },
    {
      id: "remote",
      name: "Uzaktan",
      importance: defaultImportance,
    },
    {
      id: "hybrid",
      name: "Hibrit",
      importance: defaultImportance,
    },
  ];

  const toggleWorkLocations = (pref) => {
    const newSelectedWorkLocations = _.xorBy([...workLocations], [pref], (item) => item.id);
    // console.log(newSelectedWorkStatus);
    setValue(field.name, newSelectedWorkLocations, { shouldDirty: true });
  };

  return (
    <>
      <ul className="flex items-center justify-center gap-4 max-lg:flex-col">
        {workLocationOpts.map((pref, index) => (
          <li className="max-lg:w-full" key={index}>
            <button type="button" className="max-lg:w-full" onClick={() => toggleWorkLocations(pref)}>
              <TagWithIcon
                className={`${
                  workLocations.find((item) => item.id === pref.id)
                    ? "bg-neutral-100 text-primary-600"
                    : "bg-transparent text-neutral-100"
                } border border-neutral-100`}
                text={pref.name}
                icon2={workLocations.find((item) => item.id === pref.id) && <TickOutlined className="h-3 w-3" />}
              />
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

const AdditionalExpectations = (props) => {
  const { field, formInstance } = props;
  const { control } = formInstance;

  return (
    <>
      <Field key={field.name} name={field.name}>
        <Controller
          control={control}
          name={field.name}
          rules={{ required: "Required", maxLength: 255 }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextArea
              rows={5}
              maxLength={255}
              value={value[0]?.name}
              onChange={(e) => onChange([{ name: e.target.value }])}
            />
          )}
        />
      </Field>
    </>
  );
};

const SelectImportances = (props) => {
  const { formInstance } = props;
  const location = useLocation();
  const { control, getValues } = formInstance;

  const formValues = getValues();

  useEffect(() => {
    if (location.state.jumpStep) {
      const fieldToJump = document.getElementById(location.state.jumpStep);
      if (fieldToJump) {
        fieldToJump.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [location.state.jumpStep]);
  return (
    <div>
      <p className="pb-4 text-sm leading-tight text-neutral-100">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget eros sed est venenatis mattis id non est.
        Nam eu turpis molestie, placerat quam ac, suscipit neque. Suspendisse lectus massa, rhoncus eu orci non, viverra
        ullamcorper justo. Nam porta, magna fermentum sagittis sollicitudin, justo felis eleifend leo, in luctus velit
        enim pellentesque nibh.
      </p>
      <div className="relative flex w-full flex-col gap-4">
        <div className="sticky top-0 z-10 flex w-full rounded-xl bg-neutral-100 px-6 py-3 max-lg:justify-between max-lg:px-2">
          <div className="flex basis-4/12 items-center gap-1 max-lg:hidden">
            <span className="text-sm font-semibold leading-tight text-primary-900">Tercihleriniz</span>
          </div>
          <div className="flex basis-4/12 items-center gap-1">
            <span className="whitespace-nowrap text-sm font-semibold leading-tight text-primary-900 max-lg:text-[13px]">
              Önem Derecesi
            </span>
            <Tooltip
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget eros sed est venenatis mattis id non est.
        Nam eu turpis molestie, placerat quam ac, suscipit neque."
            >
              <InfoFilled className={"h-3.5 w-3.5 text-neutral-500"} />
            </Tooltip>
          </div>
          <div className="flex basis-2/12 items-center justify-center gap-1">
            <span className="whitespace-nowrap text-sm font-semibold leading-tight text-primary-900 max-lg:text-[13px]">
              Olmazsa Olmaz
            </span>
            <Tooltip
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget eros sed est venenatis mattis id non est.
        Nam eu turpis molestie, placerat quam ac, suscipit neque."
            >
              <InfoFilled className={"h-3.5 w-3.5 text-neutral-500"} />
            </Tooltip>
          </div>
          <div className="flex basis-2/12 items-center justify-center gap-1">
            <span className="whitespace-nowrap text-sm font-semibold leading-tight text-primary-900 max-lg:text-[13px]">
              Hariç Tut
            </span>
            <Tooltip
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget eros sed est venenatis mattis id non est.
        Nam eu turpis molestie, placerat quam ac, suscipit neque."
            >
              <InfoFilled className={"h-3.5 w-3.5 text-neutral-500"} />
            </Tooltip>
          </div>
        </div>

        {Object.keys(CORECV_FIELD_KEYS)
          .filter((field) => field !== "about")
          .filter((field) => formValues[field].length > 0)
          .map((field, index) => {
            return (
              <div
                id={field}
                key={index}
                className="flex flex-col gap-0.5 rounded-xl bg-[linear-gradient(100deg,rgba(255,255,255,0.55)_1.98%,rgba(255,255,255,0.35)_98.4%)] p-6 max-lg:p-4"
              >
                <div className="flex items-center gap-2">
                  <SectionTitle
                    title={CORECV_FIELD_KEYS[field]}
                    className={"mb-0.5 w-fit items-start gap-0.5 text-base"}
                  />
                  {location.state.jumpStep && location.state.jumpStep === field && (
                    <span className="gradient-text animate-pulse text-sm font-bold text-transparent">
                      &#8226;Güncellenen
                    </span>
                  )}
                </div>
                {formValues[field].map((value, index) => {
                  return <FieldValueRow control={control} key={index} idx={index} fieldValue={value} field={field} />;
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const FieldValueRow = ({ control, fieldValue, field, idx }) => {
  const isMobile = useIsMobile();
  return (
    <div className="flex items-center max-lg:flex-col max-lg:items-baseline">
      <div className="flex lg:basis-1/3">
        <span className="pr-2 text-sm font-semibold leading-tight text-primary-900">
          {fieldValue.name ||
            fieldValue.job_name ||
            fieldValue.language_name ||
            fieldValue.country_name ||
            fieldValue.skill_name ||
            fieldValue.institute_name ||
            fieldValue.low + "-" + fieldValue.high}
        </span>
      </div>
      <div className="flex max-lg:w-full max-lg:justify-between lg:basis-2/3">
        {/* ONEM */}
        <div className="max-lg:w-[150px] lg:basis-2/4">
          {fieldValue.importance > 0 ? (
            <Field className={"w-full"} name={field}>
              <Controller
                control={control}
                name={field}
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <ImportanceRangeInput
                      values={[fieldValue.importance]}
                      onChange={(val) => {
                        const newValues = [...value];
                        newValues[idx] = {
                          ...newValues[idx],
                          importance: val,
                        };
                        onChange(newValues);
                      }}
                    />
                  );
                }}
              />
            </Field>
          ) : fieldValue.importance === 0 ? (
            <span className="my-1 flex w-[200px] items-center justify-center gap-1 rounded-full bg-success-400 text-[13px] font-medium leading-[32px] text-neutral-100 max-lg:w-[150px]">
              Olmazsa Olmaz
              <DoubleTickOutlined className={"w-4"} />
            </span>
          ) : fieldValue.importance === -1 ? (
            <span className="my-1 flex w-[200px] items-center justify-center gap-1 rounded-full bg-neutral-700 text-[13px] font-medium leading-[32px] text-neutral-100 max-lg:w-[150px]">
              Haric Tut
              <DoubleTickOutlined className={"w-4"} />
            </span>
          ) : (
            <></>
          )}
        </div>
        {/* OLMAZSA OLMAZ */}
        <div className="flex items-center justify-center lg:basis-1/4">
          <Field name={field}>
            <Controller
              control={control}
              name={field}
              rules={{ required: "Required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Switch
                    size={isMobile ? "xsmall" : "small"}
                    checked={value[idx].importance === 0}
                    onChange={(e) => {
                      const newValues = [...value];
                      newValues[idx] = {
                        ...newValues[idx],
                        importance: e.target.checked ? 0 : defaultImportance,
                      };
                      onChange(newValues);
                    }}
                  />
                );
              }}
            />
          </Field>
        </div>
        {/* HARIC TUT */}
        <div className="flex items-center justify-center lg:basis-1/4">
          <Field name={field}>
            <Controller
              control={control}
              name={field}
              rules={{ required: "Required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Switch
                    size={isMobile ? "xsmall" : "small"}
                    checked={value[idx].importance === -1}
                    onChange={(e) => {
                      const newValues = [...value];
                      newValues[idx] = {
                        ...newValues[idx],
                        importance: e.target.checked ? -1 : defaultImportance,
                      };
                      onChange(newValues);
                    }}
                  />
                );
              }}
            />
          </Field>
        </div>
      </div>
    </div>
  );
};

const CorecvForm = () => {
  const isMobile = useIsMobile();
  const [understood, setUnderstood] = useState(localStorage.getItem("ccvUnderstood"));
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isSkipping, setIsSkipping] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [id, jobPosition, setJobPosData] = useOutletContext();

  const formInstance = useForm({
    defaultValues: jobPosition.core_cv,
    mode: "onSubmit",
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = formInstance;

  const steps = [
    {
      id: 1,
      component: SelectJobPosition,
      field: { name: "job_title" },
      text: "İş Pozisyonunu seçiniz.",
      warningText: "En fazla 3 pozisyon seçebilirsiniz.",
      canSubmit: watch("job_title").length > 0,
    },
    {
      id: 2,
      component: SelectSkills,
      field: { name: "skills" },
      text: "Pozisyon için gerekli olan becerileri seçiniz.",
      warningText: "En fazla 10 beceri seçebilirsiniz.",
      canSubmit: watch("skills").length > 0,
    },
    {
      id: 3,
      component: SelectAgeRange,
      field: { name: "age_range" },
      text: "Pozisyonun gerektirdiği yaş aralığını belirtiniz.",
      warningText: "",
      canSubmit: watch("age_range").length > 0,
    },
    {
      id: 4,
      component: SelectWorkLocations,
      field: { name: "work_location" },
      text: "Çalışma yeri tercihini seçiniz.",
      warningText: "",
      canSubmit: watch("work_location").length > 0,
    },
    {
      id: 5,
      component: SelectWorkingTimes,
      field: { name: "work_time" },
      text: "Çalışma zamanı tercihinizi seçiniz.",
      warningText: "",
      canSubmit: watch("work_time").length > 0,
    },
    {
      id: 6,
      component: SelectWorkStatus,
      field: { name: "work_state" },
      text: "Çalışma durumu tercihinizi seçiniz.",
      warningText: "",
      canSubmit: watch("work_state").length > 0,
    },
    {
      id: 7,
      component: SelectResidentLocations,
      field: { name: "location" },
      text: "İkametgah tercihinizi seçiniz.",
      warningText: "",
      canSubmit: watch("location").length > 0,
    },

    {
      id: 8,
      component: SelectEducationDegree,
      field: { name: "degree" },
      text: "Minimum eğitim seviyesini seçiniz.",
      warningText: "",
      canSubmit: watch("degree").length > 0,
    },
    {
      id: 9,
      component: SelectSchools,
      field: { name: "universities" },
      text: "Okul tercih(ler)inizi yapınız.",
      warningText: "En fazla 3 okul seçebilirsiniz.",
      canSubmit: watch("universities").length > 0,
    },
    {
      id: 10,
      component: SelectDepartments,
      field: { name: "university_departments" },
      text: "Bölüm tercih(ler)inizi yapınız.",
      warningText: "En fazla 2 bölüm seçebilirsiniz.",
      canSubmit: watch("university_departments").length > 0,
    },
    {
      id: 11,
      component: SelectLanguage,
      field: { name: "languages" },
      text: "Yabancı dil tercih(ler)inizi seçiniz.",
      warningText: "En fazla 3 dil seçebilirsiniz.",
      canSubmit: watch("languages").length > 0,
    },
    {
      id: 12,
      component: SelectSectorExperienceRange,
      field: { name: "sector_experience" },
      text: "Sektör tecrübesini yıl cinsinden belirtiniz.",
      warningText: "",
      canSubmit: watch("sector_experience").length > 0,
    },
    {
      id: 13,
      component: SelectJobTitleExperienceRange,
      field: { name: "job_title_experience" },
      text: "Pozisyon tecrübesini yıl cinsinden belirtiniz.",
      warningText: "",
      canSubmit: watch("job_title_experience").length > 0,
    },
    {
      id: 14,
      component: AdditionalExpectations,
      field: { name: "about" },
      text: "Ek beklentileriniz neler?",
      warningText: "",
      canSubmit: watch("about").length > 0 && watch("about")[0].name.length > 0,
    },
    {
      id: 15,
      component: SelectImportances,
      field: { name: "importance" },
    },
  ];

  const progressPercent = jobPosition.progress_percent_core_cv;

  const nextStep = () => {
    setDirection(1);
    if (currentStep === steps.length - 1) {
      if (location.state?.jumpStep) {
        returnPositionResultPage();
      } else {
        returnAnalysisMainPage();
      }
    } else {
      if (location.state?.jumpStep) {
        setCurrentStep(steps.length - 1);
      } else {
        setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
      }
    }
  };

  const backStep = () => {
    setDirection(-1);
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const returnPositionResultPage = () => {
    navigate(`/cna/my-positions/${id}`, {
      state: { reFetch: true },
    });
  };
  const returnAnalysisMainPage = () => {
    navigate(`/jobposition/${id}/home`, {
      state: { previousLocation: location.state.previousLocation },
    });
  };

  const skipStep = async (field) => {
    const params = { id: id };
    const payload = { core_cv: {} };
    payload.core_cv[field] = [];
    await postJobPosition(
      params,
      payload,
      (res) => {
        setJobPosData(res.data);
        reset(res.data.core_cv);
        nextStep();
      },
      (err) => console.log(err),
      (bool) => setIsSkipping(bool),
    );
  };
  const handleFormSubmit = async (formData) => {
    const dirtyData = Object.keys(formData)
      .filter((key) => dirtyFields[key])
      .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});

    const params = { id: id };
    const payload = { core_cv: { ...dirtyData } };
    await postJobPosition(
      params,
      payload,
      (res) => {
        setJobPosData(res.data);
        reset(res.data.core_cv);
        nextStep();
      },
      (err) => console.log(err),
      // (bool) => console.log(bool),
    );
  };
  const handleCloseInfoBox = () => {
    setUnderstood(true);
    localStorage.setItem("ccvUnderstood", true);
  };

  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? 500 : -500,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction === 1 ? -500 : 500,
      opacity: 0,
    }),
  };

  const stepToJump = location.state.jumpStep && steps.find((step) => step.field.name === location.state.jumpStep);
  useEffect(() => {
    if (stepToJump) {
      setCurrentStep(stepToJump.id - 1);
    }
  }, [location.state.jumpStep]);
  const CurrentComponent = steps[currentStep].component;

  const savedStepIndex = steps.findIndex((step) => step.field.name === jobPosition.core_cv.state);
  useEffect(() => {
    if (savedStepIndex >= 0) {
      setCurrentStep(savedStepIndex);
    }
  }, [savedStepIndex, jobPosition.core_cv.state]);

  return (
    <>
      {!understood && (
        <div className="absolute -left-0 -top-0 z-50 h-[650px] w-[1020px] bg-neutral-1000 bg-opacity-80 max-lg:h-full max-lg:w-full">
          <motion.img
            src={eugeneIllustration}
            alt=""
            className="absolute bottom-0"
            initial={{ opacity: 0, y: 50 }} // Initial state: hidden and slightly below
            animate={{ opacity: 1, y: 0 }} // Animate to: fully visible and in original position
            transition={{ duration: 1, ease: "easeOut" }} // Animation duration and easing
          />
          <motion.div
            className="absolute bottom-0"
            initial={isMobile ? { opacity: 0, x: -100, y: -70, scale: 0.5 } : { opacity: 0, y: 20, scale: 0 }} // Initial state: hidden and slightly below
            animate={isMobile ? { opacity: 1, x: -175, y: -100, scale: 1 } : { opacity: 1, y: 0, scale: 1 }} // Animate to: fully visible and in original position
            transition={{ duration: 1, ease: "easeOut" }} // Animation duration and easing
          >
            <GradientBorder
              gradientStart="#106BAC"
              gradientEnd="#6FB6FF"
              className="absolute bottom-[200px] left-[200px] w-[343px] bg-neutral-1000 p-4"
            >
              <p className="pb-2 text-[15px] font-bold leading-tight text-primary-600">Core CV nedir?</p>
              <p className="pb-2 text-sm leading-tight text-neutral-100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate urna vel convallis
              </p>
              <Button
                onClick={handleCloseInfoBox}
                className="border-neutral-100 bg-transparent p-2.5 text-[18px] font-bold leading-tight text-neutral-100"
              >
                Anladım
              </Button>
            </GradientBorder>
          </motion.div>
        </div>
      )}
      <div className="px-20 pb-4 max-lg:px-6">
        <AnalyseProgress percent={progressPercent} />
        {/* <HorizontalStepper steps={steps} currentStep={currentStep} /> */}
      </div>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        {currentStep === steps.length - 1 ? (
          <div className="flex h-[504px] w-full flex-col gap-4 overflow-y-auto px-9 pb-6 max-lg:px-4 max-lg:py-6">
            <SelectImportances jobPosition={jobPosition} formInstance={formInstance} />
            <div className="absolute left-1/2 top-9 flex -translate-x-1/2 items-center justify-between gap-4">
              <Button className={"border-transparent bg-transparent text-neutral-100"} onClick={backStep} type="button">
                Geri
              </Button>
              <Button
                className={`border-none bg-neutral-100 px-12 text-primary-900`}
                type="submit"
                loading={isSubmitting}
              >
                Kaydet
              </Button>
            </div>
          </div>
        ) : (
          <GradientBorder className="mx-auto h-[382px] w-[650px] bg-neutral-100 bg-opacity-20 p-8 backdrop-blur max-lg:h-[450px] max-lg:w-[342px] max-lg:px-4 max-lg:py-6">
            <Button
              onClick={() => skipStep(steps[currentStep].field.name)}
              disabled={steps[currentStep].canSubmit}
              loading={isSkipping}
              type="button"
              className="absolute right-0 z-20 border-none bg-transparent text-neutral-100"
            >
              Atla
            </Button>
            <div className="max-lg:[calc(100%-46px)] relative z-10 flex h-[calc(100%-54px)] w-full items-center justify-center">
              <AnimatePresence initial={false} custom={direction}>
                <motion.div
                  key={currentStep}
                  custom={direction}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                  style={{ position: "absolute", width: "100%" }}
                >
                  <p className="pb-4 text-center text-sm font-semibold leading-tight text-primary-200">
                    Soru {currentStep + 1}/{steps.length}
                  </p>
                  <p className="pb-6 text-center font-semibold leading-tight text-neutral-100 max-lg:text-sm">
                    {steps[currentStep].text}
                    {steps[currentStep].warningText && (
                      <span className="text-sm font-light">
                        <br />({steps[currentStep].warningText})
                      </span>
                    )}
                  </p>
                  <CurrentComponent field={steps[currentStep].field} formInstance={formInstance} />
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="flex items-center justify-between gap-4">
              {currentStep > 0 && (
                <Button
                  className={"border-transparent bg-transparent text-neutral-100"}
                  onClick={backStep}
                  type="button"
                >
                  Geri
                </Button>
              )}
              <Button
                className={`${currentStep === 0 && "mx-auto"} border-none bg-neutral-100 px-12 text-primary-900`}
                type="submit"
                disabled={!steps[currentStep].canSubmit}
                loading={isSubmitting}
              >
                Kaydet
              </Button>
            </div>
          </GradientBorder>
        )}
      </Form>
    </>
  );
};

export default CorecvForm;
