import React from "react";
import { twMerge } from "tailwind-merge";

const AnalyseProgress = ({ percent, ...props }) => {
  const rate = percent < 100 ? percent : percent >= 100 ? 100 : 12;
  const classes =
    "relative h-[12px] rounded-full bg-[linear-gradient(247deg,#82B7EA_2.82%,#7986E8_35.94%,#36099F_98.96%)] text-center text-[8px] font-semibold leading-[12px] text-primary-100 transition-all";
  const mergedClasses = twMerge(classes, props.className);
  return (
    <>
      <div className="h-[12px] w-full rounded-full bg-neutral-100 bg-opacity-50">
        <div className={mergedClasses} style={{ width: `${rate}%` }}>
          <div className="absolute -right-0 -top-8 translate-x-1/2 rounded-md bg-neutral-100 p-1 text-[13px] font-semibold leading-tight text-secondary_recruiter-700 max-lg:hidden">
            %{rate}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyseProgress;
