import React, { useEffect } from "react";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import Button from "../../../components/NewDesign/Button/Button";
import {
  PeopleFilled,
  PlusOutlined,
  RubbishOutlined,
} from "../../../components/OldDesign/Icons";
import { Form } from "../../../components/NewDesign/Forms/Form";
import { Field } from "../../../components/NewDesign/Forms/Field";
import {
  ASelect,
  Input,
  Phone,
  SSelect,
  TextArea,
} from "../../../components/NewDesign/Forms/Input";
import { useStore } from "../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import {
  getCityOptions,
  getCountryOptions,
  getSectorOptions,
  getStateOptions,
} from "../../../api/endpoints";

const CompanyProfileSettings = () => {
  const settings = useStore((state) => state.settings);

  const users = settings.data.company_account.user_list;

  const {
    handleSubmit,
    getValues,
    register,
    setError,
    reset,
    resetField,
    watch,
    trigger,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      company_sector: settings.data.company_profile?.sector_name.value,
      employee_count: settings.data.company_profile?.employee_count_title.value,
      company_bio: settings.data.company_profile?.company_bio.value,
      //   company_phone: settings.data.company_profile?.company_phone.value,
      company_email: settings.data.company_profile?.company_mail.value,
      company_type: settings.data.company_profile?.company_type.value,
      tax_number: settings.data.company_profile?.tax_number.value,
      tax_office: settings.data.company_profile?.tax_office.value,
      // company_adress: settings.data.company_profile?.company_adress.value,
      company_linkedin: settings.data.company_profile?.socials.linkedin.value,
      // company_website: settings.data.company_profile?.tax_office.value,
    },

    mode: "onSubmit",
  });
  console.log("settings", settings);
  //  LOG FORM VALUES
  console.log(getValues());

  const loadCountryOptions = async (value = "A") => {
    let countryOptions;
    await getCountryOptions(
      {
        rows: 200,
        page: 1,
        keyword: value,
      },
      (res) => {
        countryOptions = res.data.rows;
      },
      (err) => console.log(err),
      (bool) => {
        console.log(bool);
      },
    );
    return countryOptions;
  };
  const loadStateOptions = async (value = "A") => {
    let stateOptions;
    await getStateOptions(
      {
        rows: 200,
        page: 1,
        keyword: value,
        country_id: getValues("company_country").id,
      },
      (res) => {
        stateOptions = res.data.rows;
      },
      (err) => console.log(err),
      (bool) => {
        console.log(bool);
      },
    );
    return stateOptions;
  };
  const loadCityOptions = async (value = "A") => {
    let cityOptions;
    await getCityOptions(
      {
        rows: 200,
        page: 1,
        keyword: value,
        country_id: getValues("company_country").id,
        state_id: getValues("company_state").id,
      },
      (res) => {
        cityOptions = res.data.rows;
      },
      (err) => console.log(err),
      (bool) => {
        console.log(bool);
      },
    );
    return cityOptions;
  };
  const loadSectorOptions = async (value = "A") => {
    let sectorOptions;
    await getSectorOptions(
      { rows: 200, page: 1, keyword: value },
      (res) => {
        sectorOptions = res.data.rows;
      },
      (err) => console.log(err),
      (bool) => console.log(bool),
    );
    return sectorOptions;
  };

  useEffect(() => {
    reset({
      //   company_sector: settings.data.company_profile?.sector_name.value,
      //   employee_count: settings.data.company_profile?.employee_count.value,
      company_bio: settings.data.company_profile?.company_bio.value,
      //   company_phone: settings.data.company_profile?.company_phone.value,
      company_email: settings.data.company_profile?.company_mail.value,
      company_type: settings.data.company_profile?.company_type.value,
      tax_number: settings.data.company_profile?.tax_number.value,
      tax_office: settings.data.company_profile?.tax_office.value,
      // company_adress: settings.data.company_profile?.company_adress.value,
      company_linkedin: settings.data.company_profile?.socials.linkedin.value,
      // company_website:settings.data.company_profile?.tax_office.value,
    });
  }, [settings, reset]);
  return (
    <div className="flex flex-col gap-6">
      <div className="rounded-xl bg-neutral-100 p-6">
        <div className="mb-6 flex basis-full items-center justify-between border-b pb-2">
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            Acount Details
          </h5>
          <TagWithIcon
            text={
              settings.data.company_account.subscription_details
                .is_subscribed_name
            }
            className={"bg-neutral-300 text-neutral-700"}
          />
        </div>
        <div className="flex flex-row items-center justify-between gap-3 max-lg:flex-col">
          <h5 className="text-[18px] font-semibold leading-tight text-primary-800">
            {settings.data.company_account.subscription_details.plan_title}
          </h5>
          <Button className={"bg-neutral-100 px-6 py-3 text-primary-600"}>
            {
              settings.data.company_account.subscription_details
                .plan_button_title
            }
          </Button>
        </div>
        <div className="flex flex-row justify-between gap-3 border-b py-4 max-lg:flex-col">
          {settings.data.company_account.subscription_details.product_list.map(
            (stat, index) => (
              <StatCard key={index} stat={stat} />
            ),
          )}
        </div>
        <div className="flex flex-row items-center justify-between gap-3 pt-4 max-lg:flex-col">
          <div className="flex flex-row gap-5">
            <div className="border-r pr-5">
              <h6 className="font-semibold leading-tight text-neutral-800">
                {
                  settings.data.company_account.subscription_details
                    .last_billing_amount_title
                }
              </h6>
              <p className="text-[15px] text-neutral-600">
                {
                  settings.data.company_account.subscription_details
                    .last_billing_amount
                }
              </p>
            </div>
            <div>
              <h6 className="font-semibold leading-tight text-neutral-800">
                {
                  settings.data.company_account.subscription_details
                    .subscription_start_date_title
                }
              </h6>
              <p className="text-[15px] text-neutral-600">
                {settings.data.company_account.subscription_details.subscription_start_date
                  .slice(0, 10)
                  .replaceAll("-", ".")}
              </p>
            </div>
            <div>
              <h6 className="font-semibold leading-tight text-neutral-800">
                {
                  settings.data.company_account.subscription_details
                    .subscription_end_date_title
                }
              </h6>
              <p className="text-[15px] text-neutral-600">
                {settings.data.company_account.subscription_details.subscription_end_date
                  .slice(0, 10)
                  .replaceAll("-", ".")}
              </p>
            </div>
          </div>
          <Button className={"bg-neutral-100 px-6 py-3 text-primary-600"}>
            {
              settings.data.company_account.subscription_details
                .last_billing_amount_button_title
            }
          </Button>
        </div>
      </div>
      <div className="rounded-xl bg-neutral-100 p-6">
        <div className="mb-6 flex items-center gap-2 border-b pb-2">
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            Company User
          </h5>
          <TagWithIcon
            icon={<PeopleFilled className={"h-4 w-4 text-primary-400"} />}
            text={`${users.length}/4`}
            className={"bg-primary-75 text-primary-400"}
          />
        </div>
        <div>
          <div className="mb-3 flex flex-row rounded-xl bg-neutral-200 px-4 py-3 max-lg:hidden">
            <div className="basis-4/12 text-[13px] font-medium text-primary-900">
              Full Name
            </div>
            <div className="basis-2/12 text-center text-[13px] font-medium text-primary-900">
              Date Added
            </div>
            <div className="basis-2/12 text-center text-[13px] font-medium text-primary-900">
              Status
            </div>
            <div className="basis-2/12 text-center text-[13px] font-medium text-primary-900">
              Authority
            </div>
            <div className="basis-2/12 text-center text-[13px] font-medium text-primary-900">
              Actions
            </div>
          </div>

          {users.length > 0 ? (
            <div className="flex flex-col gap-3">
              {users.map((user, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center rounded-xl border p-4 max-lg:relative max-lg:flex-col max-lg:items-start"
                >
                  <div className="basis-4/12">
                    <p className="font-medium leading-tight text-primary-800">
                      {user.name}
                    </p>
                    <p className="text-[14px] leading-tight text-neutral-700">
                      {user.email}
                    </p>
                  </div>
                  <div className="basis-2/12 text-center">
                    <p className="font-medium leading-tight text-primary-800">
                      {user.invite_date.slice(0, 10).replaceAll("-", ".")}
                    </p>
                  </div>
                  <div className="basis-2/12 text-center">
                    <TagWithIcon
                      text={user.invite_status}
                      className={`${
                        user.status === "confirmed"
                          ? "bg-success-100 text-success-400"
                          : user.status === "invited"
                          ? "bg-primary-75 text-primary-600"
                          : user.status === "rejected"
                          ? "bg-error-200 text-error-500"
                          : user.status === "cancelled"
                          ? "bg-secondary_recruiter-300 text-secondary_recruiter-700"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="basis-2/12 text-center">
                    <p>{user.authority}</p>
                  </div>
                  <div className="basis-2/12 text-center max-lg:absolute max-lg:right-1 max-lg:top-1">
                    <button
                      type="button"
                      className="rounded-lg bg-card_candidate-100 p-2.5"
                    >
                      <RubbishOutlined className="h-[22px] w-[22px] text-card_candidate-400 max-lg:h-4 max-lg:w-4" />
                    </button>
                  </div>
                </div>
              ))}
              {users.length < 4 && (
                <div className="flex items-center justify-center border border-dashed p-3">
                  <button className="flex gap-1">
                    <PlusOutlined
                      className={
                        "h-6 w-6 rounded-sm bg-neutral-200 p-1 text-neutral-800"
                      }
                    />
                    <span className="text-[15px] font-medium text-neutral-800">
                      Add User
                    </span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="rounded-xl bg-neutral-100 p-6">
        <div className="mb-6 flex items-center justify-between gap-2 border-b pb-2">
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            Company Profile
          </h5>
          <Button className={"bg-neutral-100 px-6 py-3 text-primary-600"}>
            Save
          </Button>
        </div>
        <Form className="grid grid-cols-2 gap-4 max-lg:grid-cols-1">
          <Field label={settings.data.company_profile.sector_name.title}>
            <Controller
              control={control}
              name="company_sector"
              id="company_sector"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ASelect
                  name="company_sector"
                  {...register("company_sector", { required: "Required" })}
                  id="company_sector"
                  loadOptions={loadSectorOptions}
                  menuShouldScrollIntoView
                  defaultOptions
                  cacheOptions
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select..."
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                />
              )}
            />
          </Field>
          <Field
            label={settings.data.company_profile.employee_count_title.title}
          >
            <Controller
              control={control}
              name="employee_count"
              id="employee_count"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SSelect
                  ref={ref}
                  //   options={}
                  //   getOptionLabel={(opt) => opt.job_experience_title}
                  //   getOptionValue={(opt) => opt.job_experience_id}
                  onChange={onChange}
                  menuShouldScrollIntoView
                  placeholder={"Select..."}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Field>
          <Field
            label={settings.data.company_profile.company_bio.title}
            className={"lg:col-span-2"}
          >
            <TextArea
              rows={3}
              {...register("company_bio", { required: "Required" })}
              name="company_bio"
              id="company_bio"
            />
          </Field>
          <Field label={settings.data.company_profile.company_phone.title}>
            <Controller
              control={control}
              name="company_phone"
              id="company_phone"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Phone
                  defaultCountry="tr"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  //   ref={ref}
                  forceDialCode
                />
              )}
            />
          </Field>
          <Field label={settings.data.company_profile.company_mail.title}>
            <Input
              {...register("company_email", { required: "Required" })}
              name="company_email"
              id="company_email"
            />
          </Field>
          <Field label={settings.data.company_profile.company_type.title}>
            <Input
              {...register("company_type", { required: "Required" })}
              name="company_type"
              id="company_type"
            />
          </Field>
          <Field label={settings.data.company_profile.tax_number.title}>
            <Input
              {...register("tax_number", { required: "Required" })}
              name="tax_number"
              id="tax_number"
            />
          </Field>
          <Field label={settings.data.company_profile.tax_office.title}>
            <Input
              {...register("tax_office", { required: "Required" })}
              name="tax_office"
              id="tax_office"
            />
          </Field>
          <Field
            label={settings.data.company_profile.location_country_name.title}
          >
            <Controller
              control={control}
              name="company_country"
              id="company_country"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ASelect
                  loadOptions={loadCountryOptions}
                  menuShouldScrollIntoView
                  defaultOptions
                  cacheOptions
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select..."
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                />
              )}
            />
          </Field>
          <Field
            label={settings.data.company_profile.location_state_name.title}
          >
            <Controller
              control={control}
              name="company_state"
              id="company_state"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ASelect
                  isDisabled={!watch("company_country")}
                  loadOptions={loadStateOptions}
                  menuShouldScrollIntoView
                  defaultOptions
                  cacheOptions
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select..."
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                />
              )}
            />
          </Field>
          <Field label={settings.data.company_profile.location_city_name.title}>
            <Controller
              control={control}
              name="company_town"
              id="company_town"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ASelect
                  isDisabled={!watch("company_state")}
                  loadOptions={loadCityOptions}
                  menuShouldScrollIntoView
                  defaultOptions
                  cacheOptions
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  placeholder="Select..."
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                />
              )}
            />
          </Field>
          <Field label={"Adres"} className={"lg:col-span-2"}>
            <Input
              {...register("company_adress", { required: "Required" })}
              name="company_adress"
              id="company_adress"
            />
          </Field>
          <Field label={settings.data.company_profile.socials.linkedin.title}>
            <Input
              {...register("company_linkedin", { required: "Required" })}
              name="company_linkedin"
              id="company_linkedin"
              placeholder=""
            />
          </Field>
          <Field label={"Web Site"}>
            <Input
              {...register("company_website", { required: "Required" })}
              name="company_website"
              id="company_website"
              placeholder=""
            />
          </Field>
        </Form>
      </div>
      {/* <div className="rounded-xl bg-neutral-100 p-6">
        <div className="mb-6 flex basis-full items-center justify-between border-b pb-2">
          <h5 className="text-[20px] font-semibold leading-tight text-primary-600">
            Social Media
          </h5>
          <Button
            type="submit"
            className="bg-neutral-100 px-6 py-3 text-primary-600"
          >
            Kaydet
          </Button>
        </div>
        <Form className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
          <Field label={"Linkedin"}>
            <Input id="linkedin" placeholder="" />
          </Field>
          <Field label={"Web Site"}>
            <Input id="website" placeholder="" />
          </Field>
        </Form>
      </div> */}
    </div>
  );
};

const StatCard = ({ stat }) => {
  return (
    <div className="flex h-[100px] w-[224px] flex-col justify-between gap-2 rounded-xl bg-primary-100 p-3 text-primary-900 max-lg:w-full max-lg:flex-row">
      <div className="flex flex-row items-center gap-2 max-lg:gap-1">
        <img src={stat.icon} alt="" className="h-[42px] w-[42px]" />

        <p className="mr-auto overflow-hidden text-ellipsis whitespace-nowrap text-[18px] font-medium leading-tight max-lg:text-[13px] max-lg:font-medium">
          {stat.title}
        </p>
        {/* <Tooltip content={`${stat.title}`}>
          <InfoOutlined className="h-5 w-5 text-primary-400 max-lg:h-4 max-lg:w-4" />
        </Tooltip> */}
      </div>
      <div className="flex flex-row items-center max-lg:gap-2">
        <p className="text-[22px] font-bold leading-tight max-lg:text-[18px] ">
          {stat.text}
        </p>
        {/* <Link
          className={` relative ml-auto`}
          to="/mynetwork/connections"
          // state={{ stateSelectedGroupFilter: stat.title.toLowerCase() }}
        >
          <RightArrowOutlined className="h-6 w-6 max-lg:h-[13px] max-lg:w-[13px]" />
        </Link> */}
      </div>
    </div>
  );
};

export default CompanyProfileSettings;
