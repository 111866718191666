import React from "react";
import { twMerge } from "tailwind-merge";

export const DrawnHeader1 = ({ brushClassName, className, children }) => {
  const classes =
    "relative inline-block text-[32px] font-semibold leading-tight text-secondary_recruiter-800";
  const mergedClasses = twMerge(classes, className);
  const brushClasses = "absolute left-1/2 w-[250px] -translate-x-1/2";
  const mergedBrushClasses = twMerge(brushClasses, brushClassName);
  return (
    <h1 className={mergedClasses}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className={mergedBrushClasses}
        viewBox="0 0 252 45"
      >
        <g fill="#9C27B0" clipPath="url(#a)" opacity=".25">
          <path
            d="M46.483 19.045c-6.417-1.066-44.456 5.483-45.44 5.463-2.313-.244-.295-10.79 1.584-12.598.66-.635 1.496-.85 3.494-.89 0 0 25.58-3.479 43.255-5.648.561.254 2.057-.929 2.392-.577.255.205 4.517-.107 10.875-.606C86.41 2.303 124.784-1.098 180.43 2.06c2.263.127 25.313 1.495 27.892 1.114.767-.118 1.348 0 1.318.224 1.555.225 8.75.9 16.239 2.307.079.02 3.032.606 3.061.684.108.244.669.46 1.25.43.984-.049 10.57 1.447 18.621 4.867 1.948.831 3.198 1.525 3.218 1.7.069 1.066-1.476 3.08-3.317 4.966-7.46 7.643-6.003 3.186-21.474.117-4.892-.968-44.82-5.14-66.462-4.29-1.162-.704-5.797.772-8.543-.46-1.152-.518-9.438-.762-11.279-.274-.984.264-42.497-.518-48.254 1.545-8.622-.997-37.537 2.863-46.198 4.075l-.02-.02Z"
            opacity=".7"
          />
          <path
            d="M205.969 6.457c3.543 1.183 34.191 1.476 42.36 5.375 1.013.606-.266 3.02-1.91 5.454-5.206 7.74-4.812 5.541-9.615 4.593-2.402-.478-1.467-.303-4.409-.713-9.006-1.28-21.337-1.486-30.461-1.486-.462-.322-1.87.724-2.126.333-.393-.48-14.88-.557-29.948.058-15.058.616-30.638 1.78-33.079 1.936-2.972.185-.649-.538-4.143-.098-7.421.928-23.699 1.544-29.299 2.55-4.478.812-5.453.87-9.822.87-4.97 0-27.833 2.512-30.589 3.568-.64.244-1.151.234-1.17.01-.67.029-4.942.39-8.317.674-.118 0-8.317.713-8.376.616-.147-.215-.669-.264-1.122-.059-.846.293-18.364 3.088-18.729 2.482-1.063-1.778.443-9.959 2.254-12.148 1.9-2.297 13.71-1.27 22.892-3.45 55.242-5.756 32.764-2.345 52.654-5.57 1.112.595 5.088-1.379 7.667-.382 1.082.42 8.483-.068 10.098-.645 1.929-.694 39.977-1.671 43.284-2.942 6.003.772 31.73-1.006 41.897-1.026h.009Z"
            opacity=".7"
          />
          <path
            d="M64.67 31.604c-4.536-1.476-35.696.664-37.457.42-1.85-.518.61-10.712 2.352-12.315.59-.547 1.299-.684 2.923-.459 1.398.196 10.59-.137 35.48-.977 0 0 55.144-.46 60.625-.41 2.756.019.561.586 3.819.41 6.929-.381 22.006.557 27.272.205 4.222-.283 5.127-.254 9.153.274 2.883.38 26.592 1.397 28.669.948.63-.137 1.092-.04 1.082.195.335.049 15.541 1.77 15.619 2.092.089.234.552.4 1.014.312 1.024-.195 11.121.958 15.462 2.356 1.791.576 2.952 1.055 2.972 1.231.148 1.71-3.947 6.988-5.01 8.269-2.352 2.824-2.204 2.707-9.438 1.114-10.048-2.219-10.088-1.251-14.28-1.73-.985-.108-7.628-.802-17.972-1.476-21.228-1.388-20.717-.997-28.374-.616-.954-.713-4.743.811-6.997-.43-.935-.518-7.706-.762-9.232-.312-1.033.303-35.755-1.31-39.593.127-6.83-1.27-33.728.547-38.069.781l-.02-.01Z"
            opacity=".7"
          />
          <path
            d="M198.914 23.902c3.198 1.388 30.312.988 32.487 1.574 1.555.655-1.496 10.663-3.021 12.09-.984.918-.817.117-8.11-.166-8.05-.313-18.187-.333-24.545-.255-.364-.322-1.486.714-1.693.333-.157-.225-3.11-.401-7.519-.47-15.491-.234-27.98.333-42.359.92-2.343.097-.502-.558-3.268-.245-5.866.664-18.709.655-23.128 1.466-3.533.655-4.301.645-7.746.508-4.409-.176-22.124 1.583-24.122 2.492-.502.235-.905.196-.925-.029-.108 0-13.12.83-13.169.743-.118-.215-.53-.284-.885-.098-.67.254-14.458 2.522-14.763 1.886-.876-1.808.138-9.92 1.535-12.04 1.477-2.229 11.082-.87 18.129-2.747 43.294-3.733 26.681-1.358 41.759-3.724.876.626 4.035-1.202 6.082-.097.847.46 6.722.264 8.002-.284 1.456-.615 31.316-.166 34.269-1.446 3.956.84 17.223.186 32.97-.42l.02.01Z"
            opacity=".7"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 .769h252v44H0z" />
          </clipPath>
        </defs>
      </svg>
      {children}
    </h1>
  );
};
export const DrawnHeader2 = ({ firstline, secondline }) => {
  return (
    <h1 className="text-[32px] font-semibold leading-tight text-secondary_recruiter-800">
      {firstline}
      <br />
      {secondline}
    </h1>
  );
};
