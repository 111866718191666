import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../Forms/Form";
import { Field } from "../Forms/Field";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { LANGUAGE_PROFICIENCY } from "../constants";
import { CloseOutlined } from "../../OldDesign/Icons";
import { REACT_BASE_URL } from "../../../api/constants";

const JobDescriptionForm = () => {
  const coreCV = useStore((state) => state.coreCV);
  const [showLanguageLevel, setShowLanguageLevel] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(
    coreCV.data.languages_array,
  );
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  const updateApplicationForm = useStore(
    (state) => state.updateApplicationForm,
  );
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { language: "", language_proficiency: "" },
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const submitLanguageLevelForm = () => {
    const languages_array = selectedLanguages.map((lang) => ({
      language_id: lang.language_id,
      language_proficiency: lang.language_proficiency,
    }));

    // console.log({ languages_array });
    updateApplicationForm({ languages_array }, () =>
      navigate("../confirmation"),
    );
  };

  const handleFormNavigateBack = () => {
    navigate("../step4");
  };

  const loadLanguageOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/language?rows=50&page=1&keyword=${value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const checkAndInsertData = (value) => {
    const selected_language = getValues("language");
    const selected_language_proficiency = getValues("language_proficiency");
    if (selected_language && selected_language_proficiency) {
      setSelectedLanguages((prev) => [
        ...prev,
        {
          language_name: selected_language.language_name,
          language_id: selected_language.language_id,
          language_proficiency: selected_language_proficiency.value,
        },
      ]);
      reset();
      setShowLanguageLevel(false);
    }
  };
  return (
    <section className="h-[520px]">
      <SectionTitle
        className="items-start"
        title={content["egitim detaylari baslik"]}
      />
      <Form id="user_profile" onSubmit={handleSubmit(submitLanguageLevelForm)}>
        <div className="flex gap-8 py-6">
          <fieldset className="flex basis-1/2 flex-col ">
            <Field
              label={content["dil bilgisi baslik"]}
              error={errors?.language}
            >
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    onChange={(e) => {
                      if (e !== null) {
                        onChange(e);
                        setShowLanguageLevel(true);
                      } else {
                        onChange("");
                        setShowLanguageLevel(false);
                      }
                    }}
                    value={value}
                    // selected={value}
                    loadOptions={loadLanguageOptions}
                    placeholder="Seçiniz"
                    isClearable
                    defaultOptions
                    getOptionLabel={(opt) => opt.language_name}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="flex basis-1/2 flex-col ">
            {showLanguageLevel && (
              <Field
                label={content["dil seviyesi baslik"]}
                error={errors?.language_proficiency}
              >
                <Controller
                  control={control}
                  name="language_proficiency"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                        checkAndInsertData(e);
                      }}
                      onBlur={onBlur}
                      selected={value}
                      options={LANGUAGE_PROFICIENCY}
                      placeholder="Seçiniz"
                      isSearchable
                      getOptionLabel={(opt) => opt.label}
                      getOptionValue={(opt) => opt.value}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "50px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
            )}
          </fieldset>
        </div>
        <div className="flex flex-wrap justify-start gap-1 pb-6">
          {selectedLanguages.map((selected, index) => (
            <div
              className="flex items-center gap-1 rounded-full bg-primary-500 px-4 py-2 text-[14px] leading-tight text-primary-50"
              key={index}
            >
              {selected.language_name}{" "}
              {
                LANGUAGE_PROFICIENCY.find(
                  (prof) => prof.value === selected.language_proficiency,
                ).label
              }
              <button
                type="button"
                onClick={() =>
                  setSelectedLanguages((prev) =>
                    prev.filter((a, i) => index !== i),
                  )
                }
              >
                <CloseOutlined className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>

        <div className="flex basis-full justify-between">
          <Button
            className="bg-neutral-100 px-12 py-4 text-primary-600"
            onClick={handleFormNavigateBack}
          >
            {content["geri buton"]}
          </Button>
          <Button type="submit" className="px-12 py-4">
            {content["gonder buton"]}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default JobDescriptionForm;
