import React, { useEffect } from "react";
import { Form } from "../Forms/Form";
import SectionTitle from "../SectionTitle";
import { Field } from "../Forms/Field";
import { AutocompleteInput, Input, TextArea } from "../Forms/Input";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../store/useStore";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Button from "../Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import AsyncSelect from "react-select/async";
import { REACT_BASE_URL } from "../../../api/constants";
import { addYears, subYears } from "date-fns";

const UserProfileForm = () => {
  const coreCV = useStore((state) => state.coreCV);
  const updateApplicationForm = useStore(
    (state) => state.updateApplicationForm,
  );
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_name: coreCV.data.user_name || "",
      user_phone: coreCV.data.phone || "",
      location_country: coreCV.data.location_country.country_id
        ? {
            id: coreCV.data.location_country.country_id,
            name: coreCV.data.location_country.country_name,
          }
        : "",
      user_email: coreCV.data.email || "",
      birth_date: coreCV.data.birth_date || "",
      location_state: coreCV.data.location_state.state_id
        ? {
            id: coreCV.data.location_state.state_id,
            name: coreCV.data.location_state.state_name,
          }
        : "",
      user_bio: coreCV.data.user_bio || "",
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const loadCountryOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/country?page=1&rows=20&keyword=${value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadCityOptions = async (value) => {
    const selectedCountry = getValues("location_country");
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/state?page=1&rows=60&keyword=${value}&country_id=${selectedCountry.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const submitUserProfileForm = (formData) => {
    let birth_date = null;
    if (formData.birth_date.length === 10) {
      birth_date = formData.birth_date;
    } else {
      let day = formData.birth_date.getDate();
      // console.log(day); // 23
      if (day < 10) {
        day = "0" + day;
      }
      let month = formData.birth_date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      // console.log(month + 1); // 8

      let year = formData.birth_date.getFullYear();

      // console.log(year); // 2022
      birth_date = year + "-" + month + "-" + day;
    }
    const location_country = {
      country_id: formData.location_country.id,
      country_name: formData.location_country.name,
    };
    const location_state = {
      state_id: formData.location_state.id,
      state_name: formData.location_state.name,
    };
    const payload = {
      ...formData,
      birth_date: birth_date,
      location_country: location_country,
      location_state: location_state,
    };

    // if (coreCV.data.email) {
    //   delete payload.user_email;
    // }
    // console.log(formData);
    updateApplicationForm(payload, () => navigate("../step2"));
  };

  if (!coreCV.isLoaded) {
    return null;
  }

  return (
    <section className="h-[670px]">
      <SectionTitle
        className="items-start"
        title={content["kullanici profili baslik"]}
      />
      <Form id="user_profile" onSubmit={handleSubmit(submitUserProfileForm)}>
        <div className="grid grid-cols-2 gap-8 py-6">
          <fieldset className="flex basis-1/2 flex-col gap-6">
            <Field label={content["ad soyad baslik"]} error={errors?.user_name}>
              <Input
                {...register("user_name", { required: "Required" })}
                id="user_name"
                placeholder="Yazınız"
              />
            </Field>
            <Field
              label={content["telefon numarasi baslik"]}
              error={errors?.user_phone}
            >
              <Controller
                control={control}
                name="user_phone"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    defaultCountry="tr"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disableFormatting
                    forceDialCode
                    inputClassName="border"
                    className="rounded-lg outline outline-1 outline-neutral-300 transition-all hover:outline-primary-600 focus:outline-primary-600"
                    inputProps={{
                      className:
                        "pl-3 h-12 bg-neutral-100 text-[13px] lg:h-[52px] lg:text-[16px] outline-none",
                    }}
                    dialCodePreviewStyleProps={{
                      className:
                        "bg-neutral-200 border-none pl-1 pr-3 text-[16px]",
                    }}
                    countrySelectorStyleProps={{
                      className: "bg-neutral-200 rounded-l-lg",
                      buttonClassName:
                        "border-none h-full rounded-l-lg hover:rounded-l-lg pl-3",
                    }}
                  />
                )}
              />
            </Field>
            <Field
              label={content["yasadiginiz ulke baslik"]}
              error={errors?.location_country}
            >
              <Controller
                control={control}
                name="location_country"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    defaultOptions
                    isClearable
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    loadOptions={loadCountryOptions}
                    placeholder={"Seçiniz"}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      placeholder: (styles, state) => ({
                        ...styles,
                        color: "#9A9ABA",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="flex basis-1/2 flex-col gap-6">
            <Field
              label={content["eposta adresi baslik"]}
              error={errors?.user_email}
            >
              <Input
                // disabled={coreCV.data.email}
                // readOnly={coreCV.data.email}
                {...register("user_email", {
                  required: "Required",
                })}
                id="user_email"
                placeholder="Yazınız"
              />
            </Field>
            <Field
              label={content["dogum tarihi baslik"]}
              error={errors?.birth_date}
            >
              <Controller
                control={control}
                id="birth_date"
                name="birth_date"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    selected={Date.parse(value)}
                    onBlur={onBlur}
                    value={value}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    minDate={subYears(new Date(), 100)}
                    placeholderText="Seçiniz"
                    showYearDropdown
                    // showMonthYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    showMonthDropdown
                    className="h-[52px] w-full rounded-lg border-[1px] border-neutral-300 p-4 focus:outline-none"
                  />
                )}
              />
            </Field>
            <Field
              label={content["yasadiginiz sehir baslik"]}
              error={errors?.location_state}
            >
              <Controller
                control={control}
                name="location_state"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    isClearable
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    loadOptions={loadCityOptions}
                    placeholder={"Seçiniz"}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      placeholder: (styles, state) => ({
                        ...styles,
                        color: "#9A9ABA",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="col-span-2 flex flex-col gap-4">
            <Field label={"Hakkınızda"} error={errors?.user_bio}>
              <TextArea
                rows={4}
                placeholder={
                  "Lütfen kendinizi birkaç cümle ile açıklayınız. (Max 510)"
                }
                maxLength={510}
                {...register("user_bio", {
                  required: "Required (max 510)",
                  maxLength: 510,
                })}
              />
            </Field>
          </fieldset>
        </div>
        <div className="flex basis-full justify-end">
          <Button type="submit" className="px-12 py-4">
            {content["gonder buton"]}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default UserProfileForm;
