import React, { useState } from "react";
import SectionTitle from "../../components/NewDesign/SectionTitle";
import {
  ChevronDownOutlined,
  EditFilled,
} from "../../components/OldDesign/Icons";
import { Link } from "react-router-dom";
import IsMobile from "../../utils/IsMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ListItems = ({ item, edu }) => {
  return (
    <li className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
      <p
        style={{ wordBreak: "break-word" }}
        className="text-[14px] font-medium leading-tight text-primary-900"
      >
        &#x2022; {item}
      </p>
      {edu && (
        <div className="flex flex-1 justify-between max-lg:w-full">
          <p className="text-[14px] font-medium leading-tight text-neutral-700">
            {edu.department}
          </p>
          <p className="text-[14px] font-medium leading-tight text-neutral-700">
            {edu.start_date.replace("-", "/") +
              "-" +
              edu.end_date.replace("-", "/")}
          </p>
        </div>
      )}
    </li>
  );
};
const ListBox = ({ title, children }) => {
  return (
    <div className="h-full w-full rounded-lg bg-primary-100 p-4 max-lg:h-[180px]">
      <h3 className="pb-4 text-[17px] font-semibold leading-tight text-primary-900">
        {title}
      </h3>
      {children}
    </div>
  );
};

const CoreCvSection = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log(user);
  const work_locations = [];
  const work_time = [];
  const languages = [];
  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  for (const [key, value] of Object.entries(user.work_time)) {
    if (value) {
      work_time.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  user.languages_array.map((lang) =>
    languages.push(`${lang.language_name} - ${lang.language_proficiency}`),
  );
  return (
    <div
      className={`${
        isOpen ? "py-2" : "py-6"
      } container2 mb-6 rounded-xl bg-primary-50 px-9 transition-all ease-linear max-lg:mb-3 max-lg:p-4`}
    >
      <button
        className="flex w-full items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SectionTitle title="Core CV" className="items-start lg:w-1/4" />
        <ChevronDownOutlined
          className={`${
            isOpen ? "rotate-0" : "rotate-180"
          } h-6 w-6 transition-all`}
        />
      </button>

      {IsMobile() ? (
        <div
          className={`${
            isOpen ? "max-h-0 pt-0" : "max-h-[400px] pt-3"
          } overflow-hidden transition-all`}
        >
          <CoreCvSectionMobileView user={user} />
        </div>
      ) : (
        <div
          className={`${
            isOpen ? "max-h-0 pt-0" : "max-h-[550px] pt-4"
          } grid auto-rows-auto grid-cols-5 gap-4 overflow-hidden transition-all`}
        >
          <div className="col-span-1 row-span-2">
            <ListBox title="Kişisel Bilgiler">
              <ul className="flex flex-col gap-2">
                <ListItems item={user.job.job_name} />
                <ListItems item={user.email} />
                <ListItems item={user.phone} />
                <ListItems
                  item={
                    user.location_state.state_name +
                    ", " +
                    user.location_country.country_name
                  }
                />
                <ListItems item={user.birth_date} />
              </ul>
            </ListBox>
          </div>
          {/* ABOUT */}
          <div className="col-span-4 h-fit">
            <ListBox title="Hakkında">
              <ul className="flex flex-wrap gap-2">
                <ListItems item={user.user_bio} />
              </ul>
            </ListBox>
          </div>
          {/* WORK PREFS - LANG */}
          <div className="col-span-4 grid grid-cols-3 gap-4">
            <ListBox title="Çalışma Tercihi">
              <ul className="flex flex-wrap gap-2">
                {work_locations.map((loc, index) => (
                  <ListItems key={index} item={loc} />
                ))}
              </ul>
            </ListBox>
            <ListBox title="Çalışma Zamanı Tercihi">
              <ul className="flex flex-wrap gap-2">
                {work_time.map((option, index) => (
                  <ListItems key={index} item={option} />
                ))}
              </ul>
            </ListBox>
            <ListBox title="Dil Bilgisi">
              <ul className="flex flex-wrap gap-2">
                {languages.map((language, index) => (
                  <ListItems key={index} item={language} />
                ))}
              </ul>
            </ListBox>
          </div>
          {/* EDU-SKILL-EXP */}
          <div className="col-span-5 row-span-1 grid grid-cols-3 gap-4">
            <div className="">
              <ListBox title="Eğitim Durumu">
                <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
                  {user.educations_array.map((school, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1"
                    >
                      <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                        <p className="text-[14px] font-medium leading-tight text-primary-900">
                          &#x2022; {school.institution}
                        </p>
                        <p className="text-[14px] font-medium leading-tight text-neutral-700">
                          {school.department !== "-1" &&
                            school.department + ","}{" "}
                          {school.degree}
                        </p>
                      </div>
                      <p className="text-[14px] font-medium leading-tight text-neutral-700">
                        {school.start_date?.replace("-", "/") +
                          "-" +
                          school.end_date?.replace("-", "/")}
                      </p>
                    </li>
                  ))}
                </ul>
              </ListBox>
            </div>
            <div className="">
              <ListBox title="Deneyimler">
                <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
                  {user.experiences_array.map((exp, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1"
                    >
                      <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                        <p className="text-[14px] font-medium leading-tight text-primary-900">
                          &#x2022; {exp.firm_name}
                        </p>
                        <p className="text-[14px] font-medium leading-tight text-neutral-700">
                          {exp.job.name}
                        </p>
                      </div>
                      <p className="text-[14px] font-medium leading-tight text-neutral-700">
                        {exp.start_date?.replace("-", "/") +
                          "-" +
                          (exp.end_date?.replace("-", "/") || "Devam Ediyor")}
                      </p>
                    </li>
                  ))}
                </ul>
              </ListBox>
            </div>
            <div className="">
              <ListBox title="Yetenekler">
                <ul className="scrollbar-hide flex flex-wrap gap-2 overflow-auto lg:max-h-[250px]">
                  {user.skills_array.map((skill, index) => (
                    <ListItems key={index} item={skill.skill_name} />
                  ))}
                </ul>
              </ListBox>
            </div>
          </div>
          <div className="col-span-5 flex flex-row-reverse">
            <Link
              to="/settings/user"
              className="flex items-center gap-1 font-medium text-primary-600"
            >
              <EditFilled className="h-4 w-4" />
              <span>Edit</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const CoreCvSectionMobileView = ({ user }) => {
  const work_locations = [];
  const work_time = [];
  const languages = [];

  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  for (const [key, value] of Object.entries(user.work_time)) {
    if (value) {
      work_time.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  user.languages_array.map((lang) =>
    languages.push(`${lang.language_name} - ${lang.language_proficiency}`),
  );
  return (
    <div>
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{ clickable: true, el: ".swiper-corecv-pagination" }}
        spaceBetween={20}
      >
        <SwiperSlide>
          <ListBox title="Kişisel Bilgiler">
            <ul className="flex flex-col gap-2 max-lg:gap-1">
              <ListItems item={user.job.job_name} />
              <ListItems item={user.email} />
              <ListItems item={user.phone} />
              <ListItems
                item={
                  user.location_state.state_name +
                  ", " +
                  user.location_country.country_name
                }
              />
              <ListItems item={user.birth_date} />
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Hakkında">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              <ListItems item={user.user_bio} />
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Sektörel Tecrübe">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              {user.experiences_array.map((exp, index) => (
                <li
                  key={index}
                  className="flex flex-col items-start gap-2 max-lg:gap-1"
                >
                  <div className="flex flex-1 flex-row justify-between max-lg:w-full">
                    <p className="line-clamp-1 w-[160px] text-[14px] font-medium leading-tight text-primary-900">
                      &#x2022; {exp.firm_name}
                    </p>
                    <p className="line-clamp-1 w-[125px] text-[14px] font-medium leading-tight text-neutral-700">
                      {exp.start_date?.replace("-", "/") +
                        "-" +
                        (exp.end_date?.replace("-", "/") || "Devam Ediyor")}
                    </p>
                  </div>
                  <p className="line-clamp-1 w-[285px] flex-1 text-[14px] font-medium leading-tight text-neutral-700">
                    {exp.job.name}
                  </p>
                </li>
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Çalışma Tercihi">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              {work_locations.map((loc, index) => (
                <ListItems key={index} item={loc} />
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Çalışma Zamanı Tercihi">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              {work_time.map((option, index) => (
                <ListItems key={index} item={option} />
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>

        <SwiperSlide>
          <ListBox title="Dil Bilgisi">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              {languages.map((language, index) => (
                <ListItems key={index} item={language} />
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Eğitim Durumu">
            <ul className="flex flex-col gap-2">
              {user.educations_array.map((school, index) => (
                <li
                  key={index}
                  className="flex flex-col items-start gap-2 max-lg:gap-1"
                >
                  <div className="flex flex-1 flex-row justify-between max-lg:w-full">
                    <p className="line-clamp-1 w-[170px] text-[14px] font-medium leading-tight text-primary-900">
                      &#x2022; {school.institution}
                    </p>
                    <p className="w-[115px] text-[14px] font-medium leading-tight text-neutral-700 ">
                      {school.start_date?.replace("-", "/") +
                        "-" +
                        school.end_date?.replace("-", "/")}
                    </p>
                  </div>
                  <p className="line-clamp-1 w-[285px] flex-1 text-[14px] font-medium leading-tight text-neutral-700">
                    {school.department !== "-1" && school.department + ","}{" "}
                    {school.degree}
                  </p>
                </li>
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>
        <SwiperSlide>
          <ListBox title="Yetenekler">
            <ul className="flex flex-wrap gap-2 max-lg:gap-1">
              {user.skills_array.map((skill, index) => (
                <ListItems key={index} item={skill.skill_name} />
              ))}
            </ul>
          </ListBox>
        </SwiperSlide>
      </Swiper>
      <div slot="pagination" className="swiper-corecv-pagination text-center" />
    </div>
  );
};

export default CoreCvSection;
