import React from "react";
import { twMerge } from "tailwind-merge";

const Button = (props) => {
  const { className, disabled, type, onClick, icon, children, icon2, loading, id } = props;
  const classes =
    "disabled:opacity-40 outline-none max-lg:py-3 inline-flex max-lg:gap-[8px] gap-[10px] items-center justify-center py-4 px-6 bg-primary-600 border border-primary-600 text-neutral-100 rounded-lg text-[16px] font-semibold leading-tight whitespace-nowrap";
  const mergedClasses = twMerge(classes, className);
  return (
    <button id={id} disabled={disabled || loading} type={type} className={mergedClasses} onClick={onClick}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {icon}
          {children}
          {icon2}
        </>
      )}
    </button>
  );
};

const Spinner = () => {
  return (
    <div className="h-5 w-5 animate-spin rounded-full border border-solid border-primary-400 border-t-transparent"></div>
  );
};

export default Button;
