import { Link, NavLink, useLocation } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { HumanasLogo } from "../OldDesign/Icons";
import { useCallback, useEffect, useState } from "react";
import Dropdown from "./Dropdowns/Dropdown";
import { useStore } from "../../store/useStore";
import { HEADER_MENU_LINKS, NAV_LINKS } from "./constants";

const Header = ({ hide }) => {
  const [showTopBar, setShowTopBar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState();
  const userName = useStore((state) => state.user.data.userName);
  const userCompany = useStore((state) => state.user.data.userCompany);
  const userType = useStore((state) => state.user.data.userType);
  const userPicture = useStore((state) => state.user.data.userPicture);
  const setSettings = useStore((state) => state.setSettings);
  const appLanguage = useStore((state) => state.appLanguage);
  const setAppLanguage = useStore((state) => state.setAppLanguage);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Dashboard"].content.translations["Default Header"]);
  const location = useLocation();
  const isCNAPage = location.pathname.slice(1, 4).includes("cna");

  // console.log("location", location.pathname.slice(1, 4).includes("cna"));

  const controlHeader = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      // console.log(window.scrollY, lastScrollY);
      // if scroll down hide the navbar
      setShowTopBar(false);
    } else {
      // if scroll up show the navbar
      setShowTopBar(true);
    }
    if (window.scrollY > 30) setLastScrollY(window.scrollY);
    // remember current page location to use in the next move
  }, [lastScrollY]);

  const menuLinks = HEADER_MENU_LINKS(content);
  const header_links = NAV_LINKS(content);

  // console.log("applanguage", appLanguage);
  const handleAppLanguageChange = async (e) => {
    const payload = { language: e.target.value };
    if (userType === "recruiter") {
      await setSettings(payload);
    } else {
      await updateApplicationForm(payload);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeader);
    return () => {
      window.removeEventListener("scroll", controlHeader);
    };
  }, [lastScrollY, controlHeader]);

  return (
    <div
      className={`${showTopBar ? "max-lg:translate-y-[0]" : "max-lg:-translate-y-[55px]"} ${hide && "hidden"} ${
        isCNAPage &&
        "fixed left-1/2 top-0 z-[999] mx-auto w-full -translate-x-1/2 bg-neutral-100 bg-opacity-20 backdrop-blur-md"
      } max-lg:fixed max-lg:top-0 max-lg:z-50 max-lg:w-full max-lg:bg-neutral-100 max-lg:bg-opacity-50 max-lg:backdrop-blur-lg max-lg:transition-all`}
    >
      <div className="container2 flex flex-row justify-between py-2 max-lg:px-2">
        <Link to="/" className="flex flex-col justify-center gap-0.5 max-lg:gap-1">
          <HumanasLogo className="text-primary-600 max-lg:h-[22px] max-lg:w-[115px]" />
          <p className="text-[11px] font-medium leading-tight text-primary-800 max-lg:text-[9px]">
            {userType === "recruiter" ? content["recruiter slogan"] : content["candidate slogan"]}
          </p>
        </Link>
        <nav className="flex items-center justify-center gap-1 px-2 max-lg:hidden">
          {header_links[userType].map((link) => {
            return (
              <NavLink
                key={link.path}
                to={link.path}
                aria-disabled={!link.ready}
                style={({ isActive, isPending }) => {
                  return {
                    borderLeft: isActive ? "2px solid #0D155A" : "2px solid #ffffff00",
                    color: isActive ? "#0D155A" : "#000000",
                    opacity: isActive ? "100%" : "70%",
                    fontWeight: isActive ? "700" : "500",
                    pointerEvents: link.ready ? "auto" : "none",
                  };
                }}
                className="relative m-2 whitespace-nowrap pl-2 text-[15px] leading-[18px] opacity-70"
              >
                {link.title}
                {!link.ready && (
                  <span className="py-.5 absolute -right-3 -top-4 rounded-md bg-primary-100 px-1 text-[9px] text-primary-700">
                    Coming Soon
                  </span>
                )}
              </NavLink>
            );
          })}
        </nav>
        <div className="flex max-h-full flex-row items-center gap-1 max-lg:gap-2 max-lg:pl-2">
          <div className="flex flex-row rounded-[6px] border p-1">
            <select
              className="bg-white bg-opacity-0 text-[13px] font-medium leading-4 text-primary-900 outline-none max-lg:text-[11px]"
              value={appLanguage}
              onChange={handleAppLanguageChange}
            >
              <option value="tr">TR</option>
              <option value="en">EN</option>
            </select>
          </div>
          <Dropdown items={menuLinks[userType]}>
            <div className="flex flex-row items-center gap-1.5">
              <img
                src={userPicture}
                className="h-[45px] w-[45px] rounded-full max-lg:h-[35px] max-lg:w-[35px] "
                alt="Profile"
              />
              <div className="overflow-hidden max-lg:hidden">
                <p className="whitespace-nowrap text-[13px] leading-4 text-primary-1000">{userName}</p>
                <p className="whitespace-nowrap text-left text-[13px] leading-4 text-neutral-800">{userCompany}</p>
              </div>
              <div className="">
                <GoChevronDown className="text-neutral-500" />
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
