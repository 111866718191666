import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [buttonHeight, setButtonHeight] = useState(0);
  const dropdown = useRef();
  useEffect(() => {
    setButtonWidth(dropdown.current.clientWidth);
    setButtonHeight(dropdown.current.clientHeight);
  }, []);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };
  const menuItems = props.items ? props.items : [];
  return (
    <>
      <div className="relative flex">
        <button ref={dropdown} onClick={toggle}>
          {props.children}
        </button>
        <div
          className={`${
            isOpen ? "flex" : "hidden"
          } absolute z-20 w-[150px] flex-col divide-y rounded-md bg-neutral-100 p-2 text-neutral-800 shadow-xl`}
          style={{
            // width: buttonWidth,
            top: buttonHeight + 4,
            right: -6,
          }}
        >
          {menuItems.map((item) => (
            <Link
              className="rounded-md p-1 text-right text-[14px] leading-loose hover:bg-neutral-200"
              key={item.path}
              to={item.path || ""}
              onClick={toggle}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
      {isOpen ? (
        <div
          className="absolute left-0 top-0 z-10 h-screen w-screen opacity-0"
          onClick={toggle}
        ></div>
      ) : null}
    </>
  );
};

export default Dropdown;
