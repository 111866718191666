import React from "react";
import { CompanyOutlined } from "../OldDesign/Icons";

const ButtonCompany = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="group flex h-[180px] w-[147px] flex-col items-center justify-end gap-6 rounded-xl border border-neutral-300 p-9 font-medium leading-5 hover:bg-secondary_recruiter-700 hover:text-white max-lg:bg-secondary_recruiter-700 max-lg:text-white lg:h-[240px] lg:w-[190px] lg:gap-12"
    >
      <CompanyOutlined />
      Company
    </button>
  );
};

export default ButtonCompany;
