import React from "react";
import { PlayOutlined } from "../OldDesign/Icons";

const VideoCard = ({ video }) => {
  return (
    <div
      className="flex h-[240px] w-[440px] items-end rounded-xl px-6 py-4 text-primary-100 max-lg:h-[198px] max-lg:w-[296px] max-lg:shrink-0"
      style={{
        background: `url(${video.thumbnail})`,
        backgroundPositionY: "50%",
      }}
    >
      <div className="flex flex-row items-center gap-2">
        <PlayOutlined className="h-6 w-6" />
        <p className="text-[18px] font-semibold leading-tight">{video.title}</p>
      </div>
    </div>
  );
};

export default VideoCard;
