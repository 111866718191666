import React, { useState } from "react";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import {
  ChevronRightOutlined,
  CloseOutlined,
  EditFilled,
  ExclamationIcon,
  LeftCircularOutlined,
  PlusOutlined,
} from "../../../OldDesign/Icons";
import { DEPARTMENTS, EDUCATION_DEGREE } from "../../constants";
import { Input } from "../../Forms/Input";
import { REACT_BASE_URL } from "../../../../api/constants";
import { addYears, subYears } from "date-fns";

const EducationDetailsCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore(
    (state) => state.updateApplicationForm,
  );
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  const [selectedSchools, setSelectedSchools] = useState(
    coreCV.educations_array,
  );
  const [emptyErrorMessage, setEmptyErrorMessage] = useState(null);
  const [showSchoolOptions, setShowSchoolOptions] = useState(false);
  const [addingSchool, setAddingSchool] = useState(false);
  const [degree, setDegree] = useState(null);
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    trigger,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      institution: "",
      institution_id: "",
      degree: "",
      department: "",
      department_id: "",
      end_date: "",
      start_date: "",
    },
    mode: "onSubmit",
  });
  const loadInstitutionOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/institute?rows=200&page=1&keyword=${value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/departments?page=1&rows=100&keyword=${value}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const sendFormData = (formData) => {
    // console.log({ selectedSchools });
    if (selectedSchools.length > 0) {
      updateApplicationForm(
        { educations_array: selectedSchools },
        props.handleForwardForm,
      );
    } else {
      trigger();
      setEmptyErrorMessage("Okul Bilgisi Gereklidir");
    }
  };
  const closeAddingSchool = () => {
    setShowSchoolOptions(false);
    setAddingSchool(false);
    reset();
    clearErrors();
  };
  const checkAndInsertData = () => {
    const selected_institution = getValues("institution");
    const selected_degree = getValues("degree");
    const selected_department = getValues("department");
    const selected_end_date = getValues("end_date");
    const selected_start_date = getValues("start_date");
    if (
      ["primaryeducation", "secondaryeducation", "highschool"].includes(
        selected_degree,
      )
    ) {
      if (
        selected_degree &&
        selected_institution &&
        selected_end_date &&
        selected_start_date
      ) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution,
            institution_id: 0,
            degree: selected_degree,
            department: "-1",
            department_id: 0,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        reset();
        clearErrors();
        setShowSchoolOptions(false);
        setAddingSchool(false);
      }
    } else {
      if (
        selected_degree &&
        selected_institution &&
        selected_department &&
        selected_end_date &&
        selected_start_date
      ) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution.institute_name,
            institution_id: selected_institution.institute_id,
            degree: selected_degree,
            department: selected_department.name,
            department_id: selected_department.id,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        reset();
        clearErrors();
        setShowSchoolOptions(false);
        setAddingSchool(false);
      }
    }
  };

  return (
    <Form
      className="flex h-full flex-col justify-between"
      onSubmit={handleSubmit(sendFormData)}
    >
      {addingSchool ? (
        <fieldset className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
              {content["egitim detaylari baslik"]}
            </h2>
            <div className="flex flex-row gap-3">
              <button type="button" onClick={() => closeAddingSchool()}>
                <ChevronRightOutlined className="inline-block h-4 w-4 rotate-180 text-error-400" />
                <p className="inline-block text-[13px] text-error-400">İptal</p>
              </button>
              <button
                type="button"
                onClick={() => {
                  checkAndInsertData();
                  trigger();
                }}
                className="rounded-lg bg-primary-700 px-1"
              >
                <p className="inline-block text-[13px] text-neutral-100">
                  Tamam
                </p>
                <PlusOutlined className="inline-block h-4 w-4 text-neutral-100" />
              </button>
            </div>
          </div>
          <Field
            label={content["egitim dereceniz baslik"]}
            error={errors?.degree}
          >
            <Controller
              control={control}
              name="degree"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  onChange={(e) => {
                    if (e !== null) {
                      onChange(e.id);
                      setShowSchoolOptions(true);
                      setDegree(e.id);
                    } else {
                      setDegree(null);
                      setShowSchoolOptions(false);
                    }
                  }}
                  onBlur={onBlur}
                  options={EDUCATION_DEGREE}
                  placeholder="Seçiniz"
                  isSearchable
                  getOptionLabel={(opt) => opt.title}
                  getOptionValue={(opt) => opt.id}
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      borderRadius: "8px",
                      borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                      borderWidth: "1px",
                    }),
                    valueContainer: (styles, state) => ({
                      ...styles,
                      height: "49px",
                      paddingLeft: "16px",
                    }),
                  }}
                />
              )}
            />
          </Field>
          {showSchoolOptions &&
            (["primaryeducation", "secondaryeducation", "highschool"].includes(
              degree,
            ) ? (
              <Field
                label={content["okul adi baslik"]}
                error={errors?.institution}
              >
                <Input
                  {...register("institution", {
                    required: "Required",
                  })}
                  id="institution"
                  placeholder="Yazınız"
                />
              </Field>
            ) : (
              <Field
                label={content["okul adi baslik"]}
                error={errors?.institution}
              >
                <Controller
                  control={control}
                  name="institution"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncSelect
                      cacheOptions={true}
                      defaultOptions
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      loadOptions={loadInstitutionOptions}
                      placeholder="Seçiniz"
                      isSearchable
                      getOptionLabel={(opt) => opt.institute_name}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "49px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
            ))}
          {showSchoolOptions &&
            !["primaryeducation", "secondaryeducation", "highschool"].includes(
              degree,
            ) && (
              <Field
                label={content["bolum adi baslik"]}
                error={errors?.department}
              >
                <Controller
                  control={control}
                  name="department"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncSelect
                      cacheOptions={true}
                      defaultOptions
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      loadOptions={loadDepartmentOptions}
                      placeholder="Seçiniz"
                      isSearchable
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "49px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
            )}
          {showSchoolOptions && (
            <div className="flex flex-row gap-8">
              <Field
                label={content["baslangic yili baslik"]}
                error={errors?.start_date}
              >
                <Controller
                  control={control}
                  name="start_date"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={Date.parse(value)}
                      onChange={onChange}
                      selectsStart
                      startDate={value}
                      endDate={getValues("end_date")}
                      maxDate={addYears(new Date(), 10)}
                      minDate={subYears(new Date(), 100)}
                      placeholderText="Seçiniz"
                      dateFormat="yyyy"
                      showYearPicker
                      className="h-[49px] w-full rounded-lg border-[1px] p-4 focus:outline-primary-600"
                    />
                  )}
                />
              </Field>
              <Field
                label={content["bitis yili baslik"]}
                error={errors?.end_date}
              >
                <Controller
                  control={control}
                  name="end_date"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={Date.parse(value)}
                      onChange={onChange}
                      selectsEnd
                      startDate={getValues("start_date")}
                      endDate={value}
                      maxDate={addYears(new Date(), 10)}
                      minDate={subYears(new Date(), 100)}
                      placeholderText="Seçiniz"
                      dateFormat="yyyy"
                      showYearPicker
                      className="h-[49px] w-full rounded-lg border-[1px] p-4 focus:outline-primary-600"
                    />
                  )}
                />
              </Field>
            </div>
          )}
        </fieldset>
      ) : (
        <>
          <h2 className="mb-2 border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
            {content["egitim detaylari baslik"]}
          </h2>
          <div className="flex flex-1 flex-col gap-2 overflow-scroll pb-2">
            <button
              onClick={() => setAddingSchool(true)}
              className="flex w-full justify-center rounded-xl border border-dashed border-primary-500 bg-primary-50 p-3"
            >
              <span className="text-[16px] font-medium leading-tight text-primary-700">
                Okul Ekle +
              </span>
            </button>
            {selectedSchools.length > 0 &&
              selectedSchools.map((school, index) => (
                <div
                  key={index}
                  className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                >
                  <div className="text-[16px] font-medium leading-tight text-primary-700">
                    <h4>{school.institution}</h4>
                    <p>{school.department !== "-1" && school.department}</p>
                    <p>
                      {school.start_date.substring(0, 4)}-
                      {school.end_date.substring(0, 4)}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    {/* <button type="button">
                      <EditFilled className="h-5 w-5 text-primary-700" />
                    </button> */}
                    <button
                      type="button"
                      onClick={() =>
                        setSelectedSchools((prev) =>
                          prev.filter((e, i) => i !== index),
                        )
                      }
                    >
                      <CloseOutlined className="h-5 w-5 text-primary-700" />
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div className="flex justify-center gap-20">
            <button
              disabled={false}
              type="button"
              id="backwardArrow"
              onClick={() => props.handleBackwardForm()}
            >
              <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
            </button>
            <button disabled={false} type="submit" id="forwardArrow">
              <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
            </button>
          </div>
        </>
      )}
    </Form>
  );
};

export default EducationDetailsCard;
