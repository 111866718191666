import React, { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import Button from "../../../components/NewDesign/Button/Button";
import { Controller, useForm } from "react-hook-form";
import { Form } from "../../../components/NewDesign/Forms/Form";
import { Field, FieldCheckbox } from "../../../components/NewDesign/Forms/Field";
import { ASelect, Checkbox, Input, TextArea } from "../../../components/NewDesign/Forms/Input";
import { PhoneInput } from "react-international-phone";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { REACT_BASE_URL } from "../../../api/constants";
import { useNavigate } from "react-router-dom";
import {
  EDUCATION_DEGREE,
  EXPERIENCE,
  LANGUAGE_PROFICIENCY,
  WORK_STATE,
} from "../../../components/NewDesign/constants";
import { CloseOutlined, PlusOutlined } from "../../../components/OldDesign/Icons";
import Loading from "../../../components/OldDesign/Loading";
import { addYears, subYears } from "date-fns";

const ProfileForm = ({ coreCV }) => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);

  const appLanguage = useStore((state) => state.appLanguage);
  const setAppLanguage = useStore((state) => state.setAppLanguage);

  const [showLanguageLevel, setShowLanguageLevel] = useState(false);

  const [showSchoolOptions, setShowSchoolOptions] = useState(false);

  const [stillWorking, setStillWorking] = useState(false);

  const [degree, setDegree] = useState(null);

  const [selectedExperiences, setSelectedExperiences] = useState(
    coreCV.data.experiences_array.map((exp) => {
      return {
        ...exp,
        job_id: { job_id: exp.job_id, job_name: exp.job.name },
        start_date: new Date(`${exp.start_date}-01`),
        end_date: exp.end_date ? new Date(`${exp.end_date}-01`) : null,
      };
    }),
  );

  const [selectedLanguages, setSelectedLanguages] = useState(coreCV.data.languages_array);

  const [selectedSkills, setSelectedSkills] = useState(coreCV.data.skills_array);

  const [selectedSchools, setSelectedSchools] = useState(coreCV.data.educations_array);

  let defaultWorkStateValue = "";
  if (coreCV.data.work_state) {
    defaultWorkStateValue = WORK_STATE.find((s) => s.value === coreCV.data.work_state);
    // console.log(defaultValue);
  }
  let defaultWorkTimeValue = "";
  if (coreCV.data.work_time) {
    defaultWorkTimeValue = Object.keys(coreCV.data.work_time).filter((key) => coreCV.data.work_time[key] === true);
    // console.log(defaultValue);
  }
  let defaultWorkLocationValue = "";
  if (coreCV.data.work_location) {
    defaultWorkLocationValue = Object.keys(coreCV.data.work_location).filter(
      (key) => coreCV.data.work_location[key] === true,
    );
    // console.log(defaultValue);
  }

  const {
    handleSubmit,
    getValues,
    register,
    setError,
    reset,
    resetField,
    watch,
    trigger,
    clearErrors,
    control,

    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_name: coreCV.data.user_name || "",
      user_phone: coreCV.data.phone || "",
      user_bio: coreCV.data.user_bio || "",
      location_country: coreCV.data.location_country?.country_id
        ? {
            id: coreCV.data.location_country.country_id,
            name: coreCV.data.location_country.country_name,
          }
        : "",
      user_email: coreCV.data.email || "",
      birth_date: coreCV.data.birth_date || "",
      location_state: coreCV.data.location_state?.state_id
        ? {
            id: coreCV.data.location_state.state_id,
            name: coreCV.data.location_state.state_name,
          }
        : "",
      user_job: coreCV.data.user_job?.job_title_id
        ? {
            job_id: coreCV.data.user_job.job_title_id,
            job_name: coreCV.data.user_job.job_name,
          }
        : "",
      job_experience: coreCV.data.user_job?.job_experience_id
        ? {
            job_experience_id: coreCV.data.user_job.job_experience_id,
            job_experience_title: coreCV.data.user_job.job_experience_title,
          }
        : "",
      work_state: defaultWorkStateValue,
      work_location: defaultWorkLocationValue,
      work_time: defaultWorkTimeValue,
      skills_array: coreCV.data.skills_array || [],
      institution: "",
      degree: "",
      department: "",
      end_date: "",
      start_date: "",
      language: "",
      language_proficiency: "",
      job_id: null,
      firm_name: "",
      company_id: "",
      job_start_date: null,
      job_end_date: null,
    },
    mode: "onSubmit",
  });

  const handleLanguageSettingsChange = async (e) => {
    const payload = { language: e.target.value };
    await updateApplicationForm(payload);
  };

  const submitProfileForm = async (formData) => {
    let birth_date = null;
    if (formData.birth_date?.length === 10) {
      birth_date = formData.birth_date;
    } else {
      let day = formData.birth_date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = formData.birth_date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let year = formData.birth_date.getFullYear();
      birth_date = year + "-" + month + "-" + day;
    }
    const location_country = {
      country_id: formData.location_country.id,
      country_name: formData.location_country.name,
    };
    const location_state = {
      state_id: formData.location_state.id,
      state_name: formData.location_state.name,
    };
    const experiences_array = selectedExperiences.map((exp) => {
      //format start date
      let _startDate = new Date(exp.start_date);
      _startDate.setDate(_startDate.getDate() + 1);
      //format end date
      let _endDate = exp.end_date ? new Date(exp.end_date) : null;
      _endDate && _endDate.setDate(_endDate.getDate() + 1);
      //return experiences array items
      return {
        job_id: exp.job_id.job_id,
        firm_name: exp.firm_name,
        company_id: null,
        start_date: _startDate.toISOString().slice(0, 7),
        end_date: _endDate ? _endDate.toISOString().slice(0, 7) : null,
      };
    });
    const languages_array = selectedLanguages.map((lang) => ({
      language_id: lang.language_id,
      language_proficiency: lang.language_proficiency,
    }));
    const user_job = {
      job_title_id: formData.user_job.job_id,
      job_name: formData.user_job.job_name,
      job_experience_name: 1,
      job_experience_id: 1,
    };
    const skills_array = selectedSkills;
    const educations_array = selectedSchools;
    const work_time = {
      parttime: formData.work_time.includes("parttime"),
      fulltime: formData.work_time.includes("fulltime"),
      freelance: formData.work_time.includes("freelance"),
    };
    const work_location = {
      remote: formData.work_location.includes("remote"),
      hybrid: formData.work_location.includes("hybrid"),
      office: formData.work_location.includes("office"),
    };
    const work_state = formData.work_state.value;

    const payload = {
      user_bio: formData.user_bio,
      user_name: formData.user_name,
      user_phone: formData.user_phone,
      birth_date: birth_date,
      educations_array: educations_array,
      user_job: user_job,
      skills_array: skills_array,
      location_country: location_country,
      location_state: location_state,
      languages_array: languages_array,
      work_time: work_time,
      work_location: work_location,
      work_state: work_state,
      experiences_array: experiences_array,
    };

    if (coreCV.data.email) {
      delete payload.user_email;
    }
    // console.log(payload);
    if (skills_array.length === 0) {
      setError("skills_array", { message: "Required" });
    } else if (educations_array.length === 0) {
      setError("degree", { message: "Required" });
    } else if (languages_array.length === 0) {
      setError("language", { message: "Required" });
    } else if (experiences_array.length === 0) {
      setError("firm_name", { message: "Required" });
    } else {
      // console.log(payload);
      await updateApplicationForm(payload);
    }
  };
  const storedLang = localStorage.getItem("lang");
  const deviceLang = navigator.language.substring(0, 2);

  const loadCountryOptions = async (value) => {
    const apiKey = localStorage.getItem("token");

    const res = await fetch(`${REACT_BASE_URL}/content/country?page=1&rows=20&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      "X-localization": storedLang || deviceLang,
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadCityOptions = async (value) => {
    const selectedCountry = getValues("location_country");
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/state?page=1&rows=60&keyword=${value}&country_id=${selectedCountry.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadSkillsOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/skill?rows=100&page=1&keyword=${encodeURIComponent(value)}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadInstitutionOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/institute?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadLanguageOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/language?rows=50&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": storedLang || deviceLang,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/departments?page=1&rows=100&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const checkAndInsertLanguageData = () => {
    const selected_language = getValues("language");
    const selected_language_proficiency = getValues("language_proficiency");
    if (selected_language && selected_language_proficiency) {
      setSelectedLanguages((prev) => [
        ...prev,
        {
          language_name: selected_language.language_name,
          language_id: selected_language.language_id,
          language_proficiency: selected_language_proficiency.value,
        },
      ]);
      resetField("language");
      resetField("language_proficiency");
      setShowLanguageLevel(false);
    }
  };

  const checkAndInsertSchoolData = () => {
    const selected_institution = getValues("institution");
    const selected_degree = getValues("degree");
    const selected_department = getValues("department");
    const selected_end_date = getValues("end_date");
    const selected_start_date = getValues("start_date");
    const elementaries = ["primaryeducation", "secondaryeducation", "highschool"];
    const licence = ["associatedegree", "bachelorsdegree", "mastersdegree", "doctoraldegree"];
    if (elementaries.includes(selected_degree)) {
      if (selected_degree && selected_institution && selected_end_date && selected_start_date) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution,
            institution_id: 0,
            degree: selected_degree,
            department: "-1",
            department_id: 0,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        resetField("institution");
        resetField("degree");
        resetField("department");
        resetField("start_date");
        resetField("end_date");
        clearErrors();
        setShowSchoolOptions(false);
      } else {
        !selected_institution && setError("institution", { message: "Required" });
        !selected_degree && setError("degree", { message: "Required" });
        !selected_start_date && setError("start_date", { message: "Required" });
        !selected_end_date && setError("end_date", { message: "Required" });
      }
    } else if (licence.includes(selected_degree)) {
      if (selected_degree && selected_institution && selected_department && selected_end_date && selected_start_date) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution.institute_name,
            institution_id: selected_institution.institute_id,
            degree: selected_degree,
            department: selected_department.name,
            department_id: selected_department.id,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        resetField("institution");
        resetField("degree");
        resetField("department");
        resetField("start_date");
        resetField("end_date");
        clearErrors();
        setShowSchoolOptions(false);
      } else {
        !selected_institution && setError("institution", { message: "Required" });
        !selected_degree && setError("degree", { message: "Required" });
        !selected_department && setError("department", { message: "Required" });
        !selected_end_date && setError("start_date", { message: "Required" });
        !selected_start_date && setError("end_date", { message: "Required" });
      }
    }
  };

  const checkAndInsertExperienceData = () => {
    const start_date = getValues("job_start_date");
    const end_date = getValues("job_end_date");
    const job_id = getValues("job_id");
    const firm_name = getValues("firm_name");

    const experience = {
      start_date: start_date,
      end_date: end_date,
      job_id: job_id,
      firm_name: firm_name,
      company_id: null,
    };
    // console.log("adding exp", experience, stillWorking, "stillworking");
    if (start_date && firm_name && job_id && (end_date || stillWorking)) {
      setSelectedExperiences((prev) => [...prev, experience]);
      resetField("firm_name");
      resetField("job_end_date");
      resetField("job_start_date");
      resetField("job_id");
      clearErrors();
      setStillWorking(false);
    } else {
      !firm_name && setError("firm_name", { message: "Required" });
      !job_id && setError("job_id", { message: "Required" });
      !start_date && setError("job_start_date", { message: "Required" });
    }
  };

  const handleSkillSelection = (opt) => {
    setSelectedSkills((prev) => [...prev, opt]);
  };

  const atLeastOneOptionChecked = (selectedOpts) => {
    return selectedOpts.length > 0;
  };

  return (
    <Form id="user_profile" className="flex flex-col gap-6" onSubmit={handleSubmit(submitProfileForm)}>
      {/* USER PROFILE */}
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <h5 className="mb-6 border-b pb-2 text-[20px] font-semibold leading-tight text-primary-600">User Profile</h5>
        <div className="mb-4">
          <img
            src={user.data.userPicture}
            alt={user.data.userName}
            className="h-[100px] w-[100px] rounded-full border"
          />
          {/* <Button
            type="button"
            onClick={() => alert("coming soon")}
            className="bg-neutral-100 text-primary-600"
          >
            Update Profile Picture
          </Button> */}
        </div>
        <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
          <fieldset className="flex flex-col gap-6">
            {/* NAME INPUT */}
            <Field label={content["ad soyad baslik"]} error={errors?.user_name}>
              <Input {...register("user_name", { required: "Required" })} id="user_name" placeholder="Yazınız" />
            </Field>
            {/* EMAIL INPUT */}
            <Field label={content["eposta adresi baslik"]} error={errors?.user_email}>
              <Input
                disabled={coreCV.data.email}
                readOnly={coreCV.data.email}
                {...register("user_email", {
                  required: "Required",
                })}
                id="user_email"
                placeholder="Yazınız"
              />
            </Field>
            {/* PHONE INPUT */}
            <Field label={content["telefon numarasi baslik"]} error={errors?.user_phone}>
              <Controller
                control={control}
                name="user_phone"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneInput
                    defaultCountry="tr"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disableFormatting
                    forceDialCode
                    inputClassName="border"
                    className="rounded-lg outline outline-1 outline-neutral-300 transition-all hover:outline-primary-600 focus:outline-primary-600"
                    inputProps={{
                      className: "pl-3 h-12 bg-neutral-100 text-[13px] lg:h-[52px] lg:text-[16px] outline-none",
                    }}
                    dialCodePreviewStyleProps={{
                      className: "bg-neutral-200 border-none pl-1 pr-3 text-[16px]",
                    }}
                    countrySelectorStyleProps={{
                      className: "bg-neutral-200 rounded-l-lg",
                      buttonClassName: "border-none h-full rounded-l-lg hover:rounded-l-lg pl-3",
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="flex flex-col gap-6">
            {/* BIRTHDATE INPUT */}
            <Field label={content["dogum tarihi baslik"]} error={errors?.birth_date}>
              <Controller
                control={control}
                id="birth_date"
                name="birth_date"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    selected={Date.parse(value)}
                    onBlur={onBlur}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Seçiniz"
                    showYearDropdown
                    maxDate={new Date()}
                    minDate={subYears(new Date(), 100)}
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    showMonthDropdown
                    className="h-[52px] w-full rounded-lg border-[1px] border-neutral-300 p-4 focus:outline-none"
                  />
                )}
              />
            </Field>
            {/* COUNTRY INPUT */}
            <Field label={content["yasadiginiz ulke baslik"]} error={errors?.location_country}>
              <Controller
                control={control}
                name="location_country"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    defaultOptions
                    isClearable
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    loadOptions={loadCountryOptions}
                    placeholder={"Seçiniz"}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      placeholder: (styles, state) => ({
                        ...styles,
                        color: "#9A9ABA",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
            {/* CITY INPUT */}
            <Field label={content["yasadiginiz sehir baslik"]} error={errors?.location_state}>
              <Controller
                control={control}
                name="location_state"
                rules={{
                  required: "Required",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    isClearable
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    loadOptions={loadCityOptions}
                    placeholder={"Seçiniz"}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      placeholder: (styles, state) => ({
                        ...styles,
                        color: "#9A9ABA",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="lg:col-span-2">
            {/* ABOUT INPUT */}
            <Field label={"Hakkında"} error={errors?.user_bio}>
              <TextArea
                {...register("user_bio", {
                  required: "Required",
                  maxLength: 510,
                })}
                id="user_bio"
                maxLength={510}
                placeholder="Yazınız"
              />
            </Field>
          </fieldset>
        </div>
      </div>
      {/* JOB DESCRIPTION */}
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <h5 className="mb-6 border-b pb-2 text-[20px] font-semibold leading-tight text-primary-600">Job Description</h5>
        <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
          <fieldset className="flex flex-col gap-6">
            {/* JOB INPUT */}
            <Field label={content["mesleginiz baslik"]} error={errors?.user_job}>
              <Controller
                control={control}
                name="user_job"
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    value={value}
                    defaultOptions
                    isClearable
                    onChange={onChange}
                    onBlur={onBlur}
                    loadOptions={loadJobTitleOptions}
                    placeholder="Seçiniz"
                    getOptionLabel={(opt) => opt.job_name}
                    getOptionValue={(opt) => opt.job_id}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
            {/* WORKSTATE INPUT */}
            <Field label={content["calisma durumunuz baslik"]} error={errors?.work_state}>
              <Controller
                control={control}
                name="work_state"
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    options={WORK_STATE}
                    placeholder="Seçiniz"
                    getOptionLabel={(opt) => opt.label}
                    getOptionValue={(opt) => opt.value}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
            {/* WORKLOCATION INPUT */}
            <div>
              <h4
                className={`${
                  errors.work_location ? "text-error-400" : "text-neutral-900"
                } pb-1 text-[15px] leading-tight `}
              >
                {content["calisma sekli tercihi"]}
              </h4>
              <div className="flex flex-row gap-1">
                <FieldCheckbox className="flex-1" label="Office">
                  <Checkbox
                    value="office"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Office"
                  />
                </FieldCheckbox>
                <FieldCheckbox className="flex-1" label="Remote">
                  <Checkbox
                    value="remote"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Remote"
                  />
                </FieldCheckbox>
                <FieldCheckbox className="flex-1" label="Hybrid">
                  <Checkbox
                    value="hybrid"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Hybrid"
                  />
                </FieldCheckbox>
              </div>
            </div>
            {/* WORKTIME INPUT */}
            <div>
              <h4
                className={`${
                  errors.work_time ? "text-error-400" : "text-neutral-900"
                } pb-1 text-[15px] leading-tight `}
              >
                {content["calisma zamani tercihi"]}
              </h4>
              <div className="flex flex-row gap-1">
                <FieldCheckbox className="flex-1" label="Part Time">
                  <Checkbox
                    value="parttime"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="parttime"
                  />
                </FieldCheckbox>
                <FieldCheckbox className="flex-1" label="Full Time">
                  <Checkbox
                    value="fulltime"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="fulltime"
                  />
                </FieldCheckbox>
                <FieldCheckbox className="flex-1" label="Freelance">
                  <Checkbox
                    value="freelance"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="freelance"
                  />
                </FieldCheckbox>
              </div>
            </div>
          </fieldset>
          <fieldset className="flex flex-col gap-6">
            {/* WORKEXPERIENCE INPUT */}
            {/* <Field
              label={content["is tecrubeniz baslik"]}
              error={errors?.job_experience}
            >
              <Controller
                control={control}
                name="job_experience"
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    isClearable
                    value={value}
                    options={EXPERIENCE}
                    placeholder="Seçiniz"
                    isSearchable
                    getOptionLabel={(opt) => opt.job_experience_title}
                    getOptionValue={(opt) => opt.job_experience_id}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field> */}

            {/* SKILLS INPUT */}
            <div>
              <Field label={content["yetenekler baslik"]} error={errors?.skills_array}>
                <Controller
                  control={control}
                  name="skills_array"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncSelect
                      cacheOptions={true}
                      defaultOptions
                      onChange={(e) =>
                        handleSkillSelection({
                          skill_id: e.skill_id,
                          skill_name: e.skill_name,
                          skill_proficiency: 0,
                        })
                      }
                      isDisabled={selectedSkills?.length >= 20}
                      selected={value}
                      onBlur={onBlur}
                      loadOptions={loadSkillsOptions}
                      placeholder="Seçiniz"
                      getOptionLabel={(opt) => opt.skill_name}
                      getOptionValue={(opt) => opt.skill_name}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "50px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
              <div className="flex flex-wrap justify-center gap-1 pt-2">
                {selectedSkills?.length > 0 &&
                  selectedSkills.map((skill, index) => (
                    <div
                      className="flex items-center gap-1 rounded-full bg-primary-500 px-4 py-2 text-[14px] leading-tight text-primary-50"
                      key={index}
                    >
                      {skill.skill_name}
                      <button
                        type="button"
                        onClick={() => setSelectedSkills((prev) => prev.filter((e, i) => i !== index))}
                      >
                        <CloseOutlined className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      {/* EXPERIENCE */}
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <h5 className="mb-6 border-b pb-2 text-[20px] font-semibold leading-tight text-primary-600">Experience</h5>
        <div className="grid grid-cols-4 gap-3 max-lg:grid-cols-1">
          <fieldset className="col-span-4 grid grid-cols-2 gap-6 max-lg:col-span-1 max-lg:grid-cols-1">
            <Field label={`Şirket Adı`} error={errors?.firm_name}>
              <Input placeholder="Yazınız" {...register("firm_name")} />
            </Field>

            <Field label={content["mesleginiz baslik"]} error={errors?.job_id}>
              <Controller
                control={control}
                name="job_id"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ASelect
                    defaultValue={value}
                    selected={value}
                    onBlur={onBlur}
                    defaultOptions
                    loadOptions={loadJobTitleOptions}
                    onChange={onChange}
                    placeholder="Seçiniz"
                    getOptionLabel={(opt) => opt.job_name}
                    getOptionValue={(opt) => opt.job_id}
                  />
                )}
              />
            </Field>
            <div className="flex flex-row gap-4">
              <Field label={"Başlangıç Tarihi"} error={errors?.job_start_date}>
                <Controller
                  control={control}
                  name="job_start_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={Date.parse(value)}
                      onChange={(date) => onChange(date)}
                      onBlur={onBlur}
                      selectsStart
                      startDate={value}
                      endDate={watch("job_end_date")}
                      maxDate={addYears(new Date(), 10)}
                      minDate={subYears(new Date(), 100)}
                      placeholderText="Seçiniz"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                    />
                  )}
                />
              </Field>
              <Field label={"Bitiş Tarihi"} error={errors?.job_end_date}>
                <Controller
                  control={control}
                  name="job_end_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={Date.parse(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      selectsEnd
                      startDate={watch("job_start_date")}
                      endDate={value}
                      maxDate={addYears(new Date(), 10)}
                      minDate={subYears(new Date(), 100)}
                      placeholderText="Seçiniz"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      disabled={stillWorking}
                      className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                    />
                  )}
                />
              </Field>
            </div>
            <FieldCheckbox className={"h-[52px] border-neutral-300 lg:mt-[22px]"} label={"Halen Çalışıyorum"}>
              <Checkbox
                value={stillWorking}
                onChange={(e) => {
                  setStillWorking((prev) => !prev);
                  resetField("job_end_date");
                }}
              />
            </FieldCheckbox>
          </fieldset>
          <div className="col-span-4 flex justify-end max-lg:col-span-1">
            <Button
              type="button"
              onClick={() => {
                checkAndInsertExperienceData();
              }}
              className="border-success-400 bg-success-400 px-8 py-3"
              icon={<PlusOutlined className="h-4 w-4" />}
            >
              {content["ekle buton"]}
            </Button>
          </div>
          {selectedExperiences.length > 0 &&
            selectedExperiences.map((exp, index) => {
              const startDate = exp.start_date?.toLocaleString("tr", {
                month: "short",
                year: "numeric",
              });
              const endDate = exp.end_date?.toLocaleString("tr", {
                month: "short",
                year: "numeric",
              });
              return (
                <div
                  key={index}
                  className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                >
                  <div className="text-[15px] font-medium leading-tight text-primary-700">
                    <h4 className="line-clamp-1 leading-tight">{exp.firm_name}</h4>
                    <p className="line-clamp-1 text-sm leading-tight">{exp.job_id.job_name}</p>
                    <p className="line-clamp-1 text-sm leading-tight">
                      {startDate} - {endDate ? endDate : "Devam ediyor"}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <button
                      onClick={(e) => setSelectedExperiences((prev) => prev.filter((e, i) => i !== index))}
                      type="button"
                    >
                      <CloseOutlined className="h-5 w-5 text-primary-700" />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* EDUCATION */}
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <h5 className="mb-6 border-b pb-2 text-[20px] font-semibold leading-tight text-primary-600">
          Educational Status
        </h5>
        <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
          <fieldset className="flex flex-col gap-6">
            {/* EDU DEGREE INPUT */}
            <Field label={content["egitim dereceniz baslik"]} error={errors?.degree}>
              <Controller
                control={control}
                name="degree"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={(e) => {
                      if (e !== null) {
                        onChange(e.id);
                        setShowSchoolOptions(true);
                        setDegree(e.id);
                      } else {
                        setDegree(null);
                        setShowSchoolOptions(false);
                      }
                    }}
                    onBlur={onBlur}
                    options={EDUCATION_DEGREE}
                    placeholder="Seçiniz"
                    isSearchable
                    isClearable
                    getOptionLabel={(opt) => opt.title}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
            {/* SCHOOL INPUT */}
            {showSchoolOptions &&
              (["primaryeducation", "secondaryeducation", "highschool"].includes(degree) ? (
                <Field label={content["okul adi baslik"]} error={errors?.institution}>
                  <Input {...register("institution")} id="institution" placeholder="Yazınız" />
                </Field>
              ) : (
                <Field label={content["okul adi baslik"]} error={errors?.institution}>
                  <Controller
                    control={control}
                    name="institution"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <AsyncSelect
                        cacheOptions={true}
                        defaultOptions
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        loadOptions={loadInstitutionOptions}
                        placeholder="Seçiniz"
                        isSearchable
                        getOptionLabel={(opt) => opt.institute_name}
                        styles={{
                          control: (styles, state) => ({
                            ...styles,
                            borderRadius: "8px",
                            borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                            borderWidth: "1px",
                          }),
                          valueContainer: (styles, state) => ({
                            ...styles,
                            height: "50px",
                            paddingLeft: "16px",
                          }),
                        }}
                      />
                    )}
                  />
                </Field>
              ))}
          </fieldset>
          <fieldset className="flex flex-col gap-6">
            {/* DEPARTMENT INPUT */}
            {showSchoolOptions && !["primaryeducation", "secondaryeducation", "highschool"].includes(degree) && (
              <Field label={content["bolum adi baslik"]} error={errors?.department}>
                <Controller
                  control={control}
                  name="department"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AsyncSelect
                      cacheOptions={true}
                      defaultOptions
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      loadOptions={loadDepartmentOptions}
                      placeholder="Seçiniz"
                      isSearchable
                      getOptionLabel={(opt) => opt.name}
                      getOptionValue={(opt) => opt.id}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "50px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
            )}
            {/* SCHOOLDATE INPUT */}
            {showSchoolOptions && (
              <div className="flex flex-row gap-8">
                <Field label={content["baslangic yili baslik"]} error={errors?.start_date}>
                  <Controller
                    control={control}
                    name="start_date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        selected={Date.parse(value)}
                        onChange={onChange}
                        selectsStart
                        startDate={value}
                        endDate={watch("start_date")}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText="Seçiniz"
                        dateFormat="yyyy"
                        showYearPicker
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
                <Field label={content["bitis yili baslik"]} error={errors?.end_date}>
                  <Controller
                    control={control}
                    name="end_date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        selected={Date.parse(value)}
                        onChange={onChange}
                        selectsEnd
                        startDate={watch("start_date")}
                        endDate={value}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText="Seçiniz"
                        dateFormat="yyyy"
                        showYearPicker
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
              </div>
            )}
          </fieldset>
          <div className="col-span-2 flex justify-end max-lg:col-span-1">
            <Button
              type="button"
              onClick={() => {
                checkAndInsertSchoolData();
              }}
              className="border-success-400 bg-success-400 px-8 py-3"
              icon={<PlusOutlined className="h-4 w-4" />}
            >
              {content["ekle buton"]}
            </Button>
          </div>
          <div className="grid grid-cols-4 gap-3 max-lg:grid-cols-1 lg:col-span-2">
            {selectedSchools?.length > 0 &&
              selectedSchools.map((school, index) => (
                <div
                  key={index}
                  className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                >
                  <div className="text-[15px] font-medium leading-tight text-primary-700">
                    <h4 className="line-clamp-1 leading-tight">{school.institution}</h4>
                    <p className="line-clamp-1 text-sm leading-tight">
                      {school.department !== "-1" && school.department}
                    </p>
                    <p className="line-clamp-1 text-sm leading-tight">
                      {school.start_date.substring(0, 4)}-{school.end_date.substring(0, 4)}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <button
                      onClick={(e) => setSelectedSchools((prev) => prev.filter((e, i) => i !== index))}
                      type="button"
                    >
                      <CloseOutlined className="h-5 w-5 text-primary-700" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* LANGUAGE */}
      <div className="rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <h5 className="mb-6 border-b pb-2 text-[20px] font-semibold leading-tight text-primary-600">Language Status</h5>
        <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
          <fieldset className="flex flex-col ">
            <Field label={content["dil bilgisi baslik"]} error={errors?.language}>
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AsyncSelect
                    cacheOptions={true}
                    onChange={(e) => {
                      if (e !== null) {
                        onChange(e);
                        setShowLanguageLevel(true);
                      } else {
                        onChange("");
                        setShowLanguageLevel(false);
                      }
                    }}
                    value={value}
                    // selected={value}
                    loadOptions={loadLanguageOptions}
                    placeholder="Seçiniz"
                    isClearable
                    defaultOptions
                    getOptionLabel={(opt) => opt.language_name}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="flex flex-col ">
            {showLanguageLevel && (
              <Field label={content["dil seviyesi baslik"]} error={errors?.language_proficiency}>
                <Controller
                  control={control}
                  name="language_proficiency"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      onChange={(e) => {
                        onChange(e);
                        checkAndInsertLanguageData(e);
                      }}
                      onBlur={onBlur}
                      selected={value}
                      options={LANGUAGE_PROFICIENCY}
                      placeholder="Seçiniz"
                      isSearchable
                      getOptionLabel={(opt) => opt.label}
                      getOptionValue={(opt) => opt.value}
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          borderRadius: "8px",
                          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                          borderWidth: "1px",
                        }),
                        valueContainer: (styles, state) => ({
                          ...styles,
                          height: "50px",
                          paddingLeft: "16px",
                        }),
                      }}
                    />
                  )}
                />
              </Field>
            )}
          </fieldset>
          <div className="col-span-2 flex flex-wrap justify-start gap-1 max-lg:col-span-1">
            {selectedLanguages?.length > 0 &&
              selectedLanguages.map((selected, index) => (
                <div
                  className="flex items-center gap-1 rounded-full bg-primary-500 px-4 py-2 text-[14px] leading-tight text-primary-50"
                  key={index}
                >
                  {selected.language_name}{" "}
                  {LANGUAGE_PROFICIENCY.find((prof) => prof.value === selected.language_proficiency).label}
                  <button
                    type="button"
                    onClick={() =>
                      setSelectedLanguages(
                        (
                          prev, //remove from selecteds language
                        ) => prev.filter((a, i) => index !== i),
                      )
                    }
                  >
                    <CloseOutlined className="h-5 w-5" />
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* APP LANGUAGE SETTING */}
      <div className="flex flex-row items-center justify-between rounded-xl bg-neutral-100 p-6 max-lg:p-3">
        <p className="text-[17px] font-semibold leading-tight text-primary-900">App Language</p>
        <select
          value={appLanguage}
          onChange={handleLanguageSettingsChange}
          className="rounded-lg border-r-[40px] border-transparent bg-white bg-opacity-0 py-4 pl-10 text-[13px] font-medium leading-none text-primary-900 outline outline-neutral-300"
        >
          <option value="tr">Türkçe</option>
          <option value="en">English</option>
        </select>
      </div>

      <div className="flex basis-full justify-end">
        <Button type="submit" loading={isSubmitting} className="px-12 py-4">
          {content["gonder buton"]}
        </Button>
      </div>
    </Form>
  );
};

const CandidateProfileSettings = () => {
  const coreCV = useStore((state) => state.coreCV);
  const getCoreCV = useStore((state) => state.getCoreCV);
  useEffect(() => {
    if (!coreCV.isLoaded) getCoreCV();
  }, [getCoreCV, coreCV.isLoaded]);

  if (coreCV.isLoading) {
    return <Loading />;
  }
  if (!coreCV.isLoaded) {
    return null;
  }
  return (
    <>
      <ProfileForm coreCV={coreCV} />
    </>
  );
};

export default CandidateProfileSettings;
