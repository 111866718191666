import React, { forwardRef, useState } from "react";
import { SearchOutlined } from "../../OldDesign/Icons";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../../store/useStore";
import debounce from "../../../utils/debouncer";

const SearchBar = forwardRef(({ searchKeyword, setSearchKeyword, ...props }, ref) => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const classes =
    "rounded-full border border-neutral-400 placeholder:text-neutral-600 py-2 pl-10 pr-4 outline-none leading-[16px] text-[14px] h-[33px]";
  const mergedClasses = twMerge(classes, props.className);

  const handleChange = debounce((e) => {
    const input = e.target.value;
    if (input.length > 2 || input.length === 0) setSearchKeyword(input);
  }, 500);
  return (
    <div className="relative">
      <input
        ref={ref}
        onChange={handleChange}
        className={mergedClasses}
        placeholder={content["search buton"]}
        {...props}
      />
      <SearchOutlined className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 text-neutral-600" />
    </div>
  );
});

export default SearchBar;
