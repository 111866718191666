import { TickOutlined } from "../OldDesign/Icons";

export const HorizontalStepper = ({ currentStep, steps }) => {
  return (
    <div className="relative mx-auto flex w-[1024px] justify-between before:absolute before:top-1/2 before:h-[5px] before:w-full before:-translate-y-1/2 before:bg-primary-200 before:content-['']">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`${
            index + 1 === currentStep ? "scale-125" : "scale-100"
          } relative bg-neutral-100 px-6`}
        >
          <div className="relative flex h-16 w-16 flex-col items-center justify-center rounded-full bg-primary-200">
            <p
              className={`${
                currentStep < index + 1 ? "bg-primary-200" : "bg-primary-600"
              } flex h-12 w-12 items-center justify-center rounded-full text-[28px] font-bold leading-tight text-neutral-100`}
            >
              {currentStep > index + 1 ? (
                <TickOutlined className="h-6 w-6" />
              ) : (
                index + 1
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const VerticalStepper = ({ currentStep, steps }) => {
  return (
    <div className="relative flex h-[315px] w-fit flex-col items-start justify-between before:absolute before:left-[21px] before:h-full before:w-[6px] before:bg-primary-200 before:content-[''] max-lg:before:left-[16px] max-lg:before:w-[4px]">
      {steps.map((step, index) => (
        <div key={index} className={`relative flex items-center gap-3 `}>
          <div
            className={`${
              currentStep === index + 1 ? "bg-primary-600" : "bg-primary-200"
            } flex h-12 w-12 items-center justify-center rounded-full max-lg:h-9 max-lg:w-9`}
          >
            <p
              className={`${
                currentStep < index + 1 ? "bg-primary-200" : "bg-primary-600"
              } flex h-8 w-8 items-center justify-center rounded-full text-[20px] font-bold leading-tight text-neutral-100 max-lg:h-6 max-lg:w-6`}
            >
              {currentStep > index + 1 ? (
                <TickOutlined className="h-5 w-5" />
              ) : (
                index + 1
              )}
            </p>
          </div>
          <span className="text-[16px] font-semibold leading-tight text-primary-600 max-lg:hidden">
            {step.name}
          </span>
        </div>
      ))}
    </div>
  );
};
