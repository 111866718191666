import React, { useState } from "react";
import {
  BalanceFilled,
  ChevronDownOutlined,
  LampColored,
  LightningFilled,
  WayOutlined,
} from "../../../components/OldDesign/Icons";
import { useStore } from "../../../store/useStore";

const QuickTips = () => {
  const user = useStore((state) => state.user.data);
  // console.log(user);
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  const content2 = useStore(
    (state) =>
      state.content.data["Recruiter Community"].content.translations[
        "Recruiter Info Boxes"
      ],
  );
  const QUICK_TIPS_C = [
    {
      title: content["yolda olun baslik"],
      content: content["yolda olun aciklama"],
      icon: <WayOutlined className="h-5 w-5" />,
    },
    {
      title: content["guncel kalin baslik"],
      content: content["guncel kalin aciklama"],
      icon: <LightningFilled className="h-5 w-5 " />,
    },
    {
      title: content["durust olun baslik"],
      content: content["durust olun aciklama"],
      icon: <BalanceFilled className="h-5 w-5 " />,
    },
  ];
  const QUICK_TIPS_R = [
    {
      title: content2["favoriler baslik"],
      content: content2["favoriler aciklama"],
      icon: <WayOutlined className="h-5 w-5" />,
    },
    {
      title: content2["gruplar baslik"],
      content: content2["gruplar aciklama"],
      icon: <LightningFilled className="h-5 w-5 " />,
    },
    {
      title: content2["match baslik"],
      content: content2["match aciklama"],
      icon: <BalanceFilled className="h-5 w-5 " />,
    },
  ];
  return (
    <div className="rounded-xl lg:bg-[linear-gradient(180deg,#EBF7F0_50%,#FFF_50%)] lg:p-6">
      <div className="flex items-center justify-center pb-3">
        <h3 className="text-center text-[24px] font-medium leading-tight text-primary-900 max-lg:text-[18px]">
          {content["isinize gelen ipuclar baslik"]}
        </h3>
        <LampColored className="h-8 w-8" />
      </div>
      <p className="mx-auto pb-7 text-center text-[18px] font-medium leading-tight text-neutral-900 max-lg:pb-3 max-lg:text-[13px] lg:w-1/2">
        {user.userType === "recruiter"
          ? content2["ipuclar aciklama"]
          : content["isinize gelen ipuclar aciklama"]}
      </p>
      <div className="grid grid-cols-3 gap-5 max-lg:grid-cols-1 max-lg:gap-2">
        {user.userType === "recruiter"
          ? QUICK_TIPS_R.map((item, index) => (
              <TipCard key={index} item={item} />
            ))
          : QUICK_TIPS_C.map((item, index) => (
              <TipCard key={index} item={item} />
            ))}
      </div>
    </div>
  );
};

const TipCard = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col gap-3 rounded-xl bg-neutral-100 px-4 py-6 shadow-md max-lg:p-4">
      <div
        className="flex cursor-pointer items-center gap-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="rounded-full bg-primary-600 p-1.5 text-neutral-100">
          {item.icon}
        </div>
        <h4 className="text-[24px] font-semibold leading-tight text-secondary_applicant-700 max-lg:text-[16px] ">
          {item.title}
        </h4>
        <div className="ml-auto lg:hidden">
          <ChevronDownOutlined className="h-3 w-3" />
        </div>
      </div>
      <p
        className={`${
          isOpen ? "block" : "max-lg:hidden lg:block"
        } text-[18px] leading-tight text-neutral-900 max-lg:text-[14px]`}
      >
        {item.content}
      </p>
    </div>
  );
};

export default QuickTips;
