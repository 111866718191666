import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { metaCvActions } from "../../../api/endpoints";
import Loading from "../../OldDesign/Loading";
import CvHeader from "./CvHeader";
import PersonalityTypeSection from "./PersonalityTypeSection";
import ProfessionalOrientationSection from "./ProfessionalOrientationSection";
import CvFooter from "./CvFooter";
import ActionBar from "./ActionBar";

import SummarySection from "./SummarySection";
import { ConnectionsWorld } from "../Illustrations";

const MetaCvView = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const commentRef = useRef();

  const handleScrollToCommentSection = () => {
    commentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const [metaCv, setMetaCv] = useState({});
  // console.log("loc", location);
  // console.log("metacv printable", metaCv);
  useEffect(() => {
    const payload = {
      type: "view",
      target: id,
      action: {},
    };
    metaCvActions(
      payload,
      (data) => {
        setMetaCv(data.data);
      },
      (err) => console.log(err),
      (bool) => setIsLoading(bool),
    );
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }
  if (!metaCv.id) {
    return (
      <div className="p-20">
        <ConnectionsWorld className="mx-auto w-[600px] pb-9" />
        <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
          Meta Cv Bulunamadı
        </p>
      </div>
    );
  }
  // if (!user) {
  //   return null;
  // }

  return (
    <div className="relative mx-auto grid w-[1240px] grid-cols-1 gap-8 p-9">
      <div className="absolute right-0 top-20">
        <div className="fixed">
          <ActionBar
            handleScrollToCommentSection={handleScrollToCommentSection}
            user={metaCv}
            setMetaCv={setMetaCv}
          />
        </div>
      </div>
      <CvHeader user={metaCv} />
      <SummarySection user={metaCv} />
      {/* <PersonalityTypeSection user={metaCv} /> */}
      <ProfessionalOrientationSection user={metaCv} />
      <div ref={commentRef}>
        <CvFooter user={metaCv} />
      </div>
    </div>
  );
};

export default MetaCvView;
