import React, { useEffect, useState } from "react";
import Button from "../../../../components/NewDesign/Button/Button";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import SearchBar from "../../../../components/NewDesign/SearchBar/SearchBar";
import CircularProgressBar from "../../../../components/NewDesign/StatusBar/CircularProgressBar";
import { TagWithIcon } from "../../../../components/NewDesign/Tags";
import {
  BadgeFilled,
  BlacklistOutlined,
  BookFilled,
  ChevronDownOutlined,
  LightningFilled,
  MatchFilled,
  PeopleFilled,
  RightArrowOutlined,
  TagFilled,
} from "../../../../components/OldDesign/Icons";
import { BulbIllustration } from "../../../../components/NewDesign/Illustrations";
import SectionTitle from "../../../../components/NewDesign/SectionTitle";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";
import IsMobile from "../../../../utils/IsMobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobPositionReport } from "../../../../api/endpoints";
import Loading from "../../../../components/OldDesign/Loading";
import { COMPATIBILITY_STAT_COLORS, CORECV_FIELD_KEYS } from "../../../../components/NewDesign/constants";
import MatchedOrientationCard from "../../../../components/NewDesign/MatchedOrientationCard";

const RadarPersonalityChart = ({ radar_data, onlyRef, refName = "ref", targetName = "target" }) => {
  // onlyRef && radar_data.forEach((data) => delete data.value_target);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart data={radar_data}>
        <defs>
          <linearGradient
            id="colorRefGroup"
            x1="148.912"
            y1="1.02734"
            x2="148.912"
            y2="255.707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6002EE" />
            <stop offset="1" stopColor="#C09AF9" stopOpacity="0.31" />
          </linearGradient>
          <linearGradient
            id="colorMatchGroup"
            x1="107.176"
            y1="1.47974"
            x2="107.176"
            y2="195.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106BAC" />
            <stop offset="1" stopColor="#4064FF" stopOpacity="0" />
          </linearGradient>
        </defs>
        <PolarGrid />
        <PolarAngleAxis dataKey="title" fontWeight={600} fontSize={IsMobile() ? "13px" : "16px"} />
        {/* <PolarRadiusAxis domain={[0, 100]} /> */}
        <Radar
          dot={{ fillOpacity: 1, fill: "#6359E9" }}
          name={refName}
          dataKey="value_reference"
          stroke="#6359E9"
          fill="url(#colorRefGroup)"
          fillOpacity={0.3}
        />
        {!onlyRef && (
          <Radar
            dot={{ fillOpacity: 1, fill: "#9ECBF6" }}
            name={targetName}
            dataKey="value_target"
            stroke="#9ECBF6"
            fill="url(#colorMatchGroup)"
            fillOpacity={0.3}
          />
        )}
        <Legend
          iconType="circle"
          formatter={(value, entry, index) => (
            <span className="font-medium text-neutral-1000 max-lg:text-[13px]">{value}</span>
          )}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const MatchRow = ({ match }) => {
  return (
    <div className="flex flex-col items-start gap-2 rounded-xl border border-neutral-300 p-3">
      <div className="flex items-center gap-1">
        <CircularProgressBar value={match.match_percent} small />
        <p>{match.info_target.name}</p>
      </div>
      <TagWithIcon
        className="border border-neutral-700 bg-transparent text-neutral-700"
        icon={<BadgeFilled className="h-4 w-4 text-success-400" />}
        text={"N/A"}
      />
    </div>
  );
};

const AnalysisReport = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [analysisReport, setAnalysisReport] = useState({ data: {}, isLoading: false, isLoaded: false });
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [openActionsDrawer, setOpenActionsDrawer] = useState(true);
  const matches = analysisReport.data.match;
  const currentMatch = currentMatchIndex >= 0 && matches[currentMatchIndex];
  const radar_data = [
    {
      title: "Duygusal Dengeye Sahip",
      description:
        "Duygusal denge kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlarda benimsedikleri yaklaşımları duygular ve duygu düzenleme açısından ele alan sınıflandırmadır. Bireyler karşılaştıkları zorlayıcı durumlarda rahat, uysal, iyimser veya soğukkanlı bir yaklaşımla davranışlarını şekillendirebilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 69,
      value_target: 74,
      value_max: 100,
    },
    {
      title: "Sorumlu",
      description:
        "Sorumlu kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlarda ne kadar sorumluluk üstlendikleri, hangi amaçla hareket ettikleri ve işlerini sürdürmede nasıl bir yaklaşım benimsedikleri ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda başarı odaklı, düzenli, öz-disiplinli, temkinli ve güvenilir kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 67,
      value_target: 67,
      value_max: 100,
    },
    {
      title: "Uyumlu",
      description:
        "Uyumlu kişilik sınıflandırması kişilerin genel olarak yaşantılarında (ev, iş, sosyal bağlam vb.) karşılaştığı durumlara ne kadar uyum gösterebildikleri ve nasıl uyum sağladıkları ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda duyarlı, uzlaşmacı ve alçakgönüllü kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 68,
      value_target: 72,
      value_max: 100,
    },
    {
      title: "Dışa Dönük",
      description:
        "Dışadönük kişilik sınıflandırması kişilerin sosyal ilişkilerinde kendilerini nasıl ifade ettikleri ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda baskın, sosyal ve enerjik kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 67,
      value_target: 59,
      value_max: 100,
    },
    {
      title: "Deneyime Açık",
      description:
        "Deneyime açık kişilik sınıflandırması kişilerin yeni deneyim edinme yaklaşımları ile ilgili kişilik sınıflandırmasıdır. Bireyler bu doğrultuda yaratıcı, estetik eğilimi olan, açık fikirli, iç görülü ve entellektüel kişilik tiplerinde olabilirler. Bu sınıflandırma altında bu kişilik yaklaşımlarından bahsedilmektedir.",
      value_reference: 71,
      value_target: 66,
      value_max: 100,
    },
  ];

  useEffect(() => {
    getJobPositionReport(
      { id: id },
      (res) => {
        setAnalysisReport((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
        setCurrentMatchIndex(0);
      },
      (err) => console.log(err),
      (bool) =>
        setAnalysisReport((prev) => {
          return { ...prev, isLoading: bool };
        }),
    );
  }, [id]);

  if (analysisReport.isLoading) {
    return <Loading />;
  }
  if (!analysisReport.isLoaded) {
    return null;
  }
  // console.log("stats", currentMatch);
  return (
    <div className="w-[1356px] bg-neutral-200 max-lg:w-full">
      {/* header */}
      <div className="mb-6 flex items-center justify-between bg-neutral-100 px-9 pb-6 pt-9 max-lg:mb-3 max-lg:p-4 ">
        <h2 className="text-[26px] font-bold leading-tight text-primary-900 max-lg:text-[14px]">
          {currentMatch.info_reference.name} Uyum Raporu
        </h2>
        <Button onClick={() => navigate(location.state.previousLocation)} className="bg-transparent text-primary-600">
          Kapat
        </Button>
      </div>
      <div className="flex gap-6 max-lg:flex-col lg:pl-9">
        {/* main */}
        <div className="flex basis-2/3 flex-col gap-4 max-lg:px-4">
          {/* versus cards */}
          <div className="flex gap-7 rounded-xl bg-primary-800 bg-cloudy bg-[200%] bg-repeat-x px-9 py-6 max-lg:gap-2 max-lg:p-3">
            <GradientBorder className="flex-1 bg-[linear-gradient(96deg,rgba(255,255,255,0.24)_2.41%,rgba(255,255,255,0.12)_99.5%)] p-4 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.25)] backdrop-blur-sm max-lg:max-w-[146px] max-lg:p-1.5">
              <div className="flex h-full flex-col items-start justify-between">
                <p className="text-[18px] font-semibold leading-tight text-neutral-100 max-lg:text-[10px]">
                  {currentMatch.info_reference.name}
                </p>
                <TagWithIcon
                  className={"bg-transparent p-0 text-neutral-100 max-lg:text-[10px]"}
                  icon={<PeopleFilled className={"h-4 w-4 max-lg:h-3 max-lg:w-3"} />}
                  text={"n/a"}
                />
              </div>
            </GradientBorder>
            <MatchFilled className="w-14 text-primary-500 drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-lg:w-[24px]" />
            <GradientBorder className="flex-1 bg-[linear-gradient(96deg,rgba(255,255,255,0.24)_2.41%,rgba(255,255,255,0.12)_99.5%)] p-4 shadow-[0px_4px_12px_0px_rgba(0,0,0,0.25)] backdrop-blur-sm max-lg:max-w-[146px] max-lg:p-1.5">
              <div className="flex h-full flex-col items-start justify-between gap-4 max-lg:gap-2">
                <div className="flex items-center gap-1">
                  <CircularProgressBar
                    textClassName={"text-neutral-100 font-bold"}
                    value={currentMatch.match_percent}
                    small
                  />
                  <p className="whitespace-nowrap text-[18px] font-semibold leading-tight text-neutral-100 max-lg:text-[10px]">
                    {currentMatch.info_target.name}
                  </p>
                </div>
                <TagWithIcon
                  className={"bg-transparent p-0 text-neutral-100 max-lg:text-[9px]"}
                  icon={<BlacklistOutlined className={"h-4 w-4 max-lg:h-3 max-lg:w-3"} />}
                  text={"Banliste Ekle"}
                />
              </div>
            </GradientBorder>
          </div>
          {/* action recommendations */}
          <div className="rounded-xl bg-secondary_recruiter-200 px-6 py-3 max-lg:px-4">
            <div className="flex items-center gap-1.5">
              <BulbIllustration className={"w-8"} />
              <h2 className="text-[22px] font-semibold text-secondary_recruiter-800 max-lg:text-base">
                Aksiyon Önerileri
              </h2>
              <TagWithIcon
                className={"bg-neutral-100 text-secondary_recruiter-600"}
                icon={<LightningFilled className={"h-4 w-4"} />}
                text={currentMatch.action_suggestions.length}
              />
              <button onClick={() => setOpenActionsDrawer((prev) => !prev)} className="ml-auto">
                <ChevronDownOutlined
                  className={`${openActionsDrawer ? "rotate-180" : "rotate-0"} h-4 w-4 transition-all`}
                />
              </button>
            </div>
            <div
              className={`${
                openActionsDrawer ? "max-h-[250px]" : "max-h-0"
              } flex flex-col gap-2 overflow-auto transition-all duration-300 ease-in max-lg:text-[13px]`}
            >
              <hr className="border-secondary_recruiter-300"></hr>
              {/* <h4 className="font-semibold leading-tight text-primary-900">
                Öncelikli Tutundurma
              </h4> */}
              {currentMatch.action_suggestions.map((suggestion, index) => {
                return (
                  <div key={index} className="flex items-start gap-1">
                    <span className="pt-1">
                      {suggestion.type === "Sür" ? (
                        <BadgeFilled className={"h-4 w-4 text-success-400"} />
                      ) : (
                        <BookFilled className="h-4 w-4 text-secondary_recruiter-600" />
                      )}
                    </span>
                    <p className="text-[14px] text-primary-900">{suggestion.description}</p>
                  </div>
                );
              })}

              {/* <div className="flex items-start gap-1">
                <span className="pt-1">
                  <LightningFilled className="h-4 w-4 text-secondary_recruiter-600" />
                </span>
                <p className="text-[14px] text-primary-900">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras laoreet dui eu gravida ultricies.
                  Phasellus tincidunt urna maximus, rutrum neque id, eleifend ligula. Nulla fringilla, mi vitae
                  consequat eleifend, augue lectus lacinia massa, sit amet maximus justo nulla nec justo.
                </p>
              </div> */}
            </div>
          </div>
          {/* corecv compare table */}
          <div className="rounded-xl bg-neutral-100 p-6 max-lg:overflow-auto max-lg:p-4">
            <div className="flex items-center justify-between">
              <SectionTitle title={"Core CV"} className="items-start max-lg:text-base" />
              <TagWithIcon
                className={"border border-secondary_recruiter-600 bg-opacity-0 text-secondary_recruiter-600"}
                icon={<TagFilled className={"g-3 w-3"} />}
                text={"Tag Info"}
                icon2={<ChevronDownOutlined className={"h-4 w-4"} />}
              />
            </div>
            <table className="border-separate border-spacing-x-6 max-lg:border-spacing-x-3">
              <colgroup>
                <col className="w-1/5" />
                <col className="w-2/5 bg-neutral-200" />
                <col className="w-2/5 bg-primary-75" />
              </colgroup>
              <thead>
                <th></th>
                <th>
                  <p className=" py-6">{currentMatch.info_reference.name}</p>
                </th>
                <th>
                  <p className=" py-6">{currentMatch.info_target.name}</p>
                </th>
              </thead>
              <tbody>
                {Object.keys(CORECV_FIELD_KEYS).map((key, index) => {
                  const field = currentMatch.core_cv.find((item) => item.id === key);
                  return (
                    <tr>
                      <th className="border-b max-lg:text-start">
                        <p>{CORECV_FIELD_KEYS[key]}</p>
                      </th>
                      <td className="border-b">
                        <div className={`relative mx-6 flex flex-wrap gap-1  py-3`}>
                          {field &&
                            field.job_position.map((item, index) => <TagWithIcon key={index} text={item.name} />)}
                        </div>
                      </td>
                      <td className="border-b">
                        <div className={`${index === 0 && ""} flex flex-wrap gap-1 px-6 py-3`}>
                          {field &&
                            field.person.map((item, index) => (
                              <span className="text-[15px] font-medium leading-tight text-primary-900">
                                {index !== 0 && <>&#x2022; </>}
                                {item}
                              </span>
                            ))}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* radar chart */}
          {/* <div className="mx-auto h-[407px] w-[596px] max-lg:h-[220px] max-lg:w-[340px]">
            <RadarPersonalityChart radar_data={radar_data} />
          </div> */}
          {/* personality description */}
          {/* <div className="rounded-xl bg-neutral-100 px-6 py-3">
            <div className="flex items-center gap-1.5">
              <BulbIllustration className={"w-8"} />
              <h2 className="text-[22px] font-semibold text-secondary_recruiter-800">Kişilik Tipi Detayları</h2>

              <button onClick={() => setOpenActionsDrawer((prev) => !prev)} className="ml-auto">
                <ChevronDownOutlined
                  className={`${openActionsDrawer ? "rotate-180" : "rotate-0"} h-4 w-4 transition-all`}
                />
              </button>
            </div>
            <div
              className={`${
                openActionsDrawer ? "max-h-[700px]" : "max-h-0"
              } flex flex-col gap-2 overflow-auto transition-all duration-300 ease-in max-lg:text-[13px]`}
            >
              <div className="flex flex-col gap-4 max-lg:px-2 max-lg:py-4">
                {radar_data.map((personality, idx) => (
                  <div key={idx} className="rounded-xl bg-primary-50 p-4">
                    <h5 className="mb-2 border-l-2 border-primary-900 pl-1 text-[15px] font-semibold leading-tight text-primary-900">
                      {personality.title}
                    </h5>
                    <p className="text-[14px] font-medium leading-tight text-primary-800 max-lg:text-justify max-lg:text-[13px] max-lg:font-normal max-lg:text-neutral-800">
                      {personality.description || "N/A"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* professional orientation summary */}
          <div className="rounded-xl bg-neutral-100 px-6 py-3 max-lg:px-4">
            <SectionTitle title={"Mesleki Yönelim Özeti"} className="items-start max-lg:text-base" />
            <CompatibilitySummary summary={currentMatch.dimensions_compatibility} />
          </div>
          {/* professional orientations box */}
          <div className="rounded-xl bg-neutral-100 px-6 py-3 max-lg:p-3">
            <SectionTitle title={"Mesleki Yönelim Detayları"} className="items-start max-lg:text-base" />
            {currentMatch.dimensions.map((dimension, index) => {
              return (
                <MatchedOrientationCard
                  refName={currentMatch.info_reference.name}
                  targetName={currentMatch.info_target.name}
                  orientation={dimension}
                  orientationContent={analysisReport.data.dimensions_content[dimension.id_combined]}
                />
              );
            })}
          </div>
        </div>
        {/* members list */}
        <div className="flex basis-1/3 flex-col gap-4 rounded-l-xl bg-neutral-100 p-6">
          <div className="flex flex-col gap-2">
            <h3 className="text-xl font-bold leading-tight text-primary-900">Üyeler</h3>
            <p className="leading-tight text-neutral-700">Herhangi bir üyeyi seçin ve karşılaştırın!</p>
          </div>
          <div className="flex gap-2">
            <SearchBar />
            {/* <FilterBar /> */}
          </div>
          <ul className="flex flex-col gap-3">
            {matches.map((match, index) => (
              <button onClick={() => setCurrentMatchIndex(index)} key={index}>
                <MatchRow match={match} />
              </button>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const CompatibilitySummary = ({ summary }) => {
  const metacvDimStats = summary;
  const colors = COMPATIBILITY_STAT_COLORS;
  const RADIAN = Math.PI / 180;
  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={13} fill="white" opacity="0.5" />
            <text x={x} y={y} fill="#0D568A" fontWeight={600} textAnchor={"middle"} dominantBaseline="central">
              {`${(percent * 14).toFixed(0)}`}
            </text>
          </>
        )}
      </>
    );
  };

  const handleScrollToOrientation = (id) => {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <PieChart width={225} height={225}>
        <Pie
          data={metacvDimStats}
          dataKey={"value"}
          nameKey={"title"}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={customLabel}
          outerRadius={100}
          innerRadius={60}
        >
          {metacvDimStats.map((entry, index) => (
            <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
          ))}
        </Pie>
      </PieChart>
      <div className="flex w-[350px] flex-col divide-y">
        {metacvDimStats.map((entry, index) => (
          <div key={index} className="flex flex-1 items-center justify-between py-2">
            <p className="flex items-center pr-1 text-[17px] font-semibold leading-tight text-primary-700 max-lg:text-[14px]">
              <span className={`pr-2 text-[10px]`} style={{ color: colors[entry.id] }}>
                {"\u2B24"}
              </span>
              {entry.name}
            </p>
            <button
              // onClick={() => handleScrollToOrientation("orientationCard_" + entry.id)}
              disabled={entry.value === 0}
            >
              {entry.value} <RightArrowOutlined className={"inline-block h-4 w-4 text-primary-800"} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalysisReport;
