import React from "react";
import {
  MetaCvLogoText,
  MetaCvOnlyLogoOpaque,
} from "../../components/OldDesign/Icons";

const MetaCvDialogue = () => {
  return (
    <>
      {/* MOBILE DIALOGUE */}
      <div
        className={`${
          window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
        } absolute left-1/2 inline-flex w-[360px] -translate-x-1/2 flex-col rounded-xl bg-white p-6 transition-all duration-500 lg:top-1/2 lg:hidden lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
      >
        <div className="absolute -top-16 self-center lg:hidden">
          <MetaCvLogoText color="#FFF" />
        </div>
        <p className="mb-6 border-l-2 border-l-primary-900 pl-2 text-[20px] font-bold leading-6 text-primary-900 lg:text-[24px] lg:leading-7">
          CV’nin yeni dünyası: Meta CV
        </p>
        <ul className="flex flex-col gap-6 text-[16px] font-medium leading-5 text-primary-900">
          <li className="relative pl-3">
            <svg
              className="absolute left-0 top-1.5"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="#062B45" />
            </svg>
            Profesyonel yönelim ve kişilik özelliklerini içeren gelişmiş
            filtreler aracılığıyla potansiyel adayları keşfedin.
          </li>
          <li className="relative pl-3">
            <svg
              className="absolute left-0 top-1.5"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="#062B45" />
            </svg>
            Mülakattan önce uygun olmayan adayları eleyerek zaman kazanın.
          </li>
          <li className="relative pl-3">
            <svg
              className="absolute left-0 top-1.5"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="#062B45" />
            </svg>
            Çalışanlarınızın potansiyeli hakkında "güncel" bilgilere sahip
            olarak olası sorunların bir adım önünde olun.
          </li>
        </ul>
      </div>
      {/* TRANSPARENT LOGO */}
      <div className={`absolute bottom-4 left-4 lg:hidden`}>
        <MetaCvOnlyLogoOpaque color="#106BAC" opacity="0.05" />
      </div>
    </>
  );
};

export default MetaCvDialogue;
