import { useNavigate } from "react-router-dom";
import { RightArrowOutlined } from "../OldDesign/Icons";
import Button from "./Button/Button";
import SectionTitle from "./SectionTitle";
import StepCard from "./StepCard";
import { HorizontalStepper, VerticalStepper } from "./Stepper";
import { useStore } from "../../store/useStore";

const ApplicationFormOverview = () => {
  const navigate = useNavigate();
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations[
        "Candidate Info Boxes"
      ],
  );
  const content2 = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  const coreCV = useStore((state) => state.coreCV);

  const steps = [
    {
      stepNumber: 1,
      name: content2["kullanici profili baslik"],
      status: coreCV.data.identity_progress.steps.user_profile.status,
      subSteps: [
        {
          stepName: content2["kisisel bilgiler baslik"],
          status:
            coreCV.data.identity_progress.steps.user_profile.subSteps
              .personal_information.status,
        },
        {
          stepName: content2["iletisim bilgileri baslik"],
          status:
            coreCV.data.identity_progress.steps.user_profile.subSteps
              .contact_information.status,
        },
      ],
    },
    {
      stepNumber: 3,
      name: content2["mesleki detaylar baslik"],
      status: coreCV.data.identity_progress.steps.profession_details.status,
      subSteps: [
        {
          stepName: content2["deneyimler baslik"],
          status:
            coreCV.data.identity_progress.steps.profession_details.subSteps
              .experience_details.status,
        },
        {
          stepName: content2["yetenekler baslik"],
          status:
            coreCV.data.identity_progress.steps.profession_details.subSteps
              .skill_details.status,
        },
        {
          stepName: content2["calisma tercihi baslik"],
          status:
            coreCV.data.identity_progress.steps.profession_details.subSteps
              .working_preferences.status,
        },
      ],
    },
    {
      stepNumber: 8,
      name: content2["egitim durumu baslik"],
      status: coreCV.data.identity_progress.steps.educational_status.status,
      subSteps: [
        {
          stepName: content2["egitim detaylari baslik"],
          status:
            coreCV.data.identity_progress.steps.educational_status.subSteps
              .education_details.status,
        },
        {
          stepName: content2["dil seviyesi baslik"],
          status:
            coreCV.data.identity_progress.steps.educational_status.subSteps
              .language_details.status,
        },
      ],
    },
  ];

  const current_step = coreCV.data.identity_progress.current_step;
  const continueStep =
    current_step < 2
      ? "step1"
      : current_step < 7
      ? "step2"
      : current_step < 8
      ? "step3"
      : current_step < 9
      ? "step4"
      : "confirmation";
  const stepperStep =
    current_step < 2 ? 1 : current_step < 7 ? 2 : current_step < 9 ? 3 : 4;

  if (!coreCV.isLoaded) {
    return null;
  }
  return (
    <section className="container2 py-9 max-lg:py-4">
      {/* DESKTOP */}
      <div className="flex flex-row items-center justify-between border-l-[3px] border-l-primary-900 pl-3 max-lg:hidden">
        <div className="flex flex-col gap-3">
          <h2 className="text-[32px] font-semibold leading-tight text-primary-900">
            {content["katilim formu baslik"]}
          </h2>
          <p className="text-[20px] font-medium leading-tight">
            {content["katilim formu aciklama"]}
          </p>
        </div>
        <div>
          <Button
            icon2={<RightArrowOutlined className="h-5 w-5" />}
            onClick={() => navigate(`/apply/${continueStep}`)}
          >
            {content["katilim formu buton"]}
          </Button>
        </div>
      </div>
      <div className="py-9 max-lg:hidden">
        <HorizontalStepper steps={steps} currentStep={stepperStep} />
      </div>
      {/* MOBILE */}
      <div className="lg:hidden">
        <div className="flex flex-row items-center">
          <SectionTitle title="Application Form" className="items-start" />
          <Button
            icon2={<RightArrowOutlined className="h-3 w-3" />}
            onClick={() =>
              navigate(`/apply`, { state: { stepNumber: current_step + 1 } })
            }
            className=" p-2 text-[14px]"
          >
            {content["katilim formu buton"]}
          </Button>
        </div>
        <p className="pt-2 text-[13px] leading-tight text-primary-800">
          {content["katilim formu aciklama"]}
        </p>
      </div>
      <div className="flex flex-row justify-center gap-4 max-lg:pt-4">
        <div className="self-center py-4 lg:hidden">
          <VerticalStepper steps={steps} currentStep={stepperStep} />
        </div>
        <div className="flex w-full flex-row gap-12 max-lg:flex-col max-lg:gap-4">
          {steps.map((step, index) => (
            <StepCard
              key={index}
              step={step}
              currentStep={stepperStep === index + 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ApplicationFormOverview;
