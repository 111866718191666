import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  BagIconFilled,
  BirthdayFilled,
  CloseCircleIcon,
  InfoOutlined,
  LocationFilled,
  MailFilled,
  PhoneFilled,
  RightArrowOutlined,
  SendFilled,
} from "../OldDesign/Icons";
import userPP from "../../user.png";
import { metaCvActions } from "../../api/endpoints";
import { TagWithIcon } from "../NewDesign/Tags";
import ActionBar from "../NewDesign/PrintableCv/ActionBar";
import Loading from "../OldDesign/Loading";
import SectionTitle from "../NewDesign/SectionTitle";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import CvFooter from "../NewDesign/PrintableCv/CvFooter";
import OrientationCard from "../NewDesign/OrientationCard";
import { Cell, Pie, PieChart } from "recharts";
import {
  ORIENTATION_LEVEL_DESCRIPTIONS,
  ORIENTATION_STAT_COLORS,
} from "../NewDesign/constants";
import Tooltip from "../NewDesign/Tooltips/Tooltip";
import { Wordcloud } from "@visx/wordcloud";
import { Link } from "react-router-dom";

const MobileMetaCv = ({ id, onClose }) => {
  const [metaCv, setMetaCv] = useState({});
  const [activeTab, setActiveTab] = useState("tab2");
  const metaCvRef = useRef();
  const commentRef = useRef();
  const handleScrollToCommentSection = () => {
    commentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleCloseOverlay = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const observerRefVal = metaCvRef.current;
    disableBodyScroll(observerRefVal);
    return () => {
      if (observerRefVal) {
        enableBodyScroll(observerRefVal);
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      type: "view",
      target: id,
      action: {},
    };
    metaCvActions(
      payload,
      (data) => setMetaCv(data.data),
      (err) => console.log(err),
      // (bool) => setIsLoading(bool),
    );
  }, [id]);

  return (
    <div
      ref={metaCvRef}
      className="fixed left-0 top-0 z-[100] h-screen w-screen overflow-y-scroll bg-neutral-200"
    >
      <button className="fixed right-2 top-2 z-50" onClick={handleCloseOverlay}>
        <CloseCircleIcon className="h-6 w-6" />
      </button>
      {!metaCv?.id ? (
        <Loading />
      ) : (
        <>
          <div className="w-full rounded-b-3xl bg-neutral-100 ">
            <div className="px-4 pb-2 pt-6">
              <div className="flex flex-row justify-start gap-2 pb-2">
                <div className="relative flex h-[130px] w-[130px] items-center justify-center rounded-full bg-[#87C4FF] max-lg:h-[80px] max-lg:w-[80px]">
                  <img
                    src={userPP}
                    alt="profile"
                    className="h-[120px] w-[120px] rounded-full max-lg:h-[70px] max-lg:w-[70px]"
                  />
                  <span className="absolute -bottom-2 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-2.5 font-semibold  text-neutral-100 max-lg:text-[11px]">
                    LVL {metaCv.metacv_level}
                  </span>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <h2 className="text-[14px] font-semibold leading-tight text-primary-900">
                    {metaCv.name}
                  </h2>
                  <p className="text-[13px] leading-tight text-neutral-900">
                    {metaCv.job.job_name}
                  </p>
                  <p className="text-[13px] leading-tight text-neutral-900">
                    {metaCv.location_state.state_name},{" "}
                    {metaCv.location_country.country_name}
                  </p>
                  <div className="flex justify-center gap-2">
                    <TagWithIcon
                      icon={<SendFilled className="h-[14px] w-[14px]" />}
                      className="bg-secondary_recruiter-100 px-2 py-1 text-secondary_recruiter-700"
                      text={`${true ? "Employee" : "Candidate"}`}
                    />
                    <TagWithIcon
                      icon={<BagIconFilled className="h-[14px] w-[14px]" />}
                      className="bg-success-100 px-2 py-1 text-success-400"
                      text={`${
                        metaCv.work_state === "unemployed"
                          ? "Not working"
                          : "Working"
                      }`}
                    />
                  </div>
                  <div className="h-[32px] w-full overflow-hidden text-clip whitespace-nowrap ">
                    {metaCv.metacv.aid.map((badge, index) => (
                      <Link
                        className="rounded-full py-1 pr-3 leading-tight transition-all hover:bg-neutral-200"
                        key={index}
                        to={`/aid/identities/${badge.aid}`}
                      >
                        <img
                          src={
                            process.env.REACT_APP_STRAPI_BASE_URL + badge.media
                          }
                          alt={badge.name}
                          title={badge.name}
                          className="mr-1 h-[32px] w-[32px]"
                        />
                        <h1 className="gradient-text inline-block text-[14px] font-semibold leading-tight text-transparent opacity-80 max-lg:text-[13px]">
                          {badge.name} - {badge.title}
                        </h1>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <ActionBar
                handleScrollToCommentSection={handleScrollToCommentSection}
                user={metaCv}
                setMetaCv={setMetaCv}
              />
            </div>
            <div className="flex justify-around p-1">
              <button
                onClick={() => handleTabChange("tab1")}
                className={`${
                  activeTab === "tab1"
                    ? "border-primary-700 text-primary-700"
                    : "border-neutral-100 text-neutral-400"
                } my-2 border-b-[1.5px] px-3 text-[14px] font-semibold leading-tight`}
              >
                Core Cv
              </button>
              <button
                onClick={() => handleTabChange("tab2")}
                className={`${
                  activeTab === "tab2"
                    ? "border-primary-700 text-primary-700"
                    : "border-neutral-100 text-neutral-400"
                } my-2 border-b-[1.5px] px-3 text-[14px] font-semibold leading-tight`}
              >
                Kişilik Tipi
              </button>
              <button
                onClick={() => handleTabChange("tab3")}
                className={`${
                  activeTab === "tab3"
                    ? "border-primary-700 text-primary-700"
                    : "border-neutral-100 text-neutral-400"
                } my-2 border-b-[1.5px] px-3 text-[14px] font-semibold leading-tight`}
              >
                Mesleki Yönelim
              </button>
            </div>
          </div>
          <div className="p-2">
            {activeTab === "tab1" && <CoreCv metaCv={metaCv} />}
            {activeTab === "tab2" && <PersonalityType metaCv={metaCv} />}
            {activeTab === "tab3" && (
              <ProfessionalOrientation metaCv={metaCv} />
            )}
          </div>
          <div ref={commentRef}>
            <CvFooter user={metaCv} />
          </div>
        </>
      )}
    </div>
  );
};

const CoreCv = ({ metaCv }) => {
  // console.log(metaCv);
  return (
    <>
      <SectionTitle title={"Core Cv"} className="pb-4" />
      <div className="flex flex-col gap-4">
        {/* Contact Information */}
        <ul className="flex flex-col gap-2 rounded-xl bg-neutral-100 px-6 py-4">
          <h2 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">
            İletişim Bilgileri
          </h2>
          <li className="flex items-center gap-2">
            <MailFilled className="h-4 w-4 text-primary-900" />
            <p className="text-[13px] font-medium leading-tight text-primary-900">
              {metaCv.email}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <PhoneFilled className="h-4 w-4" />
            <p className="text-[13px] font-medium leading-tight text-primary-900">
              {metaCv.phone}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <LocationFilled className="h-4 w-4" />
            <p className="text-[13px] font-medium leading-tight text-primary-900">
              {metaCv.location_state.state_name},{" "}
              {metaCv.location_country.country_name}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <BirthdayFilled className="h-4 w-4" />
            <p className="text-[13px] font-medium leading-tight text-primary-900">
              {metaCv.birth_date}
            </p>
          </li>
        </ul>
        {/* Experience */}
        <ul className="flex flex-col gap-2 rounded-xl bg-neutral-100 px-6 py-4">
          <h2 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">
            Çalışma Tercihi
          </h2>
          <li className="text-[14px] font-medium leading-tight text-primary-900">
            {metaCv.job.job_experience_title}
          </li>
        </ul>
        {/* Skills */}
        <ul className="flex flex-col gap-2 rounded-xl bg-neutral-100 px-6 py-4">
          <h2 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">
            Skills
          </h2>
          <div className="flex flex-col gap-2">
            {metaCv.skills_array.map((skill, index) => (
              <li
                key={index}
                className="text-[14px] font-medium leading-tight text-primary-900"
              >
                <span className="pr-2 align-top text-[6px] leading-[18px]">
                  {"\u2B24"}
                </span>
                {skill.skill_name}
              </li>
            ))}
          </div>
        </ul>
        {/* Education */}
        <ul className="flex flex-col gap-2 rounded-xl bg-neutral-100 px-6 py-4">
          <h2 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">
            Eğitim Durumu
          </h2>
          {metaCv.educations_array.map((school, index) => (
            <li key={index} className="flex">
              <span className="pr-2 align-top text-[6px] leading-[18px]">
                {"\u2B24"}
              </span>
              <div className="flex flex-col items-start gap-1">
                <p className="text-[14px] font-medium leading-tight text-primary-900">
                  {school.institution}
                </p>
                <p className="text-[14px] font-medium leading-tight text-primary-900">
                  {school.department !== "-1" && school.department + ","}{" "}
                  {school.degree}
                </p>

                <p className="text-[14px] font-medium leading-tight text-primary-900">
                  ({school.start_date.replace("-", "/")}-
                  {school.end_date.replace("-", "/")})
                </p>
              </div>
            </li>
          ))}
        </ul>
        {/* Languages */}
        <ul className="flex flex-col gap-2 rounded-xl bg-neutral-100 px-6 py-4">
          <h2 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">
            Dil Bilgisi
          </h2>
          <div className="flex gap-4">
            {metaCv.languages_array.map((language, index) => (
              <li
                key={index}
                className="text-[14px] font-medium leading-tight text-primary-900"
              >
                <span className="pr-2 align-top text-[6px] leading-[18px]">
                  {"\u2B24"}
                </span>
                {language.language_name} - {language.language_proficiency}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </>
  );
};

const PersonalityType = ({ metaCv }) => {
  function showTooltip(evt, text) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left =
      evt.clientX > 150 ? evt.clientX - 130 + "px" : evt.clientX - 20 + "px";
    tooltip.style.top = evt.clientY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }
  return (
    <>
      <SectionTitle title="Kişilik Tipi" className="pb-2" />
      <p className="px-4 pb-4 text-center text-[13px] leading-tight text-primary-900">
        Sayın {metaCv.name} için kişilik tipi analizi tamamlandı. Aşağıdaki gibi
        bir birey olduğu teyid edildi!
      </p>
      <div className="mx-auto mb-2 rounded-xl bg-neutral-100 max-lg:w-[350px]">
        <div
          id="tooltip"
          display="none"
          className="fixed z-[999] hidden max-w-[360px] rounded-lg bg-primary-800 p-2 text-justify text-sm text-neutral-100 max-lg:text-xs"
        ></div>
        <Wordcloud
          words={metaCv.metacv.word_cloud}
          fontSize={(word) => Math.sqrt(word.value)}
          padding={8}
          font={"Inter"}
          rotate={(word) => 0}
          spiral="archimedean"
          width={340}
          height={360}
          random={() => 0.5}
        >
          {(cloudWords) =>
            cloudWords.map((word, index) => {
              return (
                <Fragment key={index}>
                  <defs key={index}>
                    <filter
                      x="-4%"
                      y="-5%"
                      width="108%"
                      height="110%"
                      id={index}
                    >
                      <feFlood floodColor={word.bgColor} />
                      <feGaussianBlur stdDeviation="2" />
                      <feComponentTransfer>
                        <feFuncA type="table" tableValues="0 0 0 1" />
                      </feComponentTransfer>

                      <feComponentTransfer>
                        <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                      </feComponentTransfer>
                      <feComposite operator="over" in="SourceGraphic" />
                    </filter>
                  </defs>
                  <text
                    style={{
                      fontSize: word.size,
                      fontWeight: Math.log(word.value) * 100,
                      fill: word.color,
                      fontFamily: word.font,
                    }}
                    filter={`url(#${index})`}
                    textAnchor="middle"
                    transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                    onMouseMove={(e) =>
                      showTooltip(e, word.explain.description)
                    }
                    onMouseLeave={hideTooltip}
                  >
                    {word.text}
                  </text>
                </Fragment>
              );
            })
          }
        </Wordcloud>
        <div className="flex flex-wrap justify-center gap-4">
          {metaCv.metacv.upper_dimensions.map((upType, index) => {
            return (
              <Tooltip key={index} content={upType.description}>
                <div
                  key={index}
                  className="flex cursor-help items-center justify-center gap-1"
                >
                  <span className={`text-sm`} style={{ color: upType.color }}>
                    {"\u2B24"}
                  </span>
                  <p className="text-sm">{upType.name}</p>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      {/* <div className="absolute left-0 mb-4 overflow-hidden rounded-xl bg-neutral-100 pb-6">
        <ArcChart charts={metaCv.metacv.charts} />
      </div> */}
      <div className="flex flex-col">
        {/* <div className="col-span-3 grid grid-cols-1 gap-4 ">
          {metaCv.metacv.charts.selectedTitles.map((type, index) => (
            <div key={index} className="rounded-xl bg-neutral-100 px-3 py-4">
              <SectionTitle
                title={type.title}
                className="items-start gap-1 pb-2 font-semibold leading-tight text-primary-900 max-lg:text-[13px]"
              />
              <p className="text-[14px] leading-tight text-primary-900">
                {type.description}
              </p>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

const ProfessionalOrientation = ({ metaCv }) => {
  const data01 = metaCv.metacv.dimensions_stats;
  const colors = ORIENTATION_STAT_COLORS;
  const RADIAN = Math.PI / 180;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={10} fill="white" opacity="0.5" />
            <text
              x={x}
              y={y}
              fill="#0D568A"
              fontWeight={500}
              textAnchor={"middle"}
              dominantBaseline="central"
              fontSize={13}
            >
              {`${(percent * 14).toFixed(0)}`}
            </text>
          </>
        )}
      </>
    );
  };
  const handleScrollToOrientation = (id) => {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="mb-2">
      <SectionTitle title="Mesleki Yönelim" className="gap-0.5 pb-4" />
      <div className="mb-4 rounded-xl bg-neutral-100 p-3">
        <div className="flex flex-col items-center gap-1">
          <PieChart width={120} height={120}>
            <Pie
              data={data01}
              dataKey={"value"}
              nameKey={"title"}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={customLabel}
              outerRadius={60}
              innerRadius={30}
            >
              {data01.map((entry, index) => (
                <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
              ))}
            </Pie>
          </PieChart>
          <div className="grid w-full grid-cols-2 grid-rows-2 gap-2">
            {data01.map((entry, index) => (
              <div
                key={index}
                className="rounded-[4px] border border-neutral-300 p-2"
              >
                <div className="flex items-center justify-between pb-4">
                  <p className="flex items-center pr-1 text-[15px] font-semibold leading-tight text-primary-700">
                    <span
                      className={`pr-2 text-[10px]`}
                      style={{ color: colors[entry.id] }}
                    >
                      {"\u2B24"}
                    </span>
                    {entry.title}
                  </p>
                  <Tooltip content={ORIENTATION_LEVEL_DESCRIPTIONS[entry.id]}>
                    <InfoOutlined className={"h-4 w-4 text-neutral-600"} />
                  </Tooltip>
                </div>
                <div className="flex items-center justify-between">
                  <p className="flex items-center pr-1 text-[15px] font-bold leading-tight text-primary-800">
                    {entry.value}
                  </p>
                  <button
                    onClick={() =>
                      handleScrollToOrientation("orientationCard_" + entry.id)
                    }
                    disabled={entry.value === 0}
                  >
                    <RightArrowOutlined
                      className={"h-4 w-4 text-primary-800"}
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <p className="pb-4 text-center text-[13px] leading-tight text-primary-900">
        Aşağıda mesleki yönelimlerim ile ilgili sonuçları bulabilirsiniz.
      </p> */}
      {/* <div className="lg:my-12 lg:py-20">
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 max-lg:pb-4 lg:flex-wrap">
          <DiamondCard polar={metaCv.metacv.polars[0]} />
          <DiamondCard polar={metaCv.metacv.polars[1]} />
          <DiamondCard polar={metaCv.metacv.polars[2]} />
        </div>
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 lg:flex-wrap">
          <DiamondCard polar={metaCv.metacv.polars[3]} />
          <DiamondCard polar={metaCv.metacv.polars[4]} />
        </div>
      </div> */}

      <div className="flex flex-col gap-4">
        {metaCv.metacv.dimensions.map((orientation, index) => {
          return <OrientationCard key={index} orientation={orientation} />;
        })}
      </div>
    </div>
  );
};

export default MobileMetaCv;
