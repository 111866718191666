import React from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { Form } from "../../Forms/Form";
import { Field, FieldCheckbox } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { WORK_LOCATION } from "../../constants";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";
import { Checkbox } from "../../Forms/Input";

const WorkingStyleCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore(
    (state) => state.updateApplicationForm,
  );
  const content = useStore(
    (state) =>
      state.content.data["Candidate Community"].content.translations["Core CV"],
  );
  let defaultValue = "";
  if (coreCV.work_location) {
    defaultValue = Object.keys(coreCV.work_location).filter(
      (key) => coreCV.work_location[key] === true,
    );
    // console.log(defaultValue);
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      work_location: defaultValue,
    },
    mode: "onSubmit",
  });
  const sendFormData = (formData) => {
    const payload = {
      work_location: {
        remote: formData.work_location.includes("remote"),
        hybrid: formData.work_location.includes("hybrid"),
        office: formData.work_location.includes("office"),
      },
    };
    // console.log(formData, payload);
    updateApplicationForm(payload, props.handleForwardForm);
  };
  const atLeastOneOptionChecked = (selectedOpts) => {
    return selectedOpts.length > 0;
  };
  return (
    <Form
      className="flex h-full flex-col justify-between"
      onSubmit={handleSubmit(sendFormData)}
    >
      <fieldset>
        <h2 className="mb-4 border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
          {content["calisma sekli tercihi"]}
        </h2>
        {/* <h4
          className={`${
            errors.work_location ? "text-error-400" : "text-neutral-900"
          } pb-2 text-[15px] leading-tight `}
        >
          Çalışma Şekli Tercihi
        </h4> */}
        <div className="flex flex-col gap-4">
          <FieldCheckbox label="Office">
            <Checkbox
              value="office"
              {...register("work_location", {
                validate: atLeastOneOptionChecked,
              })}
              id="Office"
            />
          </FieldCheckbox>
          <FieldCheckbox label="Remote">
            <Checkbox
              value="remote"
              {...register("work_location", {
                validate: atLeastOneOptionChecked,
              })}
              id="Remote"
            />
          </FieldCheckbox>
          <FieldCheckbox label="Hybrid">
            <Checkbox
              value="hybrid"
              {...register("work_location", {
                validate: atLeastOneOptionChecked,
              })}
              id="Hybrid"
            />
          </FieldCheckbox>
        </div>
      </fieldset>
      <div className="flex justify-center gap-20">
        <button
          disabled={false}
          type="button"
          id="backwardArrow"
          onClick={() => props.handleBackwardForm()}
        >
          <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
        </button>
        <button disabled={false} type="submit" id="forwardArrow">
          <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
        </button>
      </div>
    </Form>
  );
};

export default WorkingStyleCard;
