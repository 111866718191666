import React, { useState } from "react";
import { Form } from "../Forms/Form";

import Button from "../Button/Button";
import { useForm } from "react-hook-form";
import { metaCvActions } from "../../../api/endpoints";
import { toast } from "react-toastify";
import { useStore } from "../../../store/useStore";

const CvFooter = ({ user }) => {
  const [editMode, setEditMode] = useState(user.review.status <= 0);
  const [submittingText, setSubmittingText] = useState(false);
  const downloadMetaCv = useStore((state) => state.downloadMetaCv);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reviewText: user.review.review,
    },
    mode: "onSubmit",
  });

  const sendComment = (formData) => {
    const payload = {
      type: "review",
      target: user.id,
      action: {
        review_name: "review name",
        review: formData.reviewText,
      },
    };

    metaCvActions(
      payload,
      (res) => {
        setEditMode(false);
        toast.success("Review submitted");
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        setSubmittingText(bool);
      },
    );
  };
  const openEditMode = (e) => {
    e.preventDefault();
    // console.log("edit mode opened");
    setEditMode(true);
  };
  return (
    <div className="shadow-[0px_-10px_8px_-8px_rgba(0,0,0,0.08)] max-lg:bg-neutral-100 max-lg:px-4 max-lg:py-6 lg:pt-9">
      <Form onSubmit={handleSubmit(sendComment)}>
        <textarea
          {...register("reviewText")}
          name="reviewText"
          placeholder="Write a review"
          rows={5}
          readOnly={!editMode}
          id="reviewText"
          className="mb-4 w-full rounded-lg bg-neutral-200 px-6 py-4 text-[18px] leading-tight outline-none placeholder:text-neutral-500 max-lg:text-[14px]"
        />
        <div className="flex justify-end gap-6">
          {!editMode ? (
            <Button
              className="mr-auto max-lg:w-full"
              type="button"
              onClick={openEditMode}
            >
              Edit Review
            </Button>
          ) : (
            <Button
              loading={submittingText}
              disabled={submittingText}
              className="mr-auto max-lg:w-full"
              type="submit"
            >
              Submit Review
            </Button>
          )}

          <Button
            className="bg-neutral-100 text-primary-600 max-lg:hidden"
            type="button"
            onClick={() => downloadMetaCv(user.id, user.name)}
          >
            Download
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CvFooter;
