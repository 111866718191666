import React, { useEffect } from "react";
import { HumanasLogo, LockIcon } from "../../components/OldDesign/Icons";
import { useForm } from "react-hook-form";

import { InputPassword, InputText } from "../../components/OldDesign/Inputs";
import { useStore } from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import Button from "../../components/NewDesign/Button/Button";

const PasswordLogin = () => {
  const navigate = useNavigate();
  const initializeSession = useStore((state) => state.initializeSession);
  const resetPasswordRequest = useStore((state) => state.resetPasswordRequest);
  const user = useStore((state) => state.user);
  const loginData = useStore((state) => state.loginData);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const handleLogin = (formData) => {
    // console.log(formData);
    initializeSession(formData);
  };

  const handleResetPassword = () => {
    // console.log(loginData);
    resetPasswordRequest();
  };
  return (
    <div
      className={`${
        window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
      } absolute left-1/2 inline-flex w-[360px] basis-1/2 -translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
    >
      <div className="absolute -top-16 self-center lg:hidden">
        <HumanasLogo className="text-neutral-100" />
      </div>
      <div className="mb-9 self-center max-lg:hidden">
        <HumanasLogo className="text-primary-600" />
      </div>
      <div className="pb-6">
        <p className="mb-3 border-l-2 border-l-[#080F47] pl-2 text-xl font-bold text-[#080F47] lg:text-[24px] lg:leading-7">
          Giriş Yap
        </p>
        <p className="text-sm leading-5 text-neutral-800 lg:text-[16px]">
          Giriş yapabilmek için lütfen şifrenizi giriniz.
        </p>
      </div>
      <form
        className="flex flex-col gap-9 lg:gap-6"
        onSubmit={handleSubmit(handleLogin)}
      >
        <InputPassword
          register={register}
          rules={{
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 8,
              message: "Minimum 8 character is reqired",
            },
          }}
          errors={errors}
          name="password"
          placeholder="Şifrenizi giriniz"
          copyInput={true}
        />

        <Button
          type="submit"
          disabled={user.isLoading}
          loading={user.isLoading}
        >
          Giriş yap
        </Button>
      </form>
      <button
        className={`text-medium mt-auto cursor-pointer text-center text-[16px] leading-5 text-primary-1000 max-lg:absolute max-lg:-bottom-10 max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap`}
        onClick={() => handleResetPassword()}
        disabled={loginData.isLoading}
      >
        Şifremi Unuttum
      </button>
    </div>
  );
};

export default PasswordLogin;
