import React from "react";
import SectionTitle from "./SectionTitle";
import thumbnail1 from "../../assets/thumbnail1.jpeg";
import thumbnail2 from "../../assets/thumbnail2.jpeg";
import thumbnail3 from "../../assets/thumbnail3.jpeg";
import VideoCard from "./VideoCard";
import { useStore } from "../../store/useStore";

const VIDEOS = [
  { title: "Çalışanlarınızı tanıyor musunuz?", thumbnail: thumbnail1 },
  { title: "Zamanınızı Yönetin", thumbnail: thumbnail2 },
  { title: "Riskleri Öngörün", thumbnail: thumbnail3 },
];

const HumanasSystem = () => {
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations[
        "Recruiter Dashboard Humanas System"
      ],
  );
  return (
    <section className="container2 py-2">
      <SectionTitle title={content["humanas sistem baslik"]} />
      <p className="mx-[108px] pb-9 pt-6 text-center text-[18px] leading-tight text-primary-800 max-lg:hidden">
        {content["humanas sistem aciklama"]}
      </p>
      <div className="flex flex-row gap-6 max-lg:gap-4 max-lg:overflow-x-scroll max-lg:py-6">
        {VIDEOS.map((video, index) => {
          return <VideoCard key={index} video={video} />;
        })}
      </div>
    </section>
  );
};

export default HumanasSystem;
